<template lang="pug">
  div.el-input.el-input--mini(:class="disableClass")
    money.el-input__inner(
      v-model="inputModel.value"
      v-bind="moneyConfig"
      :disabled="inputModel.disabled"
    )
</template>

<script>
import { Money } from 'v-money';

export default {
  name: 'CurrencyInput',
  components: { Money },
  props: ['inputModel'],
  computed: {
    country() {
      return this.inputModel.country;
    },
    moneyConfig() {
      switch (this.country) {
        case 'PE':
          return {
            thousands: ',', precision: 2, decimal: '.', prefix: '$/ ', suffix: '', masked: false,
          };
        case 'MX':
          return {
            thousands: ',', precision: 2, decimal: '.', prefix: '$ ', suffix: '', masked: false,
          };
        default:
          return {
            thousands: '.', precision: 0, decimal: ',', prefix: '$ ', masked: false,
          };
      }
    },
    disableClass() {
      if (this.inputModel.disabled) { return 'is-disabled'; }

      return '';
    },
  },
};
</script>
