<template lang="pug">
  div
    el-row
      el-col
        h3.title Cancelaciones automáticas
    el-row(:gutter="24")
      el-col(:span="12")
        el-alert(
          :title="title"
          type="info"
          :description="description"
          show-icon
          :closable="false"
        )
      el-col(:span="12")
        el-button(
          v-if="canSeeButton()"
          type="primary" @click.prevent="handleCreateReport()") Generar Reporte
    el-dialog(title="Cancelaciones Automáticas" :visible.sync="dialogForm.visible" width="70%")
      inline-form(:formAttributes="dialogForm.attributes")
      span(slot="footer")
        el-button(type="success" size="small" @click.prevent="sendReport()") Enviar Reporte
</template>

<script>
import InlineForm from '@/components/forms/InlineForm.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';

export default {
  name: 'AutomaticCancellations',
  components: {
    InlineForm,
  },
  data() {
    return {
      title: 'Importante!',
      description: 'Recuerda que este reporte llegará por email una vez generado',
      dialogForm: { visible: false, attributes: {} },
    };
  },
  methods: {
    handleCreateReport() {
      const attributes = { ...this.formAttributes };
      this.dialogForm.attributes = attributes;
      this.dialogForm.visible = true;
    },
    sendReport() {
      const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));

      FinanceApi.automatic_cancellations(attributes).then(() => {
        this.$message({ type: 'success', message: 'Reporte enviado' });
      });
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    formAttributes() {
      return {
        rows: [
          [
            {
              key: 'country',
              value: 'CL',
              inputType: 'select',
              label: 'Selecciona país',
              options: this.countries,
            },
            {
              key: 'date_range',
              value: [DateService.getDate(), DateService.getDate()],
              inputType: 'daterange',
              label: 'Rango de fechas',
            },
          ],
        ],
      };
    },
  },
};
</script>
