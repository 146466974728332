import ActionCableVue from 'actioncable-vue';
import Vue from 'vue';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/es';
import 'element-ui/lib/theme-chalk/index.css';

import './assets/css/finances-styles.scss';
import './assets/css/custom-element.scss';

import VueLodash from 'vue-lodash';
import get from 'lodash/get';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import VueClipboard from 'vue-clipboard2';
import SvgIcon from 'vue-svgicon';

import constants from '@/constants/Url.constants';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/svgs/';

VueClipboard.config.autoSetContainer = true;

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: constants.API_WEBSOCKET_URL,
  connectImmediately: true,
});

Vue.use(ElementUI, { locale });
Vue.use(VueLodash, {
  name: 'custom',
  lodash: {
    get, set, forEach, cloneDeep, isEmpty,
  },
});
Vue.use(VueClipboard);

Vue.use(SvgIcon, {
  tagName: 'svgicon',
  defaultWidth: '1.8em',
  defaultHeight: '1.8em',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
