<template lang="pug">
  div(class="container")
    el-row(:gutter="stats.rowSize")
      el-col(:span="stats.colSize" v-for="item in stats.data")
        el-statistic(:title="item.title")
          template(slot="formatter") {{ item.total }}
</template>

<script>

export default {
  name: 'StatsTable',
  props: {
    data: { required: true },
    rowSize: { required: true },
    colSize: { required: true },
  },
  data() {
    return {
    };
  },
  computed: {
    stats() {
      return { colSize: this.colSize, rowSize: this.rowSize, data: this.data };
    },
  },
};
</script>

<style>
  .container {
    margin: 20px 0;
  }
  span.title {
    font-size: 15px;
  }
  span.number {
    font-size: 25px!important;
  }
</style>
