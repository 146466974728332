/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accounting': {
    width: 16,
    height: 16,
    viewBox: '-33 157 480 480',
    data: '<path pid="0" d="M445.4 224.2l-24-32a8.288 8.288 0 00-12.8 0l-24 32a7.998 7.998 0 00-1.6 4.8v376c0 17.673 14.327 32 32 32s32-14.327 32-32V229a7.998 7.998 0 00-1.6-4.8zM415 210.336l16 21.328V237h-32v-5.336l16-21.328zM399 541V253h32v288h-32zm32 16v16h-32v-16h32zm-16 64c-8.837 0-16-7.163-16-16v-16h32v16c0 8.837-7.163 16-16 16zm-56-464H7a8 8 0 00-8 8v136.8c-18.611 3.827-31.976 20.199-32 39.2v256c.026 22.08 17.92 39.974 40 40h288a8.038 8.038 0 005.656-2.344l64-64a7.998 7.998 0 001.6-2.344c.112-.248.176-.488.264-.744.23-.672.37-1.371.416-2.08 0-.168.096-.32.096-.488V165a8 8 0 00-8-8H359zm-8 16v384h-56a8 8 0 00-8 8v56h-72.208A39.583 39.583 0 00223 597V381h104a8 8 0 008-8V197a8 8 0 00-8-8H39a8 8 0 00-8 8v104H15V173h336zm-32 64h-56v-32h56v32zm0 16v112h-56V253h56zm-72-16h-56v-32h56v32zm-72 0h-56v-32h56v32zm-72 0H47v-32h56v32zm-56 16h56v48H47v-48zm72 0h56v48h-56v-48zm72 0h56v112h-24v-24c-.024-19.001-13.389-35.373-32-39.2V253zM7 621c-13.255 0-24-10.745-24-24V341c0-13.255 10.745-24 24-24h176c13.255 0 24 10.745 24 24v256c0 13.255-10.745 24-24 24H7zm296-11.312V573h36.688L303 609.688z"/><path pid="1" d="M7 405h176a8 8 0 008-8v-48a8 8 0 00-8-8H7a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h160v32H15v-32zm176 128h-48a8 8 0 00-8 8v96a8 8 0 008 8h48a8 8 0 008-8v-96a8 8 0 00-8-8zm-8 96h-32v-80h32v80zm8-160h-48a8 8 0 00-8 8v32a8 8 0 008 8h48a8 8 0 008-8v-32a8 8 0 00-8-8zm-8 32h-32v-16h32v16zm-64 32H71a8 8 0 00-8 8v32a8 8 0 008 8h48a8 8 0 008-8v-32a8 8 0 00-8-8zm-8 32H79v-16h32v16zm8-96H71a8 8 0 00-8 8v32a8 8 0 008 8h48a8 8 0 008-8v-32a8 8 0 00-8-8zm-8 32H79v-16h32v16zm-64 32H-1a8 8 0 00-8 8v32a8 8 0 008 8h48a8 8 0 008-8v-32a8 8 0 00-8-8zm-8 32H7v-16h32v16zm8-96H-1a8 8 0 00-8 8v32a8 8 0 008 8h48a8 8 0 008-8v-32a8 8 0 00-8-8zm-8 32H7v-16h32v16zm80 96H71a8 8 0 00-8 8v32a8 8 0 008 8h48a8 8 0 008-8v-32a8 8 0 00-8-8zm-8 32H79v-16h32v16zm-64-32H-1a8 8 0 00-8 8v32a8 8 0 008 8h48a8 8 0 008-8v-32a8 8 0 00-8-8zm-8 32H7v-16h32v16z"/>'
  }
})
