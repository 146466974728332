import formatCurrency from '@/utils/formatCurrency';

function percentageFormatter(value) {
  if (!value) { return '0 %'; }

  return `${Math.round(value * 100) / 100} %`;
}

function currencyFormatter(value, country = 'CL') {
  if (typeof value === 'string') { return value; }

  return formatCurrency(value, country);
}

export {
  percentageFormatter, currencyFormatter,
};
