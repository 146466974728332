<template lang="pug">
  div
    el-row
      el-col
        h3.title Recorrido Fee
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Rango de fecha:")
            date-input(:inputModel="search" modelKey="dateRange" type="daterange")
          el-form-item(label="Fecha de emisión:")
            date-input(:inputModel="search" modelKey="generationDate" type="daterange")
          el-form-item(label="Boleta emitida:")
            el-radio-group(v-model="search.generated")
              el-radio-button(label="all") Todo
              el-radio-button(label="emitted") Si
              el-radio-button(label="emitted_R48") Si, R48
              el-radio-button(label="process") En Proceso
              el-radio-button(label="not_emitted") No
              el-radio-button(label="rejected") Rechazadas
              el-radio-button(label="blank") En Blanco
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click="fetchRecorridoFee()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportRecorridoFee(pagination.current)"
            ) DESCARGAR
          el-form-item.filters__buttons-wrapper
            el-dropdown(@command="handleCommand")
              el-button(v-if="canSeeButton()" type="primary") Regenerar boletas
                i.el-icon--right.el-icon-arrow-down
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(command="rch-emit") RCH Emitir
                el-dropdown-item(command="rch-declare") RCH Declarar
                el-dropdown-item(command="regenerate-all") Regenerar boletas
    el-row
      el-col
        h4 Resumen
    el-row
      el-col
        data-table(
          :headers="summaryHeaders"
          :items="summaryItems"
          :loading="loading"
          :shownActions="false"
        )
    el-divider
    el-row(:key="renderTable")
      el-col
        report-table(
          :headers="headers"
          :height="600"
          :paginated="false"
          :rowModel="'infinite'"
          :blockSize="50"
          :blocksInCache="50"
          :maxBlocksInCache="100"
          :datasourceRequests="1"
          @updateTable="updateTable"
        )
    el-dialog(
      title="Ingrese número de seguimiento para declarar boletas Rechazadas"
      :visible.sync="dialogForm.visible"
      width="40%"
    )
      el-form(label-position="top" size="small")
        el-form-item(label="Número de seguimiento:")
          el-input(v-model="dialogForm.search.timestamp")
      span(slot="footer")
        el-button(size="small" @click="dialogForm.visible = false") Cancelar
        el-button(type="success" size="small" @click.prevent="declareRCH()") Declarar
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import ReportTable from '@/components/tables/ReportTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

export default {
  name: 'RecorridoFee',
  components: {
    SelectInput, DataTable, DateInput, ReportTable,
  },
  data() {
    return {
      renderTable: 0,
      summaryHeaders: [
        { key: 'title', label: '' },
        { key: 'quantity', label: 'Cantidad de boletas' },
        {
          key: 'fee_net_cost', label: 'Neto', type: 'currency', country: 'CL',
        },
        {
          key: 'fee_tax', label: 'IVA', type: 'currency', country: 'CL',
        },
        {
          key: 'fee_total', label: 'Bruto', type: 'currency', country: 'CL',
        },
      ],
      headers: [
        {
          headerName: 'Operador de bus', field: 'bus_operator', width: 280, filterType: 'text',
        },
        { headerName: 'Fecha de venta', field: 'paid_date' },
        {
          headerName: 'Booking', field: 'token', width: 120, filterType: 'text',
        },
        { headerName: 'Nº Boleta electrónica', field: 'invoice_id', filterType: 'text' },
        { headerName: 'Fecha de emisión', field: 'invoice_generated_at' },
        { headerName: 'Valor pasaje', field: 'ticket_price' },
        { headerName: 'Neto boleta', field: 'unit_invoice_net_cost' },
        { headerName: 'IVA boleta', field: 'unit_invoice_tax' },
        { headerName: 'Total boleta', field: 'unit_invoice_total', width: 170 },
        { headerName: 'AXA asistencia', field: 'insurance_ioc' },
        { headerName: 'Valor pagado', field: 'total_price' },
        {
          headerName: 'Estado', field: 'invoice_status', width: 120, filterType: 'selector', selectorOptions: this.statusesSelectOptions(),
        },
        { headerName: 'Acciones', field: 'editable', width: 150 },
      ],
      columns: [],
      items: [],
      search: {
        country: 'CL',
        dateRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        generationDate: [],
        generated: 'all',
        filters: {},
      },
      loading: false,
      pagination: {},
      summaryItems: [],
      setSummary: true,
      scrollId: null,
      paramsApi: null,
      data: [],
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setRecorridoFee();
            const lastRow = this.data.data_size;
            rowsParams.successCallback(this.data.fee_sales, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };

        this.paramsApi.api.setDatasource(dataSource);
      },
      dialogForm: { visible: false, search: { timestamp: '' } },
      validForm: true,
    };
  },
  methods: {
    async setRecorridoFee() {
      if (this._.isEmpty(this.search.dateRange)) { this.search.dateRange = []; }

      if (this._.isEmpty(this.search.generationDate)) { this.search.generationDate = []; }

      const response = await FinanceApi.get_recorrido_fee(this.scrollId, this.search);
      this.scrollId = response.body.data.scroll_id;
      if (this.setSummary) { this.summary(response); }

      return response.body.data;
    },
    async fetchRecorridoFee() {
      this.renderTable += 1;
      this.setSummary = true;
      this.scrollId = null;
    },
    summary(response) {
      this.summaryItems = response.body.summary;
      this.setSummary = false;
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;

      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length) {
          this.cleanSearchFilter('error');
        }
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
            this.cleanSearchFilter();
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.cleanSearchFilter('error');
      }
    },
    exportRecorridoFee(page) {
      this.loading = true;
      FinanceApi.get_recorrido_fee(page, this.search, true).then(() => {
        this.$message({ type: 'success', message: 'Reporte enviado' });
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    format(value) {
      return currencyFormatter(value);
    },
    handleCommand(command) {
      if (command === 'rch-emit') {
        FinanceApi.recorrido_fee_regenerate_emit_rch(this.search).then(() => {
          this.$message({ type: 'success', message: 'La información está siendo procesada, será notificado por correo cuando esté listo' });
        }).catch((exception) => {
          this.$notify.error({ title: 'Error', message: exception.body.message });
        });
      } else if (command === 'rch-declare') {
        this.dialogForm.visible = true;
      } else if (command === 'regenerate-all') {
        this.$confirm(`¿Deseas regenerar los DTE de los bookings encontrados entre ${this.search.dateRange[0]} - ${this.search.dateRange[1]}?`, 'Regenerar Boleta', {
          confirmButtonText: 'SI',
          cancelButtonText: 'NO',
          type: 'warning',
        }).then(() => {
          FinanceApi.regenerate_group_dte(this.search).then(() => {
            this.$notify({
              type: 'success',
              message: `Se están regenerando las boletas RCH, no emitidas y en blanco entre ${this.search.dateRange[0]} - ${this.search.dateRange[1]}. Este proceso tardará algunos minutos. Recargue la página para ver los cambios`,
            });
          }).catch((exception) => {
            this.$notify.error({ title: 'Error al regenerar boletas', message: exception.body.message });
          });
        }).catch(() => {});
      }
    },
    validateForm() {
      this.validForm = true;
      if (this.dialogForm.search.timestamp === '') {
        this.validForm = false;
        this.$notify({
          title: 'Campo incompleto',
          message: 'Debe ingresar un número de seguimiento',
          type: 'error',
        });
        return;
      }

      if (this.dialogForm.search.timestamp.length > 30) {
        this.validForm = false;
        this.$notify({
          title: 'Campo incompleto',
          message: 'Debe ingresar un número de seguimiento menor a 30 caracteres',
          type: 'error',
        });
        return;
      }

      if (!/^\d+$/.test(this.dialogForm.search.timestamp)) {
        this.validForm = false;
        this.$notify({
          title: 'Campo incompleto',
          message: 'Debe ingresar solo números',
          type: 'error',
        });
      }
    },
    declareRCH() {
      this.validateForm();
      if (this.validForm) {
        FinanceApi.recorrido_fee_regenerate_declare_rch(this.dialogForm.search)
          .then(() => {
            this.dialogForm.visible = false;
            this.dialogForm.search.timestamp = '';
            this.$message({
              type: 'success', message: 'Número de seguimiento aceptado, las boletas cambiarán a estado Emitida con R48. Este proceso puede tardar varios minutos',
            });
          }).catch((exception) => {
            if (exception.status === 422) {
              this.$notify.error({ title: 'Error', message: 'Número de seguimiento inválido' });
            } else {
              this.$notify.error({ title: 'Error', message: exception.body.message });
            }
          });
      }
    },
    cleanSearchFilter(status) {
      if (status === 'error') { this.search.filters = {}; }

      this.scrollId = null;
      this.setSummary = true;
    },
    statusesSelectOptions() {
      return [
        { key: 'Todos', value: '' },
        { key: 'Emitida', value: 'EPR,RPR' },
        { key: 'Emitida con R48', value: 'Emitida con R48' },
        { key: 'Rechazado', value: 'RPT,RFR,VOF,RCT,RCH - DTE Rechazado' },
        { key: 'En proceso', value: 'REC,SOK,FOK,PRD,CRT' },
        { key: 'No emitida', value: 'not_emitted' },
        { key: 'En Blanco', value: 'blank' },
      ];
    },
    formatConciliable(conciliable) {
      return {
        key: conciliable.name,
        value: conciliable.name,
      };
    },
    timestampRules() {
      return [
        { required: true, message: 'Ingresa un número de seguimiento', trigger: 'blur' },
        { type: 'number', message: 'Ingresa solo numeros', trigger: ['blur'] },
      ];
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
  },
};
</script>
