/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel': {
    width: 16,
    height: 16,
    viewBox: '2360 376.858 380.16 380.16',
    data: '<path pid="0" d="M2684.48 432.538c-35.921-35.918-83.601-55.68-134.402-55.68-50.797 0-98.476 19.762-134.398 55.68-35.918 35.922-55.68 83.601-55.68 134.398 0 50.801 19.762 98.481 55.68 134.402 35.922 35.918 83.601 55.68 134.398 55.68 50.801 0 98.481-19.762 134.402-55.68 35.918-35.921 55.68-83.601 55.68-134.402 0-50.797-19.762-98.476-55.68-134.398zm-15.281 253.519c-31.84 31.84-74.16 49.36-119.121 49.36-44.957 0-87.277-17.52-119.117-49.36-65.68-65.679-65.68-172.558 0-238.238 31.84-31.84 74.16-49.359 119.117-49.359 44.961 0 87.281 17.519 119.121 49.359 65.68 65.68 65.68 172.559 0 238.238zm0 0"/><path pid="1" d="M2633.84 483.178a10.765 10.765 0 00-15.281 0l-68.481 68.481-68.476-68.481a10.766 10.766 0 00-15.282 0 10.766 10.766 0 000 15.282l68.481 68.476-68.481 68.481a10.765 10.765 0 000 15.281c2.078 2.082 4.879 3.199 7.602 3.199 2.719 0 5.519-1.039 7.598-3.199l68.48-68.481 68.48 68.481c2.079 2.082 4.879 3.199 7.598 3.199 2.801 0 5.524-1.039 7.602-3.199a10.765 10.765 0 000-15.281l-68.321-68.481 68.481-68.476a10.774 10.774 0 000-15.282zm0 0"/>'
  }
})
