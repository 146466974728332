/* eslint no-param-reassign: ["error", { "props": false }] */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    isLogged: !!localStorage.getItem('token'),
    expiration: null,
    user: {},
    selected: {},
  },
  getters: {
    isLoggedIn: (state) => state.isLogged,
  },
  mutations: {
    login(state, data) {
      const { name, email, role } = data.user;
      state.isLogged = true;
      state.expiration = data.expiration;
      state.user = { name, email, role };
    },
    logout(state) {
      state.isLogged = false;
      state.expiration = '';
      state.user = {};
    },
    selectTotal(state, total) {
      state.selected = total;
    },
  },
  plugins: [createPersistedState()],
});

export default store;
