<template lang="pug">
  div
    el-row
      el-col
        h3.title Envío de Conciliaciones
    el-row
      el-col
        el-button(
          v-if="canSeeButton()"
          type="primary"
          icon="el-icon-message"
          @click="handleShippingConciliations()"
        ) ENVIAR CONCILIACIONES
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="resendedConciliations"
          :shownActions="false"
          :loading="loading"
          :height="500"
        )
        el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
    el-dialog(:title="dialogForm.title" :visible.sync="dialogForm.visible" width="60%")
      inline-form(:formAttributes="dialogForm.attributes")
      span(slot="footer")
        el-button(@click="closeDialog()") Cancelar
        a.el-button.el-button--primary(
          @click="mailerConciliationPreview()"
          :href="showMailerConciliationPreview()"
          target="_blank"
        ) Preview
        el-button(type="success"
          @click.prevent="saveResendedConciliation()") {{ primaryButtonText }}
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue';
import InlineForm from '@/components/forms/InlineForm.vue';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';
import setPagination from '@/utils/setPagination';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

export default {
  name: 'ResendedConciliations',
  components: {
    DataTable, InlineForm,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      previewMailerUrl: '',
      primaryButtonText: '',
      conciliableName: '',
      conciliableType: '',
      recipients: [],
      conciliationEmails: [],
      resendedConciliations: [],
      loading: false,
      validForm: true,
      dialogForm: { visible: false, attributes: {}, data: {} },
      pagination: {},
      search: {
        country: 'CL',
      },
    };
  },
  beforeMount() {
    this.fetchResendedConciliations(1);
  },
  methods: {
    closeDialog() {
      this.dialogForm.visible = false;
    },
    internalOrExternalParse(external) {
      if (external) {
        return 'Externa';
      }
      return 'Interna';
    },
    toCapitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    fetchResendedConciliations(page) {
      this.loading = true;
      FinanceApi.get_resended_conciliations(page).then((data) => {
        this.resendedConciliations = data.body.map((resended) => ({
          resended_type: `${this.toCapitalize(resended.shipping_type)} - ${this.internalOrExternalParse(resended.external)}`,
          ...resended,
        }));

        setPagination(this, data, page);
        this.loading = false;
      }).catch(() => { this.loading = false; });
    },
    handleCurrentChange(page) {
      this.fetchResendedConciliations(page);
    },
    handleShippingConciliations() {
      const attributes = this._.cloneDeep(this.formAttributes);
      this.dialogForm.title = 'Enviar conciliación';
      this.primaryButtonText = 'Enviar';
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = null;
      this.dialogForm.visible = true;
    },
    internal_or_external(value) {
      return value === '1';
    },
    mailerConciliationPreview() {
      this.validateForm();
      if (this.validForm) {
        const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
        const internalOrExternal = this.internal_or_external(attributes.internal_or_external);

        if (attributes.internal_or_external === '1') { this.recipients = attributes.emails; }

        this.previewMailerUrl = FinanceApi.url_mailer_conciliation_preview(this.conciliableName,
          internalOrExternal, attributes.date_range[0], attributes.date_range[1],
          this.recipients);
      }
    },
    validateForm() {
      const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
      this.validForm = true;

      if (attributes.conciliable_id === null) {
        this.validForm = false;
        this.$notify({
          title: 'Campo incompleto',
          message: 'Debe seleccionar un operador',
          type: 'error',
        });
      }

      if (attributes.internal_or_external === '1' && attributes.emails.length === 0) {
        this.validForm = false;
        this.$notify({
          title: 'Campo incompleto',
          message: 'Debe seleccionar un rango de fechas',
          type: 'error',
        });
      }

      if (attributes.day_or_range === null) {
        this.validForm = false;
        this.$notify({
          title: 'Campo incompleto',
          message: 'Debe seleccionar el tipo de envio de la conciliación',
          type: 'error',
        });
      } else {
        this.validateDateRange(attributes);
      }
    },
    validateDateRange(attributes) {
      if (attributes.date_range === null) {
        this.validForm = false;
        this.$notify({
          title: 'Campo incompleto',
          message: 'Debe seleccionar un rango de fechas',
          type: 'error',
        });
      }
    },
    showMailerConciliationPreview() {
      if (this.validForm) {
        return this.previewMailerUrl;
      }
      return null;
    },
    saveResendedConciliation() {
      this.validateForm();
      if (this.validForm) {
        this.loading = true;
        const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
        const externalOrInternal = this.internal_or_external(attributes.internal_or_external);
        let shippingType = '';

        if (!externalOrInternal) { this.conciliationEmails = []; }

        if (externalOrInternal && attributes.emails != null) {
          attributes.emails.forEach((email) => {
            this.conciliationEmails.push(email);
          });
        }

        if (attributes.day_or_range === '0') {
          shippingType = 'daily';
        } else if (attributes.day_or_range === '1') {
          shippingType = 'range';
        }

        const conciliationToResend = {
          conciliation_to_resend: {
            conciliable_type: this.conciliableType,
            conciliable_id: attributes.conciliable_id,
            from: attributes.date_range[0],
            to: attributes.date_range[1],
            shipping_type: shippingType,
            recipients: this.conciliationEmails,
            external: externalOrInternal,
          },
        };

        FinanceApi.resendConciliations(conciliationToResend).then((data) => {
          this.fetchResendedConciliations(1);
          this.closeDialog();

          if (data.body.status === 'success') {
            this.$notify({
              title: 'Perfecto!',
              message: 'Se están generando las conciliaciones solicitadas, por favor espere',
              type: 'success',
            });
          } else {
            this.$notify({
              title: 'Error',
              message: 'Se ha producido un error al reenviar la conciliación',
              type: 'error',
            });
          }
          this.loading = false;
        }).catch(() => { this.loading = false; });
      }
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    headers() {
      return [
        { key: 'sender_email', label: 'Usuario remitente' },
        { key: 'recipients', label: 'Usuarios receptores', type: 'stringarray' },
        { key: 'conciliable_name', label: 'Operador' },
        { key: 'resended_type', label: 'Tipo de conciliación' },
        {
          key: 'from', label: 'Desde', type: 'date', format: 'DD-MM-YYYY',
        },
        {
          key: 'to', label: 'Hasta', type: 'date', format: 'DD-MM-YYYY',
        },
        {
          key: 'created_at', label: 'Fecha y hora de envío', type: 'date', format: 'DD-MM-YYYY - HH:mm:ss',
        },
      ];
    },
    formAttributes() {
      const $this = this;
      return {
        rows: [
          [
            {
              key: 'conciliable_id',
              value: null,
              inputType: 'select',
              label: 'Selecciona el operador',
              options: $this.filterConciliables(this.search.country),
              optionKey: 'idKey',
              changeMethod(idKey) {
                let idName = '';
                FinanceApi.getConciliables().then((result) => {
                  result.body.forEach((item) => {
                    if (`${item.id}-${item.klass}` === idKey) {
                      $this.conciliableName = item.name;
                      $this.conciliableType = item.klass;
                      idName = `${item.id}-${item.name}`;
                    }
                  });
                });
                $this.conciliationEmails = [];
                FinanceApi.get_operators($this.search).then((data) => {
                  data.body.forEach((item) => {
                    if (`${item.id}-${item.name}` === idName) {
                      item.conciliation_emails.forEach((email) => {
                        $this.conciliationEmails.push(email);
                      });
                    }
                  });

                  if ($this.dialogForm.attributes.rows[3][0] !== undefined) {
                    $this.dialogForm.attributes.rows[3][0].label = `Adicionalmente se enviará a los destinatarios predefinidos para el operador: ${$this.conciliationEmails}`;
                  }

                  this.loading = false;
                }).catch((exception) => {
                  this.loading = false;
                  this.$notify.error({ title: 'Error', message: exception.body.message });
                });
              },
            },
            {
              key: 'country',
              value: 'CL',
              inputType: 'select',
              label: 'Selecciona país',
              options: this.countries,
              changeMethod(country) {
                const rows = flattenForm($this.dialogForm.attributes.rows);
                const operatorRow = rows.find((row) => row.key === 'conciliable_id');

                $this._.set(operatorRow, 'value', null);
                $this._.set(operatorRow, 'options', $this.filterConciliables(country));
              },
            },
          ],
          [
            {
              key: 'internal_or_external',
              value: '0',
              inputType: 'radio',
              options: [{ id: '0', label: 'No' }, { id: '1', label: 'Si' }],
              label: '¿Quieres enviar una conciliación externa?',
              optionKey: 'id',
              changeMethod(value) {
                if (value === '1') {
                  $this.dialogForm.attributes.rows[2][0].visible = false;
                  $this.dialogForm.attributes.rows[3][0].visible = false;
                } else {
                  $this.dialogForm.attributes.rows[2][0].visible = true;
                  $this.dialogForm.attributes.rows[3][0].visible = true;
                }
              },
            },
            {
              key: 'day_or_range',
              value: null,
              inputType: 'radio',
              options: [{ id: '0', label: 'Diaria' }, { id: '1', label: 'Rango' }],
              label: '¿Quieres enviar una conciliación diaria o de rango?',
              optionKey: 'id',
              changeMethod(value) {
                $this.dialogForm.attributes.rows[4][0].visible = false;
                if (value === '0') {
                  $this.dialogForm.attributes.rows[6][0].visible = true;
                  $this.dialogForm.attributes.rows[5][0].visible = false;
                } else if (value === '1') {
                  $this.dialogForm.attributes.rows[5][0].visible = true;
                  $this.dialogForm.attributes.rows[6][0].visible = false;
                }
              },
            },
          ],
          [
            {
              key: 'emails',
              value: '',
              inputType: 'select',
              multiple: true,
              allowCreate: true,
              label: 'Enviar a',
              optionKey: 'id',
              visible: true,
            },
          ],
          [
            {
              key: 'emails_message',
              value: null,
              inputType: 'description',
              label: `Adicionalmente se enviará a los destinatarios predefinidos para el operador: ${$this.conciliationEmails}`,
              visible: true,
            },
          ],
          [
            {
              key: 'date_range', value: null, inputType: 'daterange', label: 'Seleccione un rango de fechas', visible: true,
            },
          ],
          [
            {
              key: 'day_message', value: null, inputType: 'description', label: 'Se enviarán las conciliaciones diarias de las fechas solicitadas', visible: true,
            },
          ],
          [
            {
              key: 'day_message',
              value: null,
              inputType: 'description',
              label: 'Se enviará UNA conciliación del rango seleccionado. ESTA ACCIÓN PUEDE TARDAR MÁS TIEMPO DEPENDIENDO DE LA CANTIDAD DE DÍAS SELECCIONADOS',
              visible: true,
            },
          ],
        ],
      };
    },
  },
};
</script>
