const AG_GRID_LOCALE_ES = {
  // for set filter
  selectAll: 'Seleccionar Todo',
  searchOoo: 'Buscar',
  blanks: 'En blanco',

  // for number filter and text filter
  filterOoo: 'Filtrar',
  applyFilter: 'Aplicar Filtro',
  equals: 'Igual',
  notEqual: 'Distinto',
  blank: 'En blanco',
  notBlank: 'No en blanco',
  empty: 'Vacío',

  // for number filter
  lessThan: 'Menor que',
  greaterThan: 'Mayor que',
  lessThanOrEqual: 'Menor o igual que',
  greaterThanOrEqual: 'Mayor o igual que',
  inRange: 'En rango de',

  // for text filter
  contains: 'Contiene',
  notContains: 'No contiene',
  startsWith: 'Empieza con',
  endsWith: 'Termina con',

  // filter conditions
  andCondition: 'Y',
  orCondition: 'O',

  // the header of the default group column
  group: 'Grupo',

  // tool panel
  columns: 'Columnas',
  filters: 'Filtros',
  valueColumns: 'Valor de las columnas',
  pivotMode: 'Modo pivote',
  groups: 'Grupos',
  values: 'Valores',
  pivots: 'Pivotes',
  toolPanelButton: 'Panel de herramientas',

  // other
  noRowsToShow: 'No hay filas para mostrar',

  // enterprise menu
  pinColumn: 'Columna Pin',
  valueAggregation: 'Agregar valor',
  autosizeThiscolumn: 'Autoajustar esta columna',
  autosizeAllColumns: 'Ajustar todas las columnas',
  groupBy: 'Agrupar',
  ungroupBy: 'Desagrupar',
  resetColumns: 'Reiniciar columnas',
  expandAll: 'Expandir todo',
  collapseAll: 'Colapsar todo',
  toolPanel: 'Panel de Herramientas',
  export: 'Exportar',
  csvExport: 'Exportar a CSV',
  excelExport: 'Exportar a Excel (.xlsx)',
  excelXmlExport: 'Exportar a Excel (.xml)',

  // enterprise menu pinning
  pinLeft: 'Pin izquierdo',
  pinRight: 'Pin derecho',

  // enterprise menu aggregation and status bar
  sum: 'Suman',
  min: 'Mínimo',
  max: 'Máximo',
  none: 'Ninguno',
  count: 'Contar',
  average: 'Promedio',

  // standard menu
  copy: 'Copiar',
  copyWithHeaders: 'Copiar con cabeceras',
  paste: 'Pegar',
};

export default AG_GRID_LOCALE_ES;
