import Vue from 'vue';

const formatForm = (id, attributes) => {
  const model = {};
  if (id) { model.id = id; }

  attributes.forEach((attribute) => {
    const { key } = attribute;

    Vue._.set(model, key, attribute.value);
  });
  return model;
};

export default formatForm;
