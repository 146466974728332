<template lang="pug">
  el-form(:inline="true" label-position="top" size="mini")
    el-form-item(label="País:")
      select-input(:options="countries" :attribute="search" modelKey="country")
    el-form-item(label="Operadores:")
      select-input(
        :options="filteredConciliables"
        :attribute="search"
        modelKey="operators"
        :multiple="true"
      )
    el-form-item(label="Con Horas:")
      el-checkbox(v-model="search.withHours") {{ search.withHours ? 'Si' : 'No' }}
    el-form-item(label="Fecha de ventas:")
      date-input(
        :inputModel="search"
        modelKey="dateRange"
        :type="date.type"
        :format="date.format"
      )
    el-form-item(label="Tipo de pago:")
      select-input(
        :options="filterPaymentTypes"
        :attribute="search"
        modelKey="paymentTypes"
        :multiple="true"
      )
    el-form-item(label="Forma de pago:")
      select-input(
        :options="filterPaymentForms"
        :attribute="search"
        modelKey="paymentForms"
        :multiple="true"
      )
    el-form-item(label="Canal de venta:")
      select-input(
        :options="salesChannelMediums"
        :attribute="search"
        modelKey="salesChannelMediums"
        :multiple="true"
      )
    el-form-item(label="Subcanal de venta:")
      select-input(
        :options="filteredSalesChannelSources"
        :attribute="search"
        modelKey="salesChannelSources"
        :multiple="true"
      )
    el-form-item(label="Anulaciones:")
      select-input(
        :options="reportGenerationTypes"
        :attribute="search"
        modelKey="reportGenerationType"
      )
    el-form-item(label="Incluir cupones:" v-if="reportType == 'sales'")
      el-checkbox(v-model="search.withCoupons") {{ search.withCoupons ? 'Si' : 'No' }}
    el-form-item(label="Incluir ventas AXA:" v-if="reportType == 'sales'")
      el-checkbox(v-model="search.withInsurance") {{ search.withInsurance ? 'Si' : 'No' }}
    el-form-item(label="Incluir Recorrido FEE:" v-if="reportType == 'sales'")
      el-checkbox(v-model="search.withRecorridoFee") {{ search.withRecorridoFee ? 'Si' : 'No' }}
    el-form-item.filters__buttons-wrapper
      el-button(
        type="primary"
        icon="el-icon-search"
        @click.prevent="handleFetchSales()"
      ) FILTRAR
      el-button(
        v-if="canSeeButton()"
        type="success"
        icon="el-icon-download"
        @click.prevent="handleExportReport()"
      ) EXPORTAR
      el-button(
        v-if="canSeeButton()"
        type="info"
        icon="el-icon-message"
        @click.prevent="handleSendTicketReport()"
      ) ENVIAR
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import FinanceApi from '@/services/apiService';

export default {
  name: 'SalesFilters',
  props: ['search', 'countries'],
  components: {
    SelectInput, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      date: {
        type: 'daterange',
        format: 'yyyy-MM-dd',
      },
      pickerOptions: { firstDayOfWeek: 1 },
      agencies: [],
    };
  },
  beforeMount() {
    this.fetchAgencies();
  },
  methods: {
    handleFetchSales() {
      this.$emit('fetchSales');
    },
    handleExportReport() {
      this.$emit('exportReport');
    },
    handleSendTicketReport() {
      this.$emit('sendTicketReport');
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
    fetchAgencies() {
      FinanceApi.getAgencies().then((data) => {
        this.agencies = data.body;
      });
    },
  },
  computed: {
    paymentTypes() {
      return DataService.get_payment_types();
    },
    filterPaymentTypes() {
      return this.paymentTypes
        .filter((paymentType) => paymentType.countries.includes(this.search.country));
    },
    paymentForms() {
      return DataService.get_payment_forms();
    },
    filterPaymentForms() {
      if (this.isAgency) {
        return this.paymentForms.filter((paymentForm) => paymentForm.agency);
      }
      return this.paymentForms;
    },
    salesChannelMediums() {
      return DataService.get_sales_channel_mediums();
    },
    salesChannelSources() {
      const defaultValues = [
        { key: 'whitelabel', value: 'Whitelabel', agency: false },
        { key: 'recorrido', value: 'Recorrido', agency: false },
      ];

      if (this.isPaymentTypeSelected) {
        return defaultValues.concat({ key: 'agencia', value: 'Agencia', agency: false });
      }

      return defaultValues.concat(this.filteredB2cAgencies);
    },
    filteredSalesChannelSources() {
      if (this.isAgency) {
        return this.salesChannelSources.filter((saleChannelSource) => saleChannelSource.agency);
      }

      return this.salesChannelSources.filter((saleChannelSource) => !saleChannelSource.agency);
    },
    reportGenerationTypes() {
      return [
        { key: 'sales', value: 'Ventas' },
        { key: 'net_sales', value: 'Ventas Netas' },
        { key: 'cancelled', value: 'Anulaciones' },
        { key: 'operator_complaints', value: 'Reclamos responsabilidad operador' },
        { key: 'recorrido_complaints', value: 'Reclamos responsabilidad recorrido' },
      ];
    },
    reportType() {
      return this.search.reportGenerationType;
    },
    isAgency() {
      const { paymentTypes } = this.search;

      return paymentTypes.length > 0 && paymentTypes.every((paymentForm) => paymentForm === 'Agencia');
    },
    isPaymentTypeSelected() {
      const { paymentTypes } = this.search;

      return paymentTypes.length === 0;
    },
    withHours() {
      return this.search.withHours;
    },
    startDate() {
      return DateService.getDate(this.search.dateRange[0], null);
    },
    endDate() {
      return DateService.getDate(this.search.dateRange[1], null);
    },
    filteredB2cAgencies() {
      return this.agencies.map((b2cAgency) => (
        {
          key: b2cAgency.name, value: b2cAgency.name, id: b2cAgency.id, agency: true,
        }
      ));
    },
  },
  watch: {
    isAgency(newValue) {
      this.search.isAgency = newValue;
    },
    isPaymentTypeSelected(newValue) {
      this.search.isPaymentTypeSelected = newValue;
    },
    withHours(newValue) {
      let dateFormat = 'YYYY-MM-DD';
      if (newValue) {
        this.date = { type: 'datetimerange', format: 'yyyy-MM-dd HH:mm' };
        dateFormat = 'YYYY-MM-DD HH:mm';
      } else {
        this.date = { type: 'daterange', format: 'yyyy-MM-dd' };
      }
      this.search.dateRange = [
        DateService.startOfDate('day', this.startDate, dateFormat),
        DateService.endOfDate('day', this.startDate, dateFormat),
      ];
    },
  },
};
</script>
