/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-with-a-clock-rotate': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M62,7H59V6a1,1,0,0,0-1-1H53V4a3,3,0,0,0-6,0V5H43V4a3,3,0,0,0-6,0V5H33V4a3,3,0,0,0-6,0V5H23V4a3,3,0,0,0-6,0V5H13V4A3,3,0,0,0,7,4V5H2A1,1,0,0,0,1,6V58a1,1,0,0,0,1,1H3v3a1,1,0,0,0,1,1H62a1,1,0,0,0,1-1V8A1,1,0,0,0,62,7ZM3,57V19H57V57ZM49,4a1,1,0,0,1,2,0V8a1,1,0,0,1-2,0ZM39,4a1,1,0,0,1,2,0V8a1,1,0,0,1-2,0ZM29,4a1,1,0,0,1,2,0V8a1,1,0,0,1-2,0ZM19,4a1,1,0,0,1,2,0V8a1,1,0,0,1-2,0ZM9,4a1,1,0,0,1,2,0V8A1,1,0,0,1,9,8ZM7,7V8a3,3,0,0,0,6,0V7h4V8a3,3,0,0,0,6,0V7h4V8a3,3,0,0,0,6,0V7h4V8a3,3,0,0,0,6,0V7h4V8a3,3,0,0,0,6,0V7h4V17H3V7ZM61,61H5V59H58a1,1,0,0,0,1-1V9h2Z"/><path d="M40.18,27H41V25H37a1,1,0,0,0-1,1v4h2V27.76A13,13,0,0,1,25.36,50.15L24.64,52A14.81,14.81,0,0,0,30,53,15,15,0,0,0,40.18,27Z"/><path d="M22,48.24A13,13,0,0,1,34.64,25.85L35.36,24A14.81,14.81,0,0,0,30,23,15,15,0,0,0,19.82,49H19v2h4a1,1,0,0,0,1-1V46H22Z"/><path d="M21,38a9,9,0,1,0,9-9A9,9,0,0,0,21,38Zm15.92-1H35v2h1.92A7,7,0,0,1,31,44.92V43H29v1.92A7,7,0,0,1,23.08,39H25V37H23.08A7,7,0,0,1,29,31.08V33h2V31.08A7,7,0,0,1,36.92,37Z"/><path d="M31,35H29v3a1,1,0,0,0,.29.71l3,3,1.42-1.42L31,37.59Z"/>'
  }
})
