<template lang="pug">
div
  el-row
    el-col
      h3.title F. Reclamos - Vista Banco
  el-row
    el-col
      el-form(:inline="true" label-position="top" size="mini")
        el-form-item(label="Fecha de reclamo:")
          date-input(
            :inputModel="search" modelKey="dateRangeComplaint" type="daterange" :clearable="false"
            size="medium"
          )
        el-form-item(label="Fecha de aprobación:")
          date-input(
            :inputModel="search" modelKey="dateRangeApproved" type="daterange" size="medium"
          )
        el-form-item(label="Fecha de reembolso:")
          date-input(
            :inputModel="search" modelKey="dateRangeDevolution" type="daterange" size="medium"
          )
        el-form-item(label="Estado:" size="medium" style="width: 350px;")
          select-input(:options="stateOptions" :attribute="search" modelKey="aasmState")
        el-form-item(label="Método de reembolso:" size="medium" style="width: 350px;")
          select-input(:options="refundMethodOptions()" :attribute="search" modelKey="refundMethod")
        el-form-item.filters__buttons-wrapper
          el-button(
            type="primary"
            icon="el-icon-search"
            @click.prevent="fetchComplaints()"
          ) FILTRAR
          el-button(
              type="success"
              icon="el-icon-download"
              @click.prevent="confirmExport"
              :loading="loading"
              :disabled="false"
            ) EXPORTAR NÓMINA
          el-button(
              type="warning"
              icon="el-icon-upload2"
              @click.prevent="handleImportScotiabank"
              :disabled="false"
            ) SUBIR RESPUESTA NÓMINA
        el-form-item.filters__buttons-wrapper
          el-dropdown(@command="exportComplaints")
            el-button(
              type="success"
              Dropdown
            ) EXPORTAR TABLA
              i(class="el-icon-arrow-down el-icon--right")
            el-dropdown-menu(slot="dropdown")
              el-dropdown-item(command="all") Exportar todo
        el-form-item.filters__buttons-wrapper
          el-button(
            type="warning"
            icon="el-icon-s-finance"
            @click.prevent="handleRefundTransbank()"
          ) REEMBOLSAR TBK
        el-form-item.filters__buttons-wrapper
          el-button(
            type="warning"
            icon="el-icon-s-finance"
            @click.prevent="handleRefundOneclick()"
          ) REEMBOLSAR ONECLICK
  el-row
    el-col
      stats-table(
        :data="stats"
        :rowSize="20"
        :colSize="8"
      )
  el-row(:key="renderComplaints")
    el-col
      report-table(
        v-loading="submitLoading"
        :headers="headers"
        :height="600"
        :paginated="true"
        :pageSize="100"
        :rowModel="'infinite'"
        :blockSize="100"
        :blocksInCache="100"
        :datasourceRequests="1"
        @updateTable="updateTable"
      )
  el-dialog(
      title="Subir archivo del banco"
      :visible.sync="uploadDialog.visible"
      :close-on-click-modal="false"
      :show-close="false"
    )
      el-form(:inline="true" :model="uploadDialog" :rules="uploadFormRules()" ref="form")
        el-row
          el-col
            el-form-item(label="Selecciona un archivo XLS:" prop="files")
              el-upload(
                v-model="uploadDialog.files"
                action=""
                accept=".xls"
                :file-list="uploadDialog.files"
                :auto-upload="false"
                :on-change="handleChangeFile"
              )
                el-button(slot="trigger" size="small" type="primary") Buscar
        el-row
          el-form-item(label="Fecha de pago:")
            date-input(
              :inputModel="uploadDialog" modelKey="date" type="date"
              :clearable="false" :size="'small'"
            )
        el-row.inline-form__actions
          el-button(@click="closeUploadDialog()") Cancelar
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="uploadScotiabankFile()"
            :loading="submitLoading"
          ) Subir
  el-dialog(
    :title="this.summaryDialog.title"
    :visible.sync="summaryDialog.visible"
    :close-on-click-modal="false")
    el-row(:gutter="12")
      el-col(:span="8")
        el-result(icon="info" :title="this.summaryDialog.total"
        subTitle="Reclamos Totales")
      el-col(:span="8")
        el-result(icon="success" :title="this.summaryDialog.approved"
        subTitle="Reclamos Pagados")
      el-col(:span="8")
        el-result(icon="error" :title="this.summaryDialog.rejected"
        subTitle=`Reclamos Rechazados`)
  refund-modal(
    title="Se ha iniciado un proceso de reembolso TBK"
    entity="Reclamos"
    gender="o"
    :visible.sync="dialogTransbank"
    :loading="loading"
    :refundModal="refundModalTransbank"
    :responseModal="responseModalTransbank"
    :dateRange="search.dateRangeComplaint"
    :refundData="toRefundTransbank"
    @confirm="refundTransbank"
    @close="dialogTransbank = false"
  )
  refund-modal(
    title="Se ha iniciado un proceso de reembolso OneClick"
    entity="Reclamos"
    gender="o"
    :visible.sync="dialogOneclick"
    :loading="loading"
    :refundModal="refundModalOneclick"
    :responseModal="responseModalOneclick"
    :dateRange="search.dateRangeComplaint"
    :refundData="toRefundOneclick"
    @confirm="refundOneclick"
    @close="dialogOneclick = false"
  )
</template>

<script>
import ReportTable from '@/components/tables/ReportTable.vue';
import RefundModal from '@/components/ui/RefundModal.vue';
import StatsTable from '@/components/tables/StatsTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

// eslint-disable-next-line import/no-cycle
import DateService from '@/services/dateService';
import DataService from '@/services/dataService';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'GeneralView',
  components: {
    ReportTable, DateInput, StatsTable, RefundModal, SelectInput,
  },
  data() {
    return {
      renderComplaints: 0,
      search: {
        country: 'CL',
        aasmState: '',
        refundMethod: '',
        dateRangeComplaint: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        dateRangeApproved: [],
        dateRangeDevolution: [],
        filters: {},
      },
      searchComplaints: false,
      stats: [
        { title: 'RECLAMOS', total: 0 },
        { title: 'CLP', total: currencyFormatter(0) },
        { title: 'USD', total: currencyFormatter(0, 'USD') },
      ],
      loading: false,
      paramsApi: null,
      uploadDialog: {
        visible: false,
        confirmDate: false,
        confirmFilename: false,
        files: [],
        date: '',
      },
      summaryDialog: {
        title: '',
        visible: false,
        total: 0,
        approved: 0,
        rejected: 0,
      },
      submitLoading: false,
      batchComplaint: null,
      historicFilename: null,
      dialogTransbank: false,
      refundModalTransbank: false,
      responseModalTransbank: false,
      toRefundTransbank: {
        count: 0,
        total: 0,
        response: {
          count: 0,
          total: 0,
        },
        refund: {
          count: 0,
          total: 0,
        },
        reject: {
          count: 0,
          total: 0,
        },
      },
      dialogOneclick: false,
      refundModalOneclick: false,
      responseModalOneclick: false,
      toRefundOneclick: {
        count: 0,
        total: 0,
        response: {
          count: 0,
          total: 0,
        },
        refund: {
          count: 0,
          total: 0,
        },
        reject: {
          count: 0,
          total: 0,
        },
      },
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setComplaints(rowsParams.startRow, rowsParams.endRow);
            const lastRow = this.data.complaints_total;
            this.stats = this.formatStats(this.data.stats);
            rowsParams.successCallback(this.data.complaints, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };

        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  methods: {
    async fetchComplaints() {
      this.renderComplaints += 1;
    },
    async setComplaints(startRow, endRow) {
      if (this._.isEmpty(this.search.dateRangeDevolution)) {
        this.search.dateRangeDevolution = [];
      }
      if (this._.isEmpty(this.search.dateRangeApproved)) {
        this.search.dateRangeApproved = [];
      }
      this.searchComplaints = false;
      const response = await FinanceApi.get_bank_complaints(this.search, false, startRow, endRow);
      if (response.body.complaints.length > 0) { this.searchComplaints = true; }
      this.batchComplaint = response.body.batch_complaint;
      this.setRefundData(response);

      return response.body;
    },
    exportComplaints() {
      FinanceApi.get_bank_complaints(this.search, 'export').then(() => {
        this.$message({ type: 'success', message: 'Reporte enviado' });
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    confirmExport() {
      this.loading = true;
      if (this.searchComplaints) {
        this.$confirm('Al exportar nómina vas a cambiar el estado de Aprobado - Pendiente devolución a Aprobado - En proceso de devolución de todos los reclamos filtrados', '¡Cuidado!', {
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cerrar',
          type: 'warning',
        }).then(() => {
          this.exportScotiabank();
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay resultados para exportar' });
        this.loading = false;
      }
    },
    exportScotiabank() {
      this.submitLoading = true;
      FinanceApi.get_bank_complaints(this.search, 'payroll').then((res) => {
        this.fetchComplaintsDelay();
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
        this.submitLoading = false;
      });
    },
    handleImportScotiabank() {
      if (this.batchComplaint) {
        this.uploadDialog.visible = false;
        this.confirmDateMessage();
        this.submitLoading = false;
      } else {
        this.uploadDialog.visible = true;
      }
    },
    confirmDateMessage() {
      const message = `Hoy ${this.batchComplaint.updated_date} hubo una importación de ${this.batchComplaint.details.user.name} a las
          ${this.batchComplaint.updated_time}. ¿Estás seguro de que quieres importar de nuevo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.uploadDialog.confirmDate = true;
        this.uploadDialog.visible = true;
      }).catch(() => {
        this.uploadDialog.files = [];
        this.uploadDialog.date = '';
      });
    },
    confirmFilenameMessage() {
      const message = `El archivo ${this.uploadDialog.files[0].name} ya fue importado por
        ${this.historicFilename.details.user.name} el dia ${this.historicFilename.updated_date} a las ${this.historicFilename.updated_time}.
        ¿Estás seguro de que quieres importar este archivo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.uploadDialog.confirmFilename = true;
        this.uploadDialog.visible = false;
        this.uploadScotiabankFile();
      }).catch(() => {
        this.uploadDialog.files = [];
        this.uploadDialog.date = '';
        this.uploadDialog.visible = false;
      });
    },
    uploadDialogVisible() {
      this.uploadDialog.visible = true;
    },
    uploadScotiabankFile() {
      this.submitLoading = true;
      const { form } = this.$refs;
      form.validate((valid) => {
        if (valid) {
          const filename = this.uploadDialog.files[0].name;
          FinanceApi.historic_filename_import_complaints(filename, 'bank').then((data) => {
            this.historicFilename = data.body;
            if (!this.uploadDialog.confirmFilename && data.body) {
              this.uploadDialog.visible = false;
              this.confirmFilenameMessage();
              this.submitLoading = false;
            } else {
              const formData = new FormData();
              formData.append('file', this.uploadDialog.files[0]);
              formData.append('date', this.uploadDialog.date);

              FinanceApi.import_scotiabank_complaints(formData).then((response) => {
                const { total, approved, rejected } = response.body.data;
                this.summaryDialog.title = `Se ha importado correctamente el archivo ${this.uploadDialog.files[0].name}`;
                this.setSummaryDialog(total, approved, rejected);
                this.fetchComplaints();
                this.closeUploadDialog();
                this.submitLoading = false;
              }).catch(() => {
                this.summaryDialog.title = `No se logró importar el archivo ${this.uploadDialog.files[0].name}`;
                this.setSummaryDialog();
                this.uploadDialog.visible = false;
                this.submitLoading = false;
              });
            }
          });
        }
      });
    },
    closeUploadDialog() {
      this.uploadDialog.visible = false;
      this.uploadDialog.confirmDate = false;
      this.uploadDialog.confirmFilename = false;
      this.uploadDialog.files = [];
      this.uploadDialog.date = '';
    },
    uploadFormRules() {
      return {
        files: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
        date: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
      };
    },
    handleChangeFile(file) {
      // eslint-disable-next-line prefer-destructuring
      this.uploadDialog.files = [file.raw];
    },
    setSummaryDialog(total = 0, approved = 0, rejected = 0) {
      this.summaryDialog.visible = true;
      this.summaryDialog.total = total.toString();
      this.summaryDialog.approved = approved.toString();
      this.summaryDialog.rejected = rejected.toString();
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;

      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length) {
          this.search.filters = {};
        }
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.search.filters = {};
      }
    },
    formatStats(stats) {
      return [
        { title: 'RECLAMOS', total: stats.count },
        { title: 'CLP', total: currencyFormatter(stats.total_price, this.search.country) },
        { title: 'USD', total: currencyFormatter(stats.total_price_usd, 'USD') },
      ];
    },
    fetchComplaintsDelay() {
      setTimeout(() => {
        this.fetchComplaints();
        this.loading = false;
        this.submitLoading = false;
      }, 15000);
    },
    currencyOptions() {
      return DataService.SoldCurrencies(this.search.country);
    },
    categoryOptions() {
      return DataService.ComplaintCategories(this.search.country);
    },
    paymentMethodOptions() {
      return DataService.ComplaintPaymentMethod(this.search.country);
    },
    setRefundData(response) {
      this.toRefundTransbank.count = response.body.devolution_tbk_total;
      this.toRefundTransbank.total = currencyFormatter(response.body.devolution_tbk_total_amount);
      this.toRefundOneclick.count = response.body.devolution_oneclick_total;
      this.toRefundOneclick.total = currencyFormatter(
        response.body.devolution_oneclick_total_amount,
      );
    },
    handleRefundTransbank() {
      if (this.toRefundTransbank.count !== 0) {
        this.dialogTransbank = true;
        this.refundModalTransbank = true;
        this.responseModalTransbank = false;
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay reclamos con método de devolución Transbank pendientes para reembolsar' });
      }
    },
    refundTransbank() {
      this.loading = true;
      FinanceApi.refund_webpay_complaints(this.search, 'devolution_tbk').then((data) => {
        this.toRefundTransbank.response.count = data.body.total_complaints;
        this.toRefundTransbank.response.total = currencyFormatter(
          data.body.total_amount_complaints,
        );
        this.toRefundTransbank.refund.count = data.body.approved_complaints;
        this.toRefundTransbank.refund.total = currencyFormatter(
          data.body.approved_amount_complaints,
        );
        this.toRefundTransbank.reject.count = data.body.rejected_complaints;
        this.toRefundTransbank.reject.total = currencyFormatter(
          data.body.rejected_amount_complaints,
        );
        this.refundModalTransbank = false;
        this.responseModalTransbank = true;
        this.fetchComplaints();
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    handleRefundOneclick() {
      if (this.toRefundOneclick.count !== 0) {
        this.dialogOneclick = true;
        this.refundModalOneclick = true;
        this.responseModalOneclick = false;
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay reclamos con método de devolución OneClick pendientes para reembolsar' });
      }
    },
    refundOneclick() {
      this.loading = true;
      FinanceApi.refund_webpay_complaints(this.search, 'devolution_oneclick').then((data) => {
        this.toRefundOneclick.response.count = data.body.total_complaints;
        this.toRefundOneclick.response.total = currencyFormatter(
          data.body.total_amount_complaints,
        );
        this.toRefundOneclick.refund.count = data.body.approved_complaints;
        this.toRefundOneclick.refund.total = currencyFormatter(
          data.body.approved_amount_complaints,
        );
        this.toRefundOneclick.reject.count = data.body.rejected_complaints;
        this.toRefundOneclick.reject.total = currencyFormatter(
          data.body.rejected_amount_complaints,

        );
        this.refundModalOneclick = false;
        this.responseModalOneclick = true;
        this.fetchComplaints();
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    refundMethodOptions() {
      return DataService.ComplaintRefundMethod('CL', 'bank');
    },
  },
  computed: {
    headers() {
      return [
        {
          headerName: 'ID Reclamo', field: 'number', filterType: 'text', width: 100,
        },
        { headerName: 'Fecha Reclamo', field: 'created_at', width: 120 },
        { headerName: 'Estado', field: 'aasm_state', width: 450 },
        {
          headerName: 'Categoría', field: 'category', filterType: 'selector', selectorOptions: this.categoryOptions(), width: 300,
        },
        {
          headerName: 'Operador de bus', field: 'ticket_info.bus_operators', filterType: 'text', width: 250,
        },
        {
          headerName: 'Medio de pago', field: 'ticket_info.payment_method', filterType: 'selector', selectorOptions: this.paymentMethodOptions(), width: 150,
        },
        {
          headerName: 'Token', field: 'ticket_info.booking_number', filterType: 'text', width: 100,
        },
        {
          headerName: 'Tickets', field: 'ticket_info.tickets', filterType: 'text', width: 200,
        },
        {
          headerName: 'Moneda', field: 'ticket_info.sold_currency', filterType: 'selector', selectorOptions: this.currencyOptions(), width: 200,
        },
        { headerName: 'Fecha de aprobación', field: 'approved_at', width: 120 },
        {
          headerName: 'Monto Reembolso', field: 'reimbursement.amount', filterType: 'text', width: 150,
        },
        {
          headerName: 'Monto Reembolso Moneda Funcional', field: 'reimbursement.amount_ioc', filterType: 'text', width: 150,
        },
        {
          headerName: '% descuento al Operador', field: 'reimbursement.discount_percentage', filterType: 'text', width: 100,
        },
        {
          headerName: 'Monto descuento al operador Moneda Funcional', field: 'reimbursement.discount', filterType: 'text', width: 100,
        },
        {
          headerName: 'Método Reembolso', field: 'refund_method', width: 180,
        },
        { headerName: 'Fecha Reembolso', field: 'reimbursement.devolution_at', width: 100 },
        {
          headerName: 'ID Reembolso', field: 'reimbursement.tef_code', filterType: 'text', width: 200,
        },
      ];
    },
    stateOptions() {
      return DataService.ComplaintStates();
    },
  },
};
</script>
