<template lang="pug">
  #app
    el-container
      side-menu
      el-container
        router-view
</template>

<script>
import SideMenu from '@/components/layout/SideMenu.vue';

export default {
  components: {
    'side-menu': SideMenu,
  },
};
</script>
