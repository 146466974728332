<template lang="pug">
  div
    el-row
      el-col
        h3.title Configuración de días feriados - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Año:")
            date-input(:inputModel="search" modelKey="year" type="year" format="yyyy")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchHolidays"
            ) FILTRAR
            el-button(
                v-if="canSeeButton()"
                type="success"
                icon="el-icon-new-holiday"
                @click.prevent="createHolidayDialog()"
            ) Nuevo Feriado
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="holidays"
          :loading="loading"
          :height="500"
          :shownEdit="false"
        )
          template(slot="additionalActions" slot-scope="scope" v-if="scope.row.editable")
            el-button(
              v-if="canSeeButton()"
              type="warning"
              size="mini"
              @click.prevent="updateHolidayDialog(scope.row)"
            ) Editar
            el-button(
              v-if="canSeeButton()"
              type="danger"
              size="mini"
              @click.prevent="deleteHoliday(scope.row)"
            ) Eliminar
        el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
      el-dialog(:title="dialogForm.title" :visible.sync="dialogForm.visible" width="70%")
            inline-form(:formAttributes="dialogForm.attributes" ref="dialogForm")
            el-row.inline-form__actions
              el-button(@click="closeDialog()") Cancelar
              el-button(
                type="primary"
                @click.prevent="confirmUpdate()"
                :loading="submitLoading"
              ) {{ primaryButtonText }}
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import InlineForm from '@/components/forms/InlineForm.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import financeApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';
import setPagination from '@/utils/setPagination';

export default {
  name: 'Holidays',
  components: {
    SelectInput, InlineForm, DataTable, DateInput,
  },
  data() {
    return {
      primaryButtonText: '',
      headers: [
        { key: 'title', label: 'Nombre' },
        { key: 'extra', label: 'Categoría' },
        { key: 'date', label: 'Fecha' },
        { key: 'country', label: 'Pais' },
      ],
      holidays: [],
      search: {
        year: DateService.getDate(null, 'YYYY'),
        country: 'CL',
      },
      pagination: {},
      dialogForm: { visible: false, attributes: {}, data: {} },
      selectedCountry: null,
      defaultCountry: 'CL',
      loading: false,
      submitLoading: false,
    };
  },
  beforeMount() {
    this.fetchHolidays(1);
  },
  methods: {
    handleCurrentChange(page) {
      this.fetchHolidays(page);
    },
    fetchHolidays(page) {
      this.loading = true;
      financeApi.get_holidays(page, this.search).then((data) => {
        this.holidays = data.body;
        setPagination(this, data, page);
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
      this.selectedCountry = this.search.country;
    },
    createHolidayDialog() {
      const attributes = this._.cloneDeep(this.formAttributes);
      this.dialogForm.title = 'Nuevo día feriado';
      this.primaryButtonText = 'Crear';
      this.dialogForm.data = null;
      this.dialogForm.attributes = attributes;
      this.dialogForm.visible = true;
    },
    createHoliday() {
      this.startSubmitLoading();
      const { form } = this.$refs.dialogForm.$refs;
      form.validate((valid) => {
        if (valid) {
          const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
          financeApi.createHoliday(attributes).then(() => {
            this.closeDialog();
            this.fetchHolidays(1);
            this.$notify({
              title: 'Correcto',
              message: 'Feriado creado correctamente',
              type: 'success',
            });
            this.stopSubmitLoading();
          }).catch(() => {
            this.$notify({
              title: 'Error',
              message: 'Día feriado ya existe',
              type: 'error',
            });
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    updateHolidayDialog(data) {
      const attributes = this._.cloneDeep(this.formAttributes);
      const rows = flattenForm(attributes.rows);
      this.dialogForm.title = 'Editar dia feriado';
      this.primaryButtonText = 'Actualizar';
      // eslint-disable-next-line
      rows.forEach((row) => row.value = this._.get(data, row.key));
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = { ...data };
      this.dialogForm.visible = true;
    },
    confirmUpdate() {
      this.startSubmitLoading();
      const { form } = this.$refs.dialogForm.$refs;
      form.validate((valid) => {
        if (valid) {
          if (this.dialogForm.data) {
            this.$confirm('¿Estás seguro de que deseas modificar la información del feriado?', '¡Cuidado!', {
              confirmButtonText: 'SÍ',
              cancelButtonText: 'NO',
              type: 'warning',
            }).then(() => {
              this.updateHoliday();
            }).catch(() => {
              this.stopSubmitLoading();
            });
          } else {
            this.createHoliday();
          }
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    updateHoliday() {
      const { id } = this.dialogForm.data;
      const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
      financeApi.updateHoliday(id, attributes).then(() => {
        this.closeDialog();
        this.fetchHolidays(1);
        this.$notify({
          title: 'Correcto',
          message: 'Feriado modificado correctamente',
          type: 'success',
        });
        this.stopSubmitLoading();
      }).catch(() => {
        this.$notify({
          title: 'Error',
          message: 'Día feriado ya existe',
          type: 'error',
        });
        this.stopSubmitLoading();
      });
    },
    deleteHoliday(row) {
      this.$confirm('¿Estás seguro de eliminar el día feriado?', '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.startSubmitLoading();
        financeApi.deleteHoliday(row.id).then(() => {
          this.$notify({
            title: 'Eliminado',
            message: 'Feriado eliminado correctamente',
            type: 'success',
          });
          this.fetchHolidays();
          this.stopSubmitLoading();
        }).catch((exception) => {
          this.$notify.error({ title: 'Error', message: exception.body.message });
          this.stopSubmitLoading();
        });
      }).catch(() => {});
    },
    closeDialog() {
      this.dialogForm.visible = false;
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    holidayTypes() {
      return DataService.get_holiday_types();
    },
    formAttributes() {
      return {
        rows: [
          [
            {
              key: 'title', value: null, inputType: 'string', label: 'Nombre',
            },
            {
              key: 'extra', value: null, inputType: 'select', label: 'Categoría', options: this.holidayTypes,
            },
          ],
          [
            {
              key: 'date', value: null, inputType: 'date', label: 'Fecha',
            },
            {
              key: 'country', value: null, inputType: 'select', label: 'País', options: this.countries,
            },
          ],
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .holiday-form {
    &__row {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &__card {
      margin-left: 5px;
    }
  }
</style>
