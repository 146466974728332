const parse = require('parse-link-header');

function setPagination(Vue, response, page) {
  const links = Object.prototype.hasOwnProperty.call(response.headers.map, 'link') ? parse(response.headers.map.link[0]) : null;
  const total = parseInt(response.headers.map.total, 10);
  const perPage = parseInt(response.headers.map['per-page'][0], 10);
  const pagination = {
    current: page, total, perPage, ...links,
  };
  Vue.$set(Vue, 'pagination', pagination);
}

export default setPagination;
