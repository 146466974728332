let base;

if (process.env.VUE_APP_ENV_NAME === 'production') {
  base = 'https://finantzak-api.recorrido.cl';
} else if (process.env.VUE_APP_ENV_NAME === 'qa') {
  base = 'https://finantzak-qa-api.recorrido.cl';
} else if (process.env.VUE_APP_ENV_NAME === 'staging') {
  base = 'http://finance-api-staging.recorrido.cl';
} else {
  base = 'http://localhost:4000';
}

const apiWsUrl = `${process.env.VUE_APP_API_WS_PROTOCOL || 'ws'}://${base.replace(/^https?:\/\//, '')}/websocket`;

export default {
  BASE: base,
  API_URL: `${base}/api/v1`,
  API_WEBSOCKET_URL: apiWsUrl,
};
