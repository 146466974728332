<template lang="pug">
  select.ag-filter-select(v-model="selectedFilter")
    option(v-for="option in options" v-if="option" :value="option.value") {{ option.key }}
</template>

<script>
export default {
  name: 'ReportTableSelectorFilter',
  data() {
    return {
      selectedFilter: null,
      options: [],
    };
  },
  beforeMount() {
    this.options = this.params.options;
  },
  watch: {
    selectedFilter(newValue) {
      this.params.parentFilterInstance((instance) => {
        const previousSelectedFilter = instance.appliedModel?.filter;
        if (previousSelectedFilter !== newValue) {
          instance.onFloatingFilterChanged('equals', newValue);
        } else if (!this.options.map((e) => e.value).includes(newValue)) {
          this.selectedFilter = null;
        }
      });
    },
  },
  methods: {
    onParentModelChanged(parentModel) {
      this.selectedFilter = parentModel?.filter;
    },
  },
};
</script>
