<template lang="pug">
  h1 Logout
</template>

<script>
import constants from '@/constants/Url.constants';
import Vue from 'vue';
import store from '../store';
// eslint-disable-next-line import/no-cycle
import router from '../router';

export default {
  name: 'logout',
  mounted() {
    Vue.http.post(`${constants.BASE}/logout`, { user: { email: store.state.user.email } });
    window.localStorage.clear();
    store.commit('logout');
    router.push('login');
  },
};
</script>
