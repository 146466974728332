<template lang="pug">
div
  el-row
    el-col
      h3.title F. Reclamos - Vista Paypal
  el-row
    el-col
      el-form(:inline="true" label-position="top" size="mini")
        el-form-item(label="País:" size="medium")
          select-input(:options="countries" :attribute="search" modelKey="country")
        el-form-item(label="Fecha de reclamo:")
          date-input(
            :inputModel="search" modelKey="dateRangeComplaint" type="daterange" :clearable="false"
            size="medium"
          )
        el-form-item(label="Fecha de aprobación:")
          date-input(
            :inputModel="search" modelKey="dateRangeApproved" type="daterange" size="medium"
          )
        el-form-item(label="Fecha de reembolso:")
          date-input(
            :inputModel="search" modelKey="dateRangeDevolution" type="daterange"  size="medium"
          )
        el-form-item(label="Estado:" size="medium" style="width: 350px;")
          select-input(:options="stateOptions" :attribute="search" modelKey="aasmState")
        el-form-item(label="Método de reembolso:" size="medium" style="width: 350px;")
          select-input(:options="refundMethodOptions()" :attribute="search" modelKey="refundMethod")
        el-form-item.filters__buttons-wrapper
          el-button(
            type="primary"
            icon="el-icon-search"
            @click.prevent="fetchComplaints()"
          ) FILTRAR
        el-form-item.filters__buttons-wrapper
          el-button(
            type="success"
            icon="el-icon-download"
            @click.prevent="confirmExport"
            :loading="loading"
          ) EXPORTAR NÓMINA
        el-form-item.filters__buttons-wrapper
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="handleImportPaypal"
            :disabled="false"
          ) IMPORTAR NÓMINA
        el-form-item.filters__buttons-wrapper
          el-dropdown(@command="exportComplaints")
            el-button(
              type="success"
              Dropdown
            ) EXPORTAR TABLA
              i(class="el-icon-arrow-down el-icon--right")
            el-dropdown-menu(slot="dropdown")
              el-dropdown-item(command="all") Exportar todo
        el-form-item.filters__buttons-wrapper
          el-button(
              type="warning"
              icon="el-icon-d-arrow-left"
              @click.prevent="handleRefundPaypal()"
              :disabled="refundDisabled()"
            ) REEMBOLSAR
  el-row
    el-col
      stats-table(
        :data="stats"
        :rowSize="20"
        :colSize="8"
      )
  el-row(:key="renderComplaints")
    el-col
      report-table(
        v-loading="submitLoading"
        :headers="headers"
        :height="600"
        :paginated="true"
        :pageSize="100"
        :rowModel="'infinite'"
        :blockSize="100"
        :blocksInCache="100"
        :datasourceRequests="1"
        @updateTable="updateTable"
      )
  el-dialog(title="Reembolsos Paypal" :visible.sync="refundDialog.visible" v-loading="loading")
    el-row
      h4 Detalle del pago:
      el-col
        el-row
          el-col(:span="6" :offset="6") Desde
          el-col(:span="6") Hasta
        el-row
          el-col(:span="6") Rango de fechas:
          el-col(:span="6")
            span(v-text="refundDialog.from")
          el-col(:span="6")
            span(v-text="refundDialog.to")
        el-row
          el-col(:span="6") Cantidad de reclamos:
          el-col(:span="8")
            span(v-text="refundDialog.count")
        el-row
          el-col(:span="6") Monto total a reembolsar:
          el-col(:span="8")
            span {{ refundDialog.total }} USD
    el-row.inline-form__actions
          el-button(@click="closeRefundDialog()") Cancelar
          el-button(
            type="primary"
            @click.prevent="refundPaypalComplaints()"
            :loading="refundLoading"
          ) Reembolsar
  el-dialog(
    title="Ha terminado el proceso de reembolso Paypal"
    :visible.sync="summaryDialog.visible"
    :close-on-click-modal="false")
    el-row(:gutter="12")
      el-col(:span="8")
        el-result(icon="info" :title="this.summaryDialog.total"
        subTitle="Reclamos Totales")
      el-col(:span="8")
        el-result(icon="success" :title="this.summaryDialog.approved"
        subTitle="Reclamos Pagados")
      el-col(:span="8")
        el-result(icon="error" :title="this.summaryDialog.rejected"
        subTitle=`Reclamos Rechazados`)
  el-dialog(
      title="Subir archivo de PayPal"
      :visible.sync="uploadDialog.visible"
      :close-on-click-modal="false"
      :show-close="false"
    )
      el-form(:inline="true" :model="uploadDialog" :rules="uploadFormRules()" ref="form")
        el-row
          el-col
            el-form-item(label="Selecciona un archivo TXT:" prop="files")
              el-upload(
                v-model="uploadDialog.files"
                action=""
                accept=".txt"
                :file-list="uploadDialog.files"
                :auto-upload="false"
                :on-change="handleChangeFile"
              )
                el-button(slot="trigger" size="small" type="primary") Buscar
        el-row
          el-form-item(label="Fecha de pago:")
            date-input(
              :inputModel="uploadDialog" modelKey="date" type="date"
              :clearable="false" :size="'small'"
            )
        el-row.inline-form__actions
          el-button(@click="closeUploadDialog()") Cancelar
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="uploadPaypalFile()"
            :loading="submitLoading"
          ) Subir
  el-dialog(
    :title="this.importSummaryDialog.title"
    :visible.sync="importSummaryDialog.visible"
    :close-on-click-modal="false")
    el-row(:gutter="12")
      el-col(:span="8")
        el-result(icon="info" :title="this.importSummaryDialog.total"
        subTitle="Reclamos Totales")
      el-col(:span="8")
        el-result(icon="success" :title="this.importSummaryDialog.approved"
        subTitle="Reclamos Pagados")
      el-col(:span="8")
        el-result(icon="error" :title="this.importSummaryDialog.rejected"
        subTitle=`Reclamos Rechazados`)
</template>

<script>
import ReportTable from '@/components/tables/ReportTable.vue';
import StatsTable from '@/components/tables/StatsTable.vue';
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';
import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

// eslint-disable-next-line import/no-cycle
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'PaypalView',
  components: {
    ReportTable, SelectInput, DateInput, StatsTable,
  },
  data() {
    return {
      renderComplaints: 0,
      search: {
        country: 'CL',
        aasmState: '',
        refundMethod: '',
        dateRangeComplaint: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        dateRangeApproved: [],
        dateRangeDevolution: [],
        filters: {},
      },
      searchComplaints: false,
      stats: [
        { title: 'RECLAMOS', total: 0 },
        { title: 'CLP', total: currencyFormatter(0) },
        { title: 'USD', total: currencyFormatter(0, 'USD') },
      ],
      refundDialog: {
        visible: false,
        from: DateService.getDate(null, 'DD-MM-YYYY'),
        to: DateService.getDate(null, 'DD-MM-YYYY'),
        count: 0,
        total: '0 USD',
      },
      summaryDialog: {
        visible: false,
        total: 0,
        approved: 0,
        rejected: 0,
      },
      loading: false,
      refundLoading: false,
      paramsApi: null,
      uploadDialog: {
        visible: false,
        confirmDate: false,
        confirmFilename: false,
        files: [],
        date: '',
      },
      importSummaryDialog: {
        title: '',
        visible: false,
        total: 0,
        approved: 0,
        rejected: 0,
      },
      submitLoading: false,
      batchComplaint: null,
      historicFilename: null,
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setComplaints(rowsParams.startRow, rowsParams.endRow);
            const lastRow = this.data.complaints_total;
            this.stats = this.formatStats(this.data.stats);
            rowsParams.successCallback(this.data.complaints, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };

        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  methods: {
    async fetchComplaints() {
      this.renderComplaints += 1;
    },
    async setComplaints(startRow, endRow) {
      if (this._.isEmpty(this.search.dateRangeDevolution)) {
        this.search.dateRangeDevolution = [];
      }
      if (this._.isEmpty(this.search.dateRangeApproved)) {
        this.search.dateRangeApproved = [];
      }
      this.searchComplaints = false;
      const response = await FinanceApi.get_paypal_complaints(this.search, false, startRow, endRow);
      if (response.body.complaints.length > 0) { this.searchComplaints = true; }
      this.batchComplaint = response.body.batch_complaint;
      this.setRefundData(response);

      return response.body;
    },
    exportComplaints() {
      this.loading = true;
      FinanceApi.get_paypal_complaints(this.search, 'export').then(() => {
        this.$message({ type: 'success', message: 'Reporte enviado' });
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    confirmExport() {
      this.loading = true;
      if (this.searchComplaints) {
        this.$confirm('Al exportar nómina vas a cambiar el estado de Aprobado - Pendiente devolución a Aprobado - En proceso de devolución de todos los reclamos filtrados', '¡Cuidado!', {
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cerrar',
          type: 'warning',
        }).then(() => {
          this.exportPaypal();
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay resultados para exportar' });
        this.loading = false;
      }
    },
    exportPaypal() {
      this.loading = true;
      this.submitLoading = true;
      FinanceApi.get_paypal_complaints(this.search, 'payroll').then((res) => {
        this.fetchComplaintsDelay();
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
        this.submitLoading = false;
      });
    },
    handleImportPaypal() {
      if (this.batchComplaint) {
        this.uploadDialog.visible = false;
        this.confirmDateMessage();
        this.submitLoading = false;
      } else {
        this.uploadDialog.visible = true;
      }
    },
    confirmDateMessage() {
      const message = `Hoy ${this.batchComplaint.updated_date} hubo una importación de ${this.batchComplaint.details.user.name} a las
          ${this.batchComplaint.updated_time}. ¿Estás seguro de que quieres importar de nuevo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.uploadDialog.confirmDate = true;
        this.uploadDialog.visible = true;
      }).catch(() => {
        this.uploadDialog.files = [];
        this.uploadDialog.date = '';
      });
    },
    confirmFilenameMessage() {
      const message = `El archivo ${this.uploadDialog.files[0].name} ya fue importado por
        ${this.historicFilename.details.user.name} el dia ${this.historicFilename.updated_date} a las ${this.historicFilename.updated_time}.
        ¿Estás seguro de que quieres importar este archivo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.uploadDialog.confirmFilename = true;
        this.uploadDialog.visible = false;
        this.uploadPaypalFile();
      }).catch(() => {
        this.uploadDialog.files = [];
        this.uploadDialog.date = '';
        this.uploadDialog.visible = false;
      });
    },
    uploadDialogVisible() {
      this.uploadDialog.visible = true;
    },
    uploadPaypalFile() {
      this.submitLoading = true;
      const { form } = this.$refs;
      form.validate((valid) => {
        if (valid) {
          const filename = this.uploadDialog.files[0].name;
          FinanceApi.historic_filename_import_complaints(filename, 'paypal').then((data) => {
            this.historicFilename = data.body;
            if (!this.uploadDialog.confirmFilename && data.body) {
              this.uploadDialog.visible = false;
              this.confirmFilenameMessage();
              this.submitLoading = false;
            } else {
              const formData = new FormData();
              formData.append('file', this.uploadDialog.files[0]);
              formData.append('date', this.uploadDialog.date);

              FinanceApi.import_paypal_complaints(formData).then((response) => {
                const { total, approved, rejected } = response.body.data;
                this.importSummaryDialog.title = `Se ha importado correctamente el archivo ${this.uploadDialog.files[0].name}`;
                this.setSummaryDialog(total, approved, rejected);
                this.fetchComplaints();
                this.closeUploadDialog();
                this.submitLoading = false;
              }).catch(() => {
                this.importSummaryDialog.title = `No se logró importar el archivo ${this.uploadDialog.files[0].name}`;
                this.setSummaryDialog();
                this.uploadDialog.visible = false;
                this.submitLoading = false;
              });
            }
          });
        }
      });
    },
    closeUploadDialog() {
      this.uploadDialog.visible = false;
      this.uploadDialog.confirmDate = false;
      this.uploadDialog.confirmFilename = false;
      this.uploadDialog.files = [];
      this.uploadDialog.date = '';
    },
    uploadFormRules() {
      return {
        files: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
        date: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
      };
    },
    handleChangeFile(file) {
      // eslint-disable-next-line prefer-destructuring
      this.uploadDialog.files = [file.raw];
    },
    setSummaryDialog(total = 0, approved = 0, rejected = 0) {
      this.importSummaryDialog.visible = true;
      this.importSummaryDialog.total = total.toString();
      this.importSummaryDialog.approved = approved.toString();
      this.importSummaryDialog.rejected = rejected.toString();
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;

      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length) {
          this.search.filters = {};
        }
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.search.filters = {};
      }
    },
    formatStats(stats) {
      let titleValue = '';
      switch (this.search.country) {
        case 'CL': titleValue = 'CLP'; break;
        case 'PE': titleValue = 'PEN'; break;
        case 'MX': titleValue = 'MXN'; break;
        default:
      }
      return [
        { title: 'RECLAMOS', total: stats.count },
        { title: titleValue, total: currencyFormatter(stats.total_price, this.search.country) },
        { title: 'USD', total: currencyFormatter(stats.total_price_usd, 'USD') },
      ];
    },
    fetchComplaintsDelay() {
      setTimeout(() => {
        this.fetchComplaints();
        this.loading = false;
        this.submitLoading = false;
      }, 15000);
    },
    currencyOptions() {
      return DataService.SoldCurrencies(this.search.country);
    },
    categoryOptions() {
      return DataService.ComplaintCategories(this.search.country);
    },
    paymentMethodOptions() {
      return DataService.ComplaintPaymentMethod(this.search.country);
    },
    refundMethodOptions() {
      return DataService.ComplaintRefundMethod(this.search.country, 'paypal');
    },
    handleRefundPaypal() {
      if (this.refundDialog.count !== 0) {
        this.refundDialog.visible = true;
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay reclamos con método de devolución PayPal pendientes para reembolsar' });
      }
    },
    async refundPaypalComplaints() {
      this.refundLoading = true;
      const response = await FinanceApi.refund_paypal_complaints(this.search);
      this.summaryDialog.total = response.body.total_complaints;
      this.summaryDialog.approved = response.body.approved_complaints;
      this.summaryDialog.rejected = response.body.rejected_complaints;
      this.fetchComplaints();
      this.refundLoading = false;
      this.refundDialog.visible = false;
      this.summaryDialog.visible = true;
    },
    closeRefundDialog() {
      this.refundDialog.visible = false;
    },
    setRefundData(response) {
      this.refundDialog.from = DateService.getDate(this.search.dateRangeComplaint[0], 'DD-MM-YYYY');
      this.refundDialog.to = DateService.getDate(this.search.dateRangeComplaint[1], 'DD-MM-YYYY');
      this.refundDialog.count = response.body.devolution_paypal_total;
      this.refundDialog.total = currencyFormatter(response.body.devolution_paypal_total_amount, 'USD');
    },
    refundDisabled() {
      return this.search.country === 'MX';
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    headers() {
      return [
        {
          headerName: 'ID Reclamo', field: 'number', filterType: 'text', width: 100,
        },
        { headerName: 'Fecha Reclamo', field: 'created_at', width: 100 },
        { headerName: 'Estado', field: 'aasm_state', width: 450 },
        {
          headerName: 'Categoría', field: 'category', filterType: 'selector', selectorOptions: this.categoryOptions(), width: 300,
        },
        {
          headerName: 'Operador de bus', field: 'ticket_info.bus_operators', filterType: 'text', width: 250,
        },
        {
          headerName: 'Medio de pago', field: 'ticket_info.payment_method', filterType: 'selector', selectorOptions: this.paymentMethodOptions(), width: 100,
        },
        {
          headerName: 'Token', field: 'ticket_info.booking_number', filterType: 'text', width: 100,
        },
        {
          headerName: 'Tickets', field: 'ticket_info.tickets', width: 200, filterType: 'text',
        },
        {
          headerName: 'Moneda', field: 'ticket_info.sold_currency', filterType: 'selector', selectorOptions: this.currencyOptions(), width: 100,
        },
        { headerName: 'Fecha de aprobación', field: 'approved_at', width: 120 },
        {
          headerName: 'Monto Reembolso', field: 'reimbursement.amount', filterType: 'text', width: 100,
        },
        {
          headerName: 'Monto Reembolso Moneda Funcional', field: 'reimbursement.amount_ioc', filterType: 'text', width: 100,
        },
        {
          headerName: '% descuento al Operador', field: 'reimbursement.discount_percentage', filterType: 'text', width: 100,
        },
        {
          headerName: 'Monto descuento al operador Moneda Funcional', field: 'reimbursement.discount', filterType: 'text', width: 100,
        },
        { headerName: 'Método Reembolso', field: 'refund_method', width: 160 },
        {
          headerName: 'Fecha Reembolso', field: 'reimbursement.devolution_at', width: 100,
        },
        {
          headerName: 'ID Reembolso', field: 'reimbursement.tef_code', filterType: 'text', width: 200,
        },
      ];
    },
    stateOptions() {
      return DataService.ComplaintStates();
    },
  },
};
</script>
