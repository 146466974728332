<template lang="pug">
  el-container
    el-menu(
      :collapse="isCollapse"
      mode="vertical"
      :collapse-transition="true"
      v-if="loggedIn"
      :router="true"
      class="sub-menu"
    )
      el-menu-item(
        v-for="submenuOption in submenuOptions"
        v-if="submenuOption.name"
        :index="'index'"
        :route="{ path: submenuOption.children[0].path }"
        :title="submenuOption.meta.title"
        :class="activeSubMenuClass(submenuOption.path)"
        )
          template(slot="title") {{submenuOption.meta.title}}
          svgicon.submenu-svg(:icon="submenuOption.meta.icon" width="20" height="20")
      el-radio-group(
          v-model="isCollapse"
          class="radio-group-collapse"
      )
        el-radio-button(:label="false" class="radio-button-collapse") >
        el-radio-button(:label="true" class="radio-button-collapse") <
    tab-menu
</template>

<script>
import store from '@/store';

import TabMenu from '@/components/layout/TabMenu.vue';

export default {
  name: 'SubMenu',
  components: {
    'tab-menu': TabMenu,
  },
  data() {
    return {
      isCollapse: true,
    };
  },
  methods: {
    menuClick({ path }) {
      this.$router.push({ path });
    },
    activeSubMenuClass(path) {
      let itemStyle = '';
      const activePage = this.activeIndex();
      if (activePage.includes(path)) {
        itemStyle = 'sub-menu-is-active';
      }
      return itemStyle;
    },
    activeIndex() {
      return this.$route.path;
    },
  },
  computed: {
    loggedIn() {
      return store.state.isLogged;
    },
    submenuOptions() {
      const userRole = store.state.user.role;
      const parent = this.$route.matched[this.$route.matched.length - 2];
      let name = '';
      if (parent !== undefined) {
        if (parent.meta.level === 2) {
          name = parent.parent.name;
        } else if (parent.meta.level === 1) {
          name = parent.name;
        }
      } else {
        name = this.$route.name;
      }
      const { routes } = this.$router.options;
      let navs = [];
      routes.forEach((route) => {
        if (route.name === name && (!route.meta.roles || route.meta.roles.includes(userRole))) {
          navs = route.children.filter((child) => !child.meta.roles
          || child.meta.roles.includes(userRole));
        }
      });

      return navs;
    },
  },
};
</script>
<style>
.el-radio-button__inner{
  padding: 12px 8px;
}
</style>
