const changeTitle = (to, from, next) => {
  const { name } = to;
  const TITLE = {
    login: 'Iniciar Sesión',
    home: 'Home',
    'pending-transfers-bice': 'TEF Pendientes BICE',
    'pending-transfers-scotiabank': 'TEF Pendientes Scotiabank',
    bank_transfers: 'Transferencias Bancarias',
    conciliations: 'Conciliaciones',
    'shipping-conciliation': 'Envío de Conciliaciones',
    discounts_history: 'Historial de Descuentos',
    discounts_closed: 'Agendar descuentos (cerrados)',
    discounts_approved: 'Agendar descuentos (aprobados)',
    complaints_report: 'Reclamos',
    devolution_coupons: 'Códigos de reembolso',
    sales: 'Ventas',
    tbk_transactions: 'Transaciones Transbank',
    automatic_cancellations: 'Cancelaciones Automáticas',
    insurance_report: 'Envío de Reportes de seguros',
    master_sales: 'Master Ventas',
    master_incomes: 'Master Ingresos',
    monthly_deferred_sales: 'Ventas Diferidas Mensuales',
    daily_deferred_sales: 'Ventas Diferidas Diarias',
    operators: 'Operadores',
    groups: 'Grupos',
    agencies: 'Agencias',
    holidays: 'Diás Feriados',
    general_settings: 'Configuraciones generales',
    recorrido_fee_view: 'Recorrido Fee',
  };

  document.title = 'Recorrido FINANCE';

  if (TITLE[name]) {
    document.title = `${TITLE[name]} | FINANCE`;
  }
  next();
};

export {
  // eslint-disable-next-line
  changeTitle,
};
