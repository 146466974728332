<template lang="pug">
    div
      el-row
        el-col
          h3.title Summary Agencies
      el-row
        el-col
          el-form(:inline="true" label-position="top" size="mini")
            el-form-item(label="Rango de Fecha:")
              date-input(:inputModel="search" modelKey="dateRange" type="daterange")
            el-form-item(label="País:")
              select-input(:options="countries" :attribute="search" modelKey="country" disabled)
            el-form-item(label="Formato de Fecha:")
              select-input(:options="formatDate" :attribute="search" modelKey="formatDate")
            el-form-item.filters__buttons-wrapper
              el-button(
                type="primary"
                icon="el-icon-search"
                @click="fetchSummaryAgencies()"
              ) FILTRAR
              el-button(
                type="success"
                icon="el-icon-download"
                @click.prevent="exportSummaryAgencies()"
              ) EXPORTAR
      el-row(:key="renderTable")
        el-col
          report-table(
            v-loading="loading"
            :headers="headers"
            :height="600"
            :paginated="true"
            :pageSize="100"
            :rowModel="'infinite'"
            :blockSize="100"
            :blocksInCache="100"
            :maxBlocksInCache="100"
            :datasourceRequests="1"
            @updateTable="updateTable"
          )
  </template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import ReportTable from '@/components/tables/ReportTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import handleDownload from '@/utils/handleDownload';

export default {
  name: 'SummaryAgencies',
  components: {
    SelectInput, DataTable, DateInput, ReportTable,
  },
  data() {
    return {
      renderTable: 0,
      headers: [
        { headerName: 'Fecha', field: 'date', filterType: 'text' },
        { headerName: 'Agencia', field: 'agency', filterType: 'text' },
        { headerName: 'Tickets', field: 'tickets', filterType: 'text' },
        { headerName: 'Transacciones', field: 'transactions', filterType: 'text' },
        { headerName: 'GMV', field: 'gmv', filterType: 'text' },
        { headerName: 'Revenue', field: 'revenue', filterType: 'text' },
        { headerName: 'Finance GMV', field: 'finance_gmv', filterType: 'text' },
        { headerName: 'Finance Revenue', field: 'finance_revenue', filterType: 'text' },
      ],
      columns: [],
      search: {
        country: 'CL',
        date: '',
        dateRange: [
          DateService.addDate(-1, 'day', null),
          DateService.addDate(-1, 'day', null),
        ],
        formatDate: 'daily',
        filters: {},
      },
      loading: false,
      pagination: {},
      paramsApi: null,
      data: [],
      currentPage: 1,
      totalRows: 0,
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setSummaryAgencies();
            const lastRow = this.totalRows;
            rowsParams.successCallback(this.data, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };
        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  methods: {
    async setSummaryAgencies() {
      if (this._.isEmpty(this.search.dateRange)) { this.search.dateRange = []; }

      const response = await FinanceApi.get_summary_agencies(this.currentPage, this.search, false);
      this.totalPages = response.body.total_pages;
      this.totalRows = response.body.total_records;
      this.currentPage += 1;
      this.loading = false;
      return response.body.records;
    },
    async fetchSummaryAgencies() {
      this.renderTable += 1;
      this.currentPage = 1;
      this.search.force = false;
      this.data = [];
    },
    exportSummaryAgencies() {
      FinanceApi.get_summary_agencies(this.currentPage, this.search, true).then((res) => {
        handleDownload(res);
      });
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;
      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length) {
          this.cleanSearchFilter('error');
        }
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
            this.cleanSearchFilter();
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.cleanSearchFilter('error');
      }
    },
    cleanSearchFilter(status) {
      if (status === 'error') { this.search.filters = {}; }

      this.currentPage = 1;
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    formatDate() {
      return DataService.FormatDate();
    },
  },
};
</script>
