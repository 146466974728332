<template lang="pug">
  el-row
    el-col
      el-input(v-if="shownSearch" v-model="searchText" placeholder="Buscar")
    el-col
      el-table(
        v-loading="loading"
        :data="filteredData"
        @selection-change="handleSelectionChange"
        @cell-click="handleCellClick"
        :ref="refTable"
        :show-summary="shownSummary"
        :summary-method="summaryMethod"
        :show-header="shownHeader"
        :height="height"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
        @header-click="handleHeaderClick"
        :stripe="stripe"
        @sort-change="handleSortChange"
      )
        el-table-column(v-if="shownSelection" type="selection")
        el-table-column(
          v-for="header in headers"
          :key="header.key"
          :prop="header.key"
          :fixed="header.fixed"
          :sortable="header.sortable"
          :width="header.width || columnWidth"
          :class-name="header.className"
        )
          template(slot="header" slot-scope="scope")
            el-tooltip(
              v-if="copySummary"
              content="Haz click para copiar el valor del resumen"
              placement="top-start"
            )
              span {{ header.label }}
            span(v-else) {{ header.label }}
          template(slot-scope="scope")
            slot(:name="header.key" :value="cellValue(scope.row, header.key)", :row="scope.row")
              currency-input(
                v-if="header.editable && scope.row.type === 'input'"
                :inputModel="scope.row[header.key]"
              )
              span(v-else) {{ cellValue(scope.row, header) }}
        el-table-column(v-if="shownActions" width="250")
          template(slot="header" slot-scope="scope")
            | Acciones
          template(slot-scope="scope")
            slot(name="additionalActions" :row="scope.row")
            el-button(
              v-if="shownEdit"
              type="warning"
              icon="el-icon-edit"
              circle
              size="mini"
              @click.prevent="handleUpdate(scope.row)"
            )
</template>

<script>
import CurrencyInput from '@/components/forms/inputs/CurrencyInput.vue';

import DateService from '@/services/dateService';

import sleep from '@/utils/sleep';
import {
  percentageFormatter, currencyFormatter,
} from '@/utils/cellFormatter';

export default {
  name: 'DataTable',
  props: {
    headers: { required: true },
    items: { required: true },
    shownSearch: { required: false, default: false },
    shownSelection: { required: false, default: false },
    shownActions: { required: false, default: true },
    shownEdit: { required: false, default: true },
    refTable: { required: false, default: null },
    shownSummary: { required: false, default: false },
    copySummary: { required: false, default: false },
    shownHeader: { required: false, default: true },
    height: { required: false, default: null },
    loading: { required: false, default: false },
    stripe: { required: false, default: true },
    noInformation: { required: false, default: false },
    sorteable: { required: false, default: false },
    summaries: { required: false },
  },
  components: {
    CurrencyInput,
  },
  data() {
    return {
      searchText: '',
    };
  },
  mounted() {
    if (this.shownSelection && this.refTable) {
      this.items.forEach((row) => this.$refs[this.refTable].toggleRowSelection(row));
    }
  },
  methods: {
    cellValue(row, header) {
      const defaultValue = this._.get(row, header.key);
      switch (header.type) {
        case 'percentage':
          return percentageFormatter(defaultValue);
        case 'currency':
          if (this.noInformation && !defaultValue) { return 'Sin info'; }

          return currencyFormatter(Number(defaultValue), header.country);
        case 'object':
          if (defaultValue.type === 'currency') {
            return currencyFormatter(Number(defaultValue.value), header.country);
          }
          if (defaultValue.type === 'percentage') {
            return percentageFormatter(defaultValue.value);
          }
          return defaultValue.value;
        case 'date':
          return DateService.getDate(defaultValue, header.format || 'ddd DD-MM');
        case 'stringarray':
          defaultValue.map((item) => item);
          return defaultValue.join(', ');
        case 'formatted':
          if (header.formatted_value && typeof header.formatted_value === 'function') {
            return header.formatted_value(defaultValue);
          }
          return defaultValue;
        default:
          return defaultValue;
      }
    },
    handleUpdate(row) {
      this.$emit('update', row);
    },
    handleSelectionChange(rows) {
      if (!this.shownSelection) { return; }

      this.$emit('select', rows);
    },
    handleCellClick(row, column, cell, event) {
      this.$emit('cellClickEvent', {
        row, column, cell, event,
      });
    },
    handleHeaderClick(column) {
      if (this.copySummary) {
        const values = this.items.map((item) => item[column.property]);
        if (values.every((value) => !Number.isNaN(Number(value)))) {
          this.$copyText(values.reduce((a, b) => a + b, 0)).then((event) => {
            this.$notify({ type: 'success', title: 'Texto copiado', message: event.text });
          });
        } else {
          this.$notify.info({ title: 'UPS', message: 'Nada que copiar' });
        }
      }
    },
    handleSortChange(sort) {
      this.$emit('sort-change', sort);
    },
    tableRowClassName({ row }) {
      return row.rowClass;
    },
    tableCellClassName({ column, row }) {
      const rowValues = row[column.property];
      if (this.noInformation && !rowValues) { return 'data-table__no-information'; }

      return rowValues && rowValues.cellClass;
    },
    summaryMethod({ columns, data }) {
      const summaries = [];
      columns.forEach((column, index) => {
        const columnName = column.property;
        const columnHeader = this.headers.find((header) => header.key === columnName);
        let columnSummary = '';

        if (this.summaries) {
          columnSummary = this.summaries[columnName];
        } else {
          const values = data.map((item) => {
            if (columnName && columnName.includes('operator_')) {
              return Number(item[columnName].value);
            }

            return Number(item[columnName]);
          }).filter((value) => !Number.isNaN(value));

          if (columnName === 'pending_transfer') {
            columnSummary = values[values.length - 1];
          } else {
            columnSummary = values.reduce((a, b) => a + b, 0);
          }
        }

        if (columnHeader && columnHeader.type === 'currency') {
          summaries[index] = currencyFormatter(columnSummary, this.country);
        } else {
          summaries[index] = '';
        }
      });

      return summaries;
    },
    async refreshLayout() {
      /* When the visibility of Table changes, or items updated after the component is mounted,
        you may need to call this method to get a correct layout */
      if (this.refTable) {
        await sleep(100);
        this.$refs[this.refTable].doLayout();
      }
    },
  },
  computed: {
    filteredData() {
      if (this.searchText) {
        return this.items.filter((item) => {
          const term = this.searchText.toLowerCase();
          return item.name.toLowerCase().includes(term);
        });
      }

      return this.items;
    },
    country() {
      const currencyHeader = this.headers.find((header) => header.type === 'currency');

      return currencyHeader && currencyHeader.country;
    },
    columnWidth() {
      return this.headers.length > 8 ? '120' : null;
    },
  },
  watch: {
    loading() {
      this.refreshLayout();
    },
  },
};
</script>
