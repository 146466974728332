export default {
  methods: {
    handleSelectionChange(rows) {
      this.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.rowClass = null;
      });

      rows.forEach((row) => {
        // eslint-disable-next-line no-param-reassign
        row.rowClass = 'data-table__item--selected-row';
      });
    },
    handleCellClick({ column, row }) {
      const columnName = column.property;

      if (['dates_to_transfer', 'calculated_to_transfer', 'pending_to_transfer', 'national_id', 'bank_name', 'number'].includes(columnName)) {
        this.$copyText(row[columnName].toString()).then((event) => {
          this.$notify({ type: 'success', title: 'Texto copiado', message: event.text });
        });
      }
    },
  },
};
