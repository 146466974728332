<template lang="pug">
div
  el-row
    el-col
      h3.title F. Reclamos - Vista General
  el-row
    el-col
      el-form(:inline="true" label-position="top" size="mini")
        el-form-item(label="País:" size="medium")
          select-input(:options="countries" :attribute="search" modelKey="country")
        el-form-item(label="Fecha de reclamo:")
          date-input(
            :inputModel="search" modelKey="dateRangeComplaint" type="daterange" :clearable="false"
            size="medium"
          )
        el-form-item(label="Fecha de aprobación:")
          date-input(
            :inputModel="search" modelKey="dateRangeApproved" type="daterange" size="medium"
          )
        el-form-item(label="Fecha de reembolso:")
          date-input(
            :inputModel="search" modelKey="dateRangeDevolution" type="daterange"  size="medium"
          )
        el-form-item(label="Estado:" size="medium" style="width: 350px;")
          select-input(:options="stateOptions" :attribute="search" modelKey="aasmState")
        el-form-item(label="Método de reembolso:" size="medium" style="width: 350px;")
          select-input(:options="refundMethodOptions()" :attribute="search" modelKey="refundMethod")
        el-form-item.filters__buttons-wrapper
          el-button(
            type="primary"
            icon="el-icon-search"
            @click.prevent="fetchComplaints()"
          ) FILTRAR
        el-form-item.filters__buttons-wrapper
          el-dropdown(@command="exportComplaints")
            el-button(
              type="success"
              Dropdown
            ) EXPORTAR TABLA
              i(class="el-icon-arrow-down el-icon--right")
            el-dropdown-menu(slot="dropdown")
              el-dropdown-item(command="all") Exportar todo
  el-row
    el-col
      stats-table(
        :data="stats"
        :rowSize="20"
        :colSize="8"
      )
  el-row(:key="renderComplaints")
    el-col
      report-table(
        v-loading="loading"
        :headers="headers"
        :height="600"
        :paginated="true"
        :pageSize="100"
        :rowModel="'infinite'"
        :blockSize="100"
        :blocksInCache="100"
        :datasourceRequests="1"
        @updateTable="updateTable"
      )
</template>

<script>
import ReportTable from '@/components/tables/ReportTable.vue';
import StatsTable from '@/components/tables/StatsTable.vue';
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';
import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

// eslint-disable-next-line import/no-cycle
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';

export default {
  name: 'GeneralView',
  components: {
    ReportTable, SelectInput, DateInput, StatsTable,
  },
  data() {
    return {
      renderComplaints: 0,
      loading: false,
      search: {
        country: 'CL',
        aasmState: '',
        refundMethod: '',
        dateRangeComplaint: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        dateRangeApproved: [],
        dateRangeDevolution: [],
        filters: {},
      },
      stats: [
        { title: 'RECLAMOS', total: 0 },
        { title: 'CLP', total: currencyFormatter(0) },
        { title: 'USD', total: currencyFormatter(0, 'USD') },
      ],
      paramsApi: null,
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setComplaints(rowsParams.startRow, rowsParams.endRow);
            const lastRow = this.data.complaints_total;
            this.stats = this.formatStats(this.data.stats);
            rowsParams.successCallback(this.data.complaints, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };

        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  methods: {
    async fetchComplaints() {
      this.renderComplaints += 1;
    },
    async setComplaints(startRow, endRow) {
      if (this._.isEmpty(this.search.dateRangeDevolution)) {
        this.search.dateRangeDevolution = [];
      }
      if (this._.isEmpty(this.search.dateRangeApproved)) {
        this.search.dateRangeApproved = [];
      }

      const response = await FinanceApi.get_complaints(this.search, false, startRow, endRow);

      return response.body;
    },
    exportComplaints() {
      this.loading = true;
      FinanceApi.get_complaints(this.search, true).then(() => {
        this.$message({ type: 'success', message: 'Reporte enviado' });
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;

      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length) {
          this.search.filters = {};
        }
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.search.filters = {};
      }
    },
    formatStats(stats) {
      let titleValue = '';
      switch (this.search.country) {
        case 'CL': titleValue = 'CLP'; break;
        case 'PE': titleValue = 'PEN'; break;
        case 'MX': titleValue = 'MXN'; break;
        default:
      }
      return [
        { title: 'RECLAMOS', total: stats.count },
        { title: titleValue, total: currencyFormatter(stats.total_price, this.search.country) },
        { title: 'USD', total: currencyFormatter(stats.total_price_usd, 'USD') },
      ];
    },
    currencyOptions() {
      return DataService.SoldCurrencies(this.search.country);
    },
    categoryOptions() {
      return DataService.ComplaintCategories(this.search.country);
    },
    paymentMethodOptions() {
      return DataService.ComplaintPaymentMethod(this.search.country);
    },
    refundMethodOptions() {
      return DataService.ComplaintRefundMethod(this.search.country);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    headers() {
      return [
        {
          headerName: 'ID Reclamo', field: 'number', filterType: 'text', width: 100,
        },
        {
          headerName: 'Fecha Reclamo', field: 'created_at', width: 120,
        },
        { headerName: 'Estado', field: 'aasm_state', width: 450 },
        {
          headerName: 'Categoría', field: 'category', filterType: 'selector', selectorOptions: this.categoryOptions(), width: 300,
        },
        {
          headerName: 'Operador de bus', field: 'ticket_info.bus_operators', filterType: 'text', width: 250,
        },
        {
          headerName: 'Medio de pago', field: 'ticket_info.payment_method', filterType: 'selector', selectorOptions: this.paymentMethodOptions(), width: 150,
        },
        {
          headerName: 'Token', field: 'ticket_info.booking_number', filterType: 'text', width: 100,
        },
        {
          headerName: 'Tickets', field: 'ticket_info.tickets', filterType: 'text', width: 200,
        },
        {
          headerName: 'Moneda', field: 'ticket_info.sold_currency', filterType: 'selector', selectorOptions: this.currencyOptions(), width: 200,
        },
        { headerName: 'Fecha de aprobación', field: 'approved_at', width: 120 },
        {
          headerName: 'Monto Reembolso', field: 'reimbursement.amount', filterType: 'text', width: 150,
        },
        {
          headerName: 'Monto Reembolso Moneda Funcional', field: 'reimbursement.amount_ioc', filterType: 'text', width: 150,
        },
        {
          headerName: '% descuento al Operador', field: 'reimbursement.discount_percentage', filterType: 'text', width: 100,
        },
        {
          headerName: 'Monto descuento al operador Moneda Funcional', field: 'reimbursement.discount', filterType: 'text', width: 100,
        },
        { headerName: 'Método Reembolso', field: 'refund_method', width: 160 },
        { headerName: 'Fecha Reembolso', field: 'reimbursement.devolution_at', width: 100 },
        {
          headerName: 'ID Reembolso', field: 'reimbursement.tef_code', filterType: 'text', width: 200,
        },
      ];
    },
    stateOptions() {
      return DataService.ComplaintStates();
    },
  },
};
</script>
