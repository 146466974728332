import FinanceApi from '@/services/apiService';

/*
  for import conciliable mixins, is necessary import mixin in the component and add into mixins
  attribute.

  for use filtered data is necessary declare the next data in the component:

  return filtered data for country attribute.
    this.search.country (optional)
    this.defaultCountry (optional)
*/

export default {
  data() {
    return {
      conciliables: [],
    };
  },
  beforeMount() {
    this.fetchConciliables();
  },
  methods: {
    fetchConciliables() {
      FinanceApi.getConciliables().then((data) => {
        this.conciliables = data.body;
      });
    },
    filterConciliables(country) {
      return this.conciliables.filter((conciliable) => conciliable.country === country)
        .map((conciliable) => (this.formatConciliable(conciliable)));
    },
    formatConciliable(conciliable) {
      return {
        key: conciliable.name,
        value: conciliable.name,
        id: conciliable.id,
        klass: conciliable.klass,
        idKey: `${conciliable.id}-${conciliable.klass}`,
      };
    },
    getConciliable(idKey) {
      return this.filteredConciliables.find((conciliable) => conciliable.idKey === idKey);
    },
  },
  computed: {
    filteredConciliables() {
      const country = this.search.country || this.defaultCountry;
      let filteredConciliables = this.conciliables;

      if (country) {
        filteredConciliables = filteredConciliables
          .filter((conciliable) => conciliable.country === country);
      }

      return filteredConciliables.map((conciliable) => (this.formatConciliable(conciliable)));
    },
  },
};
