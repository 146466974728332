<template lang="pug">
  div
    el-row
      el-col
        h3.title F. Cancelaciones - Vista general
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Fecha de cancelación:")
            date-input(:inputModel="search" modelKey="dateRange" type="daterange")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Método devolución:")
            select-input(
              :options="refundMethods"
              :attribute="search"
              modelKey="refundMethods"
              :multiple="true"
            )
          el-form-item(label="Estado:")
            select-input(
              :options="cancelationStates"
              :attribute="search"
              modelKey="cancelationStates"
              :multiple="true"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchCancellations()"
            ) FILTRAR
          el-form-item.filters__buttons-wrapper
            el-dropdown(@command="exportTable" style="margin: 0 12px 0 12px")
              el-button(
                type="success"
                Dropdown
              ) EXPORTAR TABLA
                i(class="el-icon-arrow-down el-icon--right")
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(command="all") Exportar todo
          el-form-item.filters__buttons-wrapper
            el-button(
              type="danger"
              icon="el-icon-delete"
              @click.prevent="cleanFilters()"
            ) LIMPIAR FILTROS
    el-row
      el-col
        stats-table(
          :data="stats"
          :rowSize="20"
          :colSize="8"
        )
    el-row(:key="renderTable")
      el-col
        report-table(
          v-loading="loading"
          :headers="headers"
          :height="600"
          :paginated="true"
          :pageSize="20"
          :rowModel="'infinite'"
          :blockSize="20"
          :blocksInCache="20"
          :datasourceRequests="1"
          @updateTable="updateTable"
        )
    el-dialog(
      :title="changeStateDialog.title"
      :visible.sync="changeStateDialog.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-form(:inline="true" :model="changeStateDialog")
        el-row(:gutter="24")
          el-col(:span="12")
            el-form-item(label="Estado actual:") {{ this.changeStateDialog.currentState }}
          el-col(:span="12")
            el-form-item(label="Estado nuevo:")
              select-input(
                :changeMethod="this.handleChange"
                :options="changeStateDialog.states"
                :attribute="changeStateDialog"
                modelKey="state"
              )
        el-row(v-show="changeStateDialog.excludedInput")
          el-form-item(label="Motivo de exclusión")
            el-input(v-model="changeStateDialog.excludeReason" type="textarea" :rows="4"
            :cols="35")
        el-row(v-show="changeStateDialog.refundedInput")
          el-form-item(label="Número de transacción")
            el-input(v-model="changeStateDialog.transactionNumber" type="text")
      el-row.inline-form__actions
        el-button(
          @click="closeChangeStateDialog"
        ) Cancelar
        el-button(
          type="warning"
          @click="confirmChangeState"
        ) Aceptar
    el-dialog(
      :visible.sync="confirmChangeStateDialog.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="40%"
      :show-close="false")
      el-row(:gutter="12")
        el-col
          el-result(icon="warning" :title="this.confirmChangeStateDialog.title"
          style="padding: 0px")
      el-steps(:space="200" simple style="margin: 50px 0; padding: 20px 10px")
        el-step(status="finish" :title="this.confirmChangeStateDialog.firstStepTitle")
        el-step(status="success" :title="this.confirmChangeStateDialog.lastStepTitle")
      el-row.inline-form__actions
        el-button(
          @click="discardChangeState"
        ) Descartar
        el-button(
          type="warning"
          @click="changeState"
        ) Confirmar
    el-dialog(
      :title="historicDetail.title"
      :visible.sync="historicDetail.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="60%")
      historic-details(
        :historicalRecords="this.historicDetail.historicalRecords"
      )
</template>

<script>

import ReportTable from '@/components/tables/ReportTable.vue';
import StatsTable from '@/components/tables/StatsTable.vue';
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';
import HistoricDetails from '@/components/reimburse_users/f_cancellations/historic_details/HistoricDetails.vue';
import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

// eslint-disable-next-line import/no-cycle
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';

export default {
  name: 'GeneralView',
  components: {
    ReportTable, StatsTable, SelectInput, DateInput, HistoricDetails,
  },
  data() {
    return {
      headers: [
        {
          headerName: 'Estado',
          field: 'state',
          cellRenderer: 'agGroupCellRenderer',
          onCellClicked: this.handleChangeState,
        },
        { headerName: 'Método devolución', field: 'method' },
        {
          headerName: 'ID Cancelación',
          field: 'id_k',
          cellRenderer: 'agGroupCellRenderer',
          onCellClicked: this.handleHistoricDetails,
          filterType: 'text',
        },
        { headerName: 'Fecha de cancelación', field: 'canceled_at' },
        {
          headerName: 'Agencias', field: 'agency', filterType: 'text',
        },
        {
          headerName: 'Operador de bus', field: 'bus_operator', filterType: 'text',
        },
        { headerName: 'Token compra', field: 'token', filterType: 'text' },
        { headerName: 'N° boleto', field: 'number', filterType: 'text' },
        { headerName: 'Nombre', field: 'passenger_fullname', filterType: 'text' },
        { headerName: 'N° documento', field: 'passenger_identification', filterType: 'text' },
        { headerName: 'Correo', field: 'email', filterType: 'text' },
        { headerName: 'ID de Reclamo', field: 'complaint_number', filterType: 'text' },
        { headerName: 'Reembolsado en (Fecha)', field: 'refunded_at', filterType: 'range' },
        {
          headerName: 'Moneda de pago del pasajero', field: 'sold_currency', filterType: 'selector', selectorOptions: this.soldCurrencySelectOptions(),
        },
        { headerName: 'Precio boleto USD', field: 'ticket_price_usd', filterType: 'range' },
        { headerName: 'Precio boleto ($M. Funcional)', field: 'final_price_ioc', filterType: 'range' },
        { headerName: 'Reembolso boleto ($M. Funcional)', field: 'devolution_ioc', filterType: 'range' },
        { headerName: 'Descuento OP ($M. Funcional)', field: 'operator_discount', filterType: 'range' },
        { headerName: 'Descuento CT ($M. Funcional)', field: 'offset_discount', filterType: 'range' },
        { headerName: 'Descuento a Recorrido', field: 'recorrido_discount', filterType: 'range' },
      ],
      stats: [
        { title: 'CANCELACIONES', total: 0 },
        { title: 'CLP', total: currencyFormatter(0) },
        { title: 'USD', total: currencyFormatter(0, 'MX') },
      ],
      search: {
        country: 'CL',
        refundMethods: [],
        cancelationStates: [],
        dateRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        filters: {},
      },
      changeStateDialog: {
        visible: false,
        id: '',
        title: '',
        currentState: '',
        excludeReason: '',
        transactionNumber: '',
        state: '',
        states: [{ key: 'refunded', value: 'Devuelto' }, { key: 'excluded', value: 'Excluido' }],
        refundedInput: false,
        excludedInput: false,
      },
      confirmChangeStateDialog: {
        visible: false,
        title: '',
        firstStepTitle: '',
        lastStepTitle: '',
      },
      historicDetail: {
        visible: false,
        title: '',
        historicalRecords: [],
      },
      searchCancellations: false,
      loading: false,
      renderTable: 0,
      setSummary: true,
      scrollId: null,
      paramsApi: null,
      data: [],
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setCancellations();
            const lastRow = this.data.data_size;
            rowsParams.successCallback(this.data.tickets, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };

        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  methods: {
    async setCancellations() {
      this.searchCancellations = false;
      const response = await FinanceApi.get_cancellations(this.scrollId, this.search);

      this.scrollId = response.body.data.scroll_id;
      if (this.setSummary) { this.summary(response); }
      if (response.body.data.tickets.length > 0) { this.searchCancellations = true; }

      return response.body.data;
    },
    async fetchCancellations() {
      this.renderTable += 1;
      this.setSummary = true;
      this.scrollId = null;
    },
    summary(response) {
      this.stats = this.formatStats(response.body.data.stats);
      this.setSummary = false;
    },
    cleanSearchFilter() {
      this.scrollId = null;
      this.setSummary = true;
    },
    handleHistoricDetails(params) {
      this.historicDetail.historicalRecords = [];
      const { data } = params;
      this.historicDetail.visible = true;
      this.historicDetail.title = `Cancelación ${data.id}`;
      const currentDate = new Date();
      data.historical_records.sort((a, b) => {
        const dateItemOne = new Date(a.state_at);
        const dateItemTwo = new Date(b.state_at);

        return Math.abs(dateItemTwo - currentDate) - Math.abs(dateItemOne - currentDate);
      });

      this.historicDetail.historicalRecords = data.historical_records.reverse();
    },
    handleChangeState(params) {
      const { data } = params;

      if (data.state === 'Pendiente') {
        this.changeStateDialog.visible = true;
        this.changeStateDialog.id = data.id;
        this.changeStateDialog.title = `Cambio de estado manual de Cancelación ${data.id}`;
        this.changeStateDialog.currentState = data.state;
      }
    },
    handleChange(state) {
      if (state === 'refunded') {
        this.changeStateDialog.refundedInput = true;
        this.changeStateDialog.excludedInput = false;
        this.changeStateDialog.transactionNumber = '';
      } else {
        this.changeStateDialog.excludedInput = true;
        this.changeStateDialog.refundedInput = false;
        this.changeStateDialog.excludeReason = '';
      }
    },
    closeChangeStateDialog() {
      this.changeStateDialog.visible = false;
      this.changeStateDialog.refundedInput = false;
      this.changeStateDialog.excludedInput = false;
      this.changeStateDialog.state = '';
      this.changeStateDialog.excludeReason = '';
      this.changeStateDialog.transactionNumber = '';
    },
    confirmChangeState() {
      if (this.changeStateDialog.state.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar un estado' });
        return;
      }
      if (this.changeStateDialog.refundedInput
          && this.changeStateDialog.transactionNumber.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe ingresar un numero de transacción' });
        return;
      }
      if (this.changeStateDialog.excludedInput
          && this.changeStateDialog.excludeReason.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe ingresar un motivo de exclusión' });
        return;
      }
      this.changeStateDialog.visible = false;
      this.confirmChangeStateDialog.visible = true;
      this.confirmChangeStateDialog.title = `¿Estás seguro que deseas cambiar el estado de la cancelación ${this.changeStateDialog.id}?`;
      this.confirmChangeStateDialog.firstStepTitle = `Estado actual: ${this.changeStateDialog.currentState}`;
      const newState = this.changeStateDialog.state === 'refunded' ? 'Devuelto' : 'Excluido';
      this.confirmChangeStateDialog.lastStepTitle = `Estado nuevo: ${newState}`;
    },
    discardChangeState() {
      this.confirmChangeStateDialog.visible = false;
      this.changeStateDialog.visible = true;
    },
    changeState() {
      this.loading = true;
      FinanceApi.manual_change_state(this.changeStateDialog).then((data) => {
        setTimeout(() => {
          this.fetchCancellations();
          this.closeChangeStateDialog();
          this.confirmChangeStateDialog.visible = false;
          this.loading = false;

          if (data.body) {
            this.$notify({ message: 'Cambios guardados exitosamente', type: 'success' });
          } else {
            this.$notify({ message: 'No se logró actualizar la cancelación', type: 'error' });
          }
        }, 15000);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });

        this.loading = false;
      });
    },
    formatStats(stats) {
      let titleValue = '';
      switch (this.search.country) {
        case 'CL': titleValue = 'CLP'; break;
        case 'PE': titleValue = 'PEN'; break;
        case 'MX': titleValue = 'MXN'; break;
        default:
      }
      return [
        { title: 'CANCELACIONES', total: stats.count },
        { title: titleValue, total: currencyFormatter(stats.total_price, this.search.country) },
        { title: 'USD', total: currencyFormatter(stats.total_price_usd, 'USD') },
      ];
    },
    cleanFilters() {
      this.search.country = 'CL';
      this.search.refundMethods = [];
      this.search.cancelationStates = [];
      this.search.dateRange = [
        DateService.getDate(),
        DateService.getDate(),
      ];
    },
    exportTable() {
      if (this.searchCancellations) {
        FinanceApi.get_cancellations(this.scrollId, this.search, true).then(() => {
          this.$message({ type: 'success', message: 'Reporte enviado' });
        }).catch((exception) => {
          this.$notify.error({ title: 'Error', message: exception.body.message });
        });
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay resultados para exportar' });
      }
    },
    soldCurrencySelectOptions() {
      return [
        { key: 'Todos', value: '' },
        { key: 'CLP', value: 'CLP' },
        { key: 'USD', value: 'USD' },
        { key: 'PEN', value: 'PEN' },
        { key: 'MXN', value: 'MXN' },
      ];
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;
      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length
        && Object.keys(this.search.filters).length === 0) {
          this.search.filters = {};
          this.cleanSearchFilter();
        }
        this.search.filters = {};
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (filterValue.type === 'from' || filterValue.type === 'to') {
            if (this.search.filters[key] === undefined) {
              this.search.filters[key] = [];
            }
            const date = DateService.convertToDate(key, filterValue.filter);
            if (filterValue.type === 'from' && this.search.filters[key][0] !== date) {
              this.search.filters[key][0] = date;
              this.cleanSearchFilter();
            }
            if (filterValue.type === 'to' && this.search.filters[key][1] !== date) {
              if (filterValue.filter === '-1') {
                this.search.filters[key].pop();
                this.cleanSearchFilter();
              } else {
                this.search.filters[key][1] = date;
                if (this.search.filters[key][0] === undefined) { this.search.filters[key][0] = '0'; }
                this.cleanSearchFilter();
              }
            }
            this.cleanSearchFilter();
          } else if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
            this.cleanSearchFilter();
          } else if (this.search.filters.length !== filterModel.length) {
            this.cleanSearchFilter();
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.search.filters = {};
        this.cleanSearchFilter();
      }
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    refundMethods() {
      return DataService.TicketCancellationRefundMethods('general');
    },
    cancelationStates() {
      return DataService.CancelationStates('general');
    },
  },
};
</script>
