<template lang="pug">
  div
    el-row
      el-col
        h3.title Transacciones Transbank
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Mes:")
            date-input(:inputModel="search" type="month")
          el-form-item(label="Históricos")
            el-checkbox(v-model="search.historic") {{ search.historic ? 'Si' : 'No' }}
          el-form-item(label="Booking:")
            el-input(v-model="search.token")
          el-form-item(label="Estado:")
            select-input(
              :options="transbankStatuses"
              :attribute="search"
              modelKey="statuses"
              :multiple="true"
            )
          el-form-item(label="Missing in:")
            select-input(
              :options="missingOptions"
              :attribute="search"
              modelKey="missingIn"
              :multiple="true"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent.stop="fetchTransactions(pagination.current)"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportTransactions(pagination.current)"
            ) EXPORTAR
            el-button(
              v-if="canSeeButton()"
              type="warning"
              icon="el-icon-upload2"
              @click="uploadDialog.visible = true"
            ) SUBIR
            el-button(
              v-if="canSeeButton()"
              type="primary"
              icon="el-icon-message"
              @click.prevent="handleSendTbk()"
            ) ENVIAR CORREO
    el-row
      el-col
        h4 Resumen
    el-row
      el-col
        data-table(
          :headers="summaryHeaders"
          :items="summaryItems"
          :loading="loading"
          :shownActions="false"
        )
    <el-divider></el-divider><br>
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :shownSelection="true"
          @select="handleSelectionChange"
          @update="handleUpdate"
          :loading="loading"
        )
        el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
    upload-tbk-files(:uploadDialog="uploadDialog")
    el-dialog(title="Editar Transacción" :visible.sync="dialogForm.visible")
      inline-form(:formAttributes="dialogForm.attributes")
      span(slot="footer")
        el-button(size="mini" @click="dialogForm.visible = false") Cancelar
        el-button(
          type="primary"
          size="mini"
          @click.prevent="updateTransaction()"
        ) Actualizar Transacción
    el-dialog(title="Envío de casos a Transbank" :visible.sync="sendTbkDialogForm.visible")
      inline-form(:formAttributes="sendTbkDialogForm.attributes" ref="sendTbkDialogForm")
      el-row
        el-col
          data-table(
            :headers="sendTbkHeaders"
            :items="sendTbkDialogForm.selectedTransactions"
            :shownActions="false"
          )
      span(slot="footer")
        el-button(size="mini" @click="sendTbkDialogForm.visible = false") Cancelar
        el-button(
          type="primary"
          size="mini"
          @click.prevent="confirmSendTbk()"
        ) Enviar Correo
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import InlineForm from '@/components/forms/InlineForm.vue';
import DataTable from '@/components/tables/DataTable.vue';
import UploadTbkFiles from '@/components/control/analytics/tbk/UploadTbkFiles.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';
import setPagination from '@/utils/setPagination';
import handleDownload from '@/utils/handleDownload';

export default {
  name: 'Transactions',
  components: {
    SelectInput, InlineForm, DataTable, UploadTbkFiles, DateInput,
  },
  data() {
    return {
      summaryHeaders: [
        { key: 'title', label: '' },
        {
          key: 'tc_total', label: 'TC Monto', type: 'currency', country: 'CL',
        },
        {
          key: 'td_total', label: 'TD Monto', type: 'currency', country: 'CL',
        },
        { key: 'tc_quantity', label: 'TC Cantidad' },
        { key: 'td_quantity', label: 'TD Cantidad' },
      ],
      headers: [
        { key: 'token', label: 'Booking' },
        { key: 'paid_at', label: 'Fecha de transacción' },
        { key: 'merchant_id', label: 'Código de comercio' },
        { key: 'payment_type', label: 'Tipo' },
        { key: 'card_digits', label: 'Últimos 4 Dígitos' },
        { key: 'authorization_code', label: 'Código Autorización' },
        {
          key: 'price', label: 'Valor', type: 'currency', country: 'CL',
        },
        { key: 'missing_in', label: 'Missing in' },
        { key: 'status', label: 'Estado' },
        { key: 'comment', label: 'Comentario' },
      ],
      search: {
        date: DateService.getDate(),
        historic: '',
        token: '',
        statuses: [],
        missingIn: [],
      },
      summaryItems: [],
      items: [],
      pagination: {},
      uploadDialog: { visible: false },
      dialogForm: { visible: false, attributes: {}, data: {} },
      loading: false,
      sendTbkHeaders: [
        { key: 'token', label: 'Identificador' },
        { key: 'paid_at', label: 'Fecha Venta' },
        { key: 'price', label: 'Monto Original Venta', type: 'currency' },
      ],
      sendTbkDialogForm: { visible: false, attributes: {}, selectedTransactions: [] },
    };
  },
  beforeMount() {
    this.fetchTransactions(1);
  },
  methods: {
    handleCurrentChange(page) {
      this.fetchTransactions(page);
    },
    fetchTransactions(page) {
      this.loading = true;
      FinanceApi.get_tbk_transactions(page, this.search).then((data) => {
        this.items = data.body.transactions;
        this.summaryItems = data.body.summary;
        setPagination(this, data, page);
        this.loading = false;
      }).catch(() => { this.loading = false; });
    },
    exportTransactions(page) {
      FinanceApi.get_tbk_transactions(page, this.search, true).then((res) => {
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({
          title: 'Error',
          message: exception.body.message,
        });
      });
    },
    handleUpdate(data) {
      const attributes = { ...this.formAttributes };
      const rows = flattenForm(attributes.rows);
      // eslint-disable-next-line
      rows.forEach((row) => row.value = this._.get(data, row.key));
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = { ...data };
      this.dialogForm.visible = true;
    },
    updateTransaction() {
      const { id } = this.dialogForm.data;
      const attributes = formatForm(id, flattenForm(this.dialogForm.attributes.rows));
      FinanceApi.update_tbk_transaction({ ...attributes, id }).then(() => {
        this.fetchTransactions();
        this.$notify({
          title: 'Correcto',
          message: 'La transacción ha sido actualizado exitosamente.',
          type: 'success',
        });
        this.dialogForm.visible = false;
      });
    },
    handleSelectionChange(rows) {
      this.sendTbkDialogForm.selectedTransactions = rows;
    },
    handleSendTbk() {
      if (this.sendTbkDialogForm.selectedTransactions.length === 0) {
        this.$notify({
          title: 'Error',
          message: 'Seleccione al menos un caso a enviar.',
          type: 'error',
        });
      } else {
        this.sendTbkDialogForm.attributes = { ...this.sendTbkAttributes };
        this.sendTbkDialogForm.visible = true;
      }
    },
    confirmSendTbk() {
      this.startSubmitLoading();
      const { form } = this.$refs.sendTbkDialogForm.$refs;
      form.validate((valid) => {
        if (valid) {
          this.$confirm('El correo con el detalle se enviará a Transbank', '¡Cuidado!', {
            confirmButtonText: 'SÍ',
            cancelButtonText: 'NO',
            type: 'warning',
          }).then(() => {
            this.sendTbk();
          }).catch(() => {
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    sendTbk() {
      this.startSubmitLoading();
      const attributes = formatForm(null, flattenForm(this.sendTbkDialogForm.attributes.rows));
      const transactions = this.sendTbkDialogForm.selectedTransactions;
      const { form } = this.$refs.sendTbkDialogForm.$refs;
      form.validate((valid) => {
        if (valid) {
          FinanceApi.send_tbk_transactions(attributes, transactions)
            .then((data) => {
              this.transactions = data.body.transactions;
              this.$notify({
                title: 'Correcto',
                message: 'Casos enviados exitosamente',
                type: 'success',
              });
              this.stopSubmitLoading();
              this.sendTbkDialogForm.visible = false;
            }).catch((exception) => {
              this.$notify.error({ title: 'Error', message: exception.body.message });
              this.stopSubmitLoading();
            });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    historic() {
      return this.search.historic;
    },
    transbankStatuses() {
      return DataService.get_transbank_status();
    },
    missingOptions() {
      return DataService.get_missing_in();
    },
    formAttributes() {
      return {
        rows: [
          [
            {
              key: 'token', value: null, inputType: 'string', label: 'Booking', disabled: true,
            },
            {
              key: 'paid_at', value: null, inputType: 'string', label: 'Fecha de pago', disabled: true,
            },
          ],
          [
            {
              key: 'merchant_id', value: null, inputType: 'string', label: 'Código de comercio', disabled: true,
            },
            {
              key: 'payment_type', value: null, inputType: 'string', label: 'Tipo de pago', disabled: true,
            },
          ],
          [
            {
              key: 'card_digits', value: null, inputType: 'string', label: 'Últimos 4 dígitos', disabled: true,
            },
            {
              key: 'price', value: null, inputType: 'string', label: 'Total', disabled: true,
            },
          ],
          [
            {
              key: 'missing_in', value: null, inputType: 'string', label: 'Falta en', disabled: true,
            },
            {
              key: 'status', value: null, inputType: 'select', label: 'Estado', options: this.transbankStatuses, simple: false,
            },
          ],
          [
            {
              key: 'comment', value: null, inputType: 'textarea', label: 'Comentario',
            },
          ],
        ],
      };
    },
    sendTbkAttributes() {
      return {
        rows: [
          [
            {
              key: 'subject', value: `Transacciones de ventas en Recorrido no identificadas en Transbank / Fecha: ${DateService.getDate()}`, inputType: 'string', label: 'Asunto',
            },
          ],
          [
            {
              key: 'body', value: 'Estimados Transbank, en el cruce entre las ventas en nuestro sistema y las cartolas transbank, identificamos operaciones que no han sido abonadas, según se detalla a continuación. Nos gustaria conocer el motivo de la situación:', inputType: 'textarea', label: 'Cuerpo',
            },
          ],
        ],
      };
    },
  },
  watch: {
    historic(newValue) {
      if (newValue) {
        this.search.date = '';
        this.search.disabled = true;
      } else {
        this.search.historic = '';
        this.search.date = DateService.getDate();
        this.search.disabled = false;
      }
    },
  },
};
</script>
