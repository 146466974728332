/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'taxes-icon': {
    width: 16,
    height: 16,
    viewBox: '2442 447.858 216.664 237.597',
    data: '<path pid="0" d="M2533.676 621.647c0 34.515 27.98 62.496 62.496 62.496 34.516 0 62.492-27.981 62.492-62.496 0-34.516-27.976-62.492-62.492-62.492s-62.496 27.98-62.496 62.492zm38.086-24.406a8.377 8.377 0 015.879-2.438 8.41 8.41 0 015.89 2.438 8.366 8.366 0 012.43 5.882 8.362 8.362 0 01-2.43 5.883 8.396 8.396 0 01-5.89 2.438 8.377 8.377 0 01-5.879-2.438 8.37 8.37 0 01-2.438-5.883c0-2.187.887-4.332 2.438-5.882zm48.816 48.812a8.38 8.38 0 01-5.883 2.438 8.379 8.379 0 01-5.879-2.438 8.329 8.329 0 01-2.441-5.883c0-2.187.883-4.336 2.441-5.883a8.382 8.382 0 015.879-2.437c2.192 0 4.336.891 5.883 2.437a8.371 8.371 0 012.438 5.883 8.355 8.355 0 01-2.438 5.883zm-.109-48.703c3.246 3.25 3.246 8.52 0 11.766l-36.828 36.828a8.293 8.293 0 01-5.883 2.437 8.293 8.293 0 01-5.883-2.437 8.318 8.318 0 010-11.766l36.824-36.828c3.254-3.246 8.52-3.246 11.77 0zm0 0"/><path pid="1" d="M2517.039 621.647c0-43.633 35.496-79.133 79.133-79.133 1.445 0 2.883.043 4.308.121v-86.457a8.319 8.319 0 00-8.32-8.32h-141.84a8.322 8.322 0 00-8.32 8.32v220.961c0 4.594 3.727 8.316 8.32 8.316h99.106c-19.625-14.414-32.387-37.644-32.387-63.808zm-27.992-129.598h58.922a8.323 8.323 0 018.32 8.321 8.323 8.323 0 01-8.32 8.32h-58.922a8.322 8.322 0 01-8.32-8.32 8.322 8.322 0 018.32-8.321zm0 44.196h58.922c4.593 0 8.32 3.722 8.32 8.316s-3.727 8.32-8.32 8.32h-58.922c-4.594 0-8.32-3.726-8.32-8.32s3.726-8.316 8.32-8.316zm14.73 60.828h-14.73a8.32 8.32 0 110-16.637h14.73c4.594 0 8.321 3.723 8.321 8.316a8.32 8.32 0 01-8.321 8.321zm0 0"/>'
  }
})
