<template lang="pug">
  div
    el-row
      el-col
        h3.title Ventas Diferidas Mensuales - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores: ")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Fecha:")
            date-input(:inputModel="search" type="month")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchDeferredSales()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportReport()"
            ) Exportar
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :shownActions="false"
          :loading="loading"
          :shownSummary="true"
          :copySummary="true"
          :height="500"
          refTable="montlhyDeferredSalesTable"
        )
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'MontlhyDeferredSales',
  components: {
    SelectInput, DataTable, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      items: [],
      search: {
        country: 'CL',
        operators: [],
        date: DateService.getDate(),
      },
      selectedCountry: null,
      loading: false,
    };
  },
  beforeMount() {
    this.fetchDeferredSales();
  },
  methods: {
    fetchDeferredSales() {
      this.loading = true;
      FinanceApi.monthly_deferred_sales(this.search, false).then((data) => {
        this.items = data.body.data;
        if (this.items.length === 0) {
          this.$notify.info({ title: 'Info', message: 'No se han encontrado datos' });
        }
        this.loading = false;
      }).catch(() => { this.loading = false; });
      this.selectedCountry = this.search.country;
    },
    exportReport() {
      FinanceApi.monthly_deferred_sales(this.search, true).then((res) => {
        handleDownload(res);
      });
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    headers() {
      return [
        { key: 'operator', label: 'Operador' },
        { key: 'previous_date', label: 'Desde' },
        {
          key: 'deferred_total', label: 'Venta diferida', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'pending_total', label: 'Pendiente diferido', type: 'currency', country: this.selectedCountry,
        },
      ];
    },
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
  },
};
</script>
