import { render, staticRenderFns } from "./BankTransfers.vue?vue&type=template&id=c490d49c&lang=pug&"
import script from "./BankTransfers.vue?vue&type=script&lang=js&"
export * from "./BankTransfers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports