<template lang="pug">
  el-dialog(title="Subir archivos transbank" :visible.sync="uploadDialog.visible")
    el-row.upload-tbk-files__alert
      el-col
        el-alert(
          :title="title"
          :description="description"
          type="warning"
          show-icon
          :closable="false"
        )
    el-row
      el-col
        el-form(:inline="true" :model="attributes" :rules="uploadFormRules" ref="form")
          el-row
            el-col
              el-form-item(label="Mes:" prop="date")
                date-input(:inputModel="attributes" type="month")
              el-form-item(label="Archivos:" prop="files")
                el-upload(
                  v-model="attributes.files"
                  action=""
                  accept=".csv"
                  :file-list="attributes.files"
                  :multiple="true"
                  :auto-upload="false"
                  :on-change="handleChangeFile"
                )
                  el-button(slot="trigger" size="small" type="warning") Seleccionar Archivo
          el-row.inline-form__actions
            el-button(@click="uploadDialog.visible = false") Cancelar
            el-button(
              type="warning"
              icon="el-icon-upload2"
              @click.prevent="uploadTransactions()"
              :loading="submitLoading"
            ) Subir
</template>

<script>
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DateService from '@/services/dateService';

export default {
  name: 'UploadTbkFiles',
  components: {
    DateInput,
  },
  props: ['uploadDialog'],
  data() {
    return {
      attributes: {
        date: DateService.addDate(-1, 'month'),
        files: [],
      },
      submitLoading: false,
    };
  },
  methods: {
    handleChangeFile(file) {
      this.attributes.files.push(file.raw);
    },
    uploadTransactions() {
      this.startSubmitLoading();
      const { form } = this.$refs;

      form.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          if (this.attributes.files.length === 0) {
            this.$notify.error({ title: 'Error', message: 'No haz seleccionado ningún archivo' });
            return;
          }
          formData.append('date', this.attributes.date);
          this.attributes.files.forEach((file, index) => formData.append(`files[file_${index}]`, file));
          FinanceApi.upload_tbk_transactions(formData).then((data) => {
            this.$notify({
              title: 'Correcto',
              message: `Analizando datos del mes ${data.body.month}, en unos minutos estará la información disponible.`,
              type: 'success',
            });
            this.uploadDialog.visible = false;
            this.stopSubmitLoading();
          }).catch((exception) => {
            if (exception.status === 406) {
              this.$notify.error({ title: 'Error', message: exception.body.message });
            } else {
              this.$notify.error({ title: 'Error', message: exception.statusText });
            }
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
  },
  computed: {
    title() {
      return 'Subir archivos transbank';
    },
    description() {
      return 'Si el mes seleccionado ya existe en la base de datos, este regenerará toda la información del mes.';
    },
    uploadFormRules() {
      return {
        date: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
        files: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-tbk-files {
  &__alert {
    margin-bottom: 10px;
  }
}
</style>
