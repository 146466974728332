<template lang="pug">
el-dialog(
  title="Historial de cuota de transporte"
  :visible.sync="localVisible"
  :before-close="onClose"
  width="30%"
)
  el-timeline
    el-timeline-item(
      v-for="(activity, index) in data"
      :key="index"
      :type="activity.type"
      :color="index === 0 ? '#0bbd87' : ''"
      :size="index === 0 ? 'large' : 'normal'"
      :timestamp="`${activity.date} - ${activity.responsible}`"
    ) {{ getB2cDescription(activity) }}
</template>

<script>
export default {
  name: 'B2cOffsetHistoricModal',
  props: {
    visible: Boolean,
    data: Array,
  },
  data() {
    return {
      localVisible: this.visible,
    };
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    getB2cDescription(activity) {
      if (activity.b2c_offset_percentage === 0) { return 'Sin cuota de transporte'; }

      return `${activity.b2c_offset_percentage}% Cuota de transporte`;
    },
  },
};
</script>
