async function handleDownload(response) {
  const blob = await response.blob();
  const filename = (response.headers.get('content-disposition') || '').split('filename=')[1];
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = filename.replace(/"/g, '');
  link.click();
}

export default handleDownload;
