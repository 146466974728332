<template lang="pug">
  div
    el-row
      el-col
        h3.title Agendar descuentos (cerrados, con descuento al operador)
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores:")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchDiscounts()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-document-add"
              @click.prevent="handleCreateDiscount()"
            ) NUEVO
            el-button(
              v-if="canSeeButton()"
              type="info"
              icon="el-icon-timer"
              @click.prevent="handleSchedule()"
              :disabled="someDiscountSelected"
            ) AGENDAR
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="discounts"
          :shownSelection="true"
          @select="handleSelectionChange"
          :loading="loading"
          :shownActions="false"
          :height="500"
        )
    el-dialog(title="Nuevo descuento" :visible.sync="dialogForm.visible")
      inline-form(:formAttributes="dialogForm.attributes" ref="dialogForm")
        template(slot="actions" slot-scope="scope")
          el-row(v-if="dateAlertVisible")
            el-col
              h4 ¡Cuidado!
              p La fecha seleccionada es inferior o igual a la fecha actual.
          el-row.inline-form__actions
            el-button(type="primary" @click.prevent="createDiscount()" :loading="submitLoading")
              | Crear
    el-dialog(title="Agendar descuentos" :visible.sync="scheduleDialogForm.visible")
      inline-form(:formAttributes="scheduleDialogForm.attributes" ref="scheduleDialogForm")
      el-row
        el-col
          data-table(
            :headers="scheduleHeaders"
            :items="scheduleDialogForm.selectedDiscounts"
            :shownActions="false"
          )
      span(slot="footer")
        el-button(
          type="primary"
          size="small"
          :loading="submitLoading"
          @click.prevent="confirmSchedule()"
        ) Confirmar
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import InlineForm from '@/components/forms/InlineForm.vue';
import DataTable from '@/components/tables/DataTable.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';

export default {
  name: 'DiscountsClosed',
  components: {
    SelectInput, InlineForm, DataTable,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      headers: [
        { key: 'operator', label: 'Operador' },
        { key: 'category', label: 'Categoría' },
        { key: 'complaint_id', label: 'Reclamo ID' },
        { key: 'ticket_ids', label: 'Número de pasajes' },
        { key: 'amount', label: 'Monto de descuento', type: 'currency' },
      ],
      scheduleHeaders: [
        { key: 'operator', label: 'Operador' },
        { key: 'amount', label: 'Monto de descuento', type: 'currency' },
      ],
      operators: [],
      discounts: [],
      search: { country: 'CL', operators: [] },
      dialogForm: { visible: false, attributes: {} },
      scheduleDialogForm: { visible: false, attributes: {}, selectedDiscounts: [] },
      loading: false,
      submitLoading: false,
      defaultCountry: 'CL',
    };
  },
  beforeMount() {
    this.fetchDiscounts();
  },
  methods: {
    fetchDiscounts() {
      this.loading = true;
      FinanceApi.get_kexa_discounts(this.search, 'closed').then((data) => {
        this.discounts = data.body.discounts;
        this.loading = false;
      }).catch(() => { this.loading = false; });
    },
    handleSelectionChange(rows) {
      this.scheduleDialogForm.selectedDiscounts = rows;
    },
    handleCreateDiscount() {
      const attributes = this._.cloneDeep(this.formAttributes);
      this.dialogForm.attributes = attributes;
      this.dialogForm.visible = true;
    },
    createDiscount() {
      this.startSubmitLoading();
      const { form } = this.$refs.dialogForm.$refs;
      form.validate((valid) => {
        if (valid) {
          const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
          const conciliableData = this.dialogForm.attributes.conciliable;

          FinanceApi.createDiscount(attributes, conciliableData).then(() => {
            this.fetchDiscounts();
            this.dialogForm.visible = false;
            this.stopSubmitLoading();
          }).catch((exception) => {
            this.$notify.error({ title: 'Error', message: exception.body.message });
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    handleSchedule() {
      const attributes = { ...this.scheduleAttributes };
      this.scheduleDialogForm.attributes = attributes;
      this.scheduleDialogForm.visible = true;
    },
    confirmSchedule() {
      this.startSubmitLoading();
      const attributes = formatForm(null, flattenForm(this.scheduleDialogForm.attributes.rows));
      const discountDate = attributes.discount_date;
      const { form } = this.$refs.scheduleDialogForm.$refs;
      form.validate((valid) => {
        if (valid) {
          FinanceApi.confirm_discounts(
            discountDate,
            this.scheduleDialogForm.selectedDiscounts,
            'closed',
          ).then((data) => {
            this.discounts = data.body.discounts;
            this.$notify({
              title: 'Correcto',
              message: 'Descuento agendando exitosamente',
              type: 'success',
            });
            this.stopSubmitLoading();
            this.scheduleDialogForm.visible = false;
          }).catch((exception) => {
            this.$notify.error({ title: 'Error', message: exception.body.message });
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    categories() {
      return DataService.get_discount_categories();
    },
    someDiscountSelected() {
      return this.scheduleDialogForm.selectedDiscounts.length === 0;
    },
    dateAlertVisible() {
      const rows = flattenForm(this.dialogForm.attributes.rows || []);
      const discountDateRow = rows.find((row) => row.key === 'discount_date');
      const discountDate = discountDateRow && discountDateRow.value;

      if (!discountDate) { return false; }

      return DateService.getDate(discountDate, null) < DateService.getDate(null, null);
    },
    formAttributes() {
      const $this = this;
      return {
        conciliable: { id: null, klass: '' },
        rows: [
          [
            {
              key: 'country',
              value: 'CL',
              inputType: 'select',
              label: 'País',
              options: this.countries,
              changeMethod(country) {
                const rows = flattenForm($this.dialogForm.attributes.rows);
                const operatorRow = rows.find((row) => row.key === 'operator');
                const amountRow = rows.find((row) => row.key === 'amount');

                $this._.set(operatorRow, 'value', null);
                $this._.set(operatorRow, 'options', $this.filterConciliables(country));
                $this._.set(amountRow, 'country', country);
              },
            },
            {
              key: 'operator',
              value: null,
              inputType: 'select',
              label: 'Operador',
              options: this.filteredConciliables,
              optionKey: 'idKey',
              changeMethod(idKey) {
                const foundConciliable = $this.getConciliable(idKey);

                const conciliableData = $this.dialogForm.attributes.conciliable;

                this._.set(conciliableData, 'id', foundConciliable.id);
                this._.set(conciliableData, 'klass', foundConciliable.klass);
              },
            },
          ],
          [
            {
              key: 'amount', value: 0, inputType: 'currency', label: 'Monto', country: 'CL',
            },
            {
              key: 'discount_date', value: null, inputType: 'date', label: 'Fecha de descuento',
            },
          ],
          [
            {
              key: 'category', value: 0, inputType: 'select', label: 'Categoría', options: this.categories,
            },
            {
              key: 'description', value: null, inputType: 'textarea', label: 'Descripción',
            },
          ],
        ],
      };
    },
    scheduleAttributes() {
      return {
        rows: [
          [
            {
              key: 'discount_date', value: DateService.getDate(), inputType: 'date', label: 'Fecha de descuento',
            },
          ],
        ],
      };
    },
  },
};
</script>
