<template lang="pug">
  div
    el-row
      el-col
        h3.title Conciliación {{ conciliableName }} - {{ selectedMonth }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Mes:")
            date-input(:inputModel="search" type="month")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores: ")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operator"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="searchConciliation()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportConciliation()"
            ) EXPORTAR
            el-button(
              v-if="canSeeButton()"
              icon="el-icon-refresh"
              @click.prevent="refreshConciliation()"
            ) ACTUALIZAR
            el-button(
              type="warning"
              icon="el-icon-document-add"
              v-if="canSeeButton() && hasManualInputs"
              @click.prevent="saveConciliation()"
            ) GUARDAR
            el-popover(width="500" trigger="click")
              p Politica de transferencia: {{ transferPolicy }}
              p Cuenta bancaria: {{ holder }}
              el-button(slot="reference" icon="el-icon-info" type="info") INFO OPERADOR
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :summaries="summaries"
          :shownActions="false"
          :shownSummary="true"
          :loading="loading"
          :height="500"
          refTable="conciliationTable"
        )
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'Conciliation',
  components: {
    SelectInput, DataTable, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      conciliableName: '',
      headers: [],
      items: [],
      summaries: [],
      originalItems: [],
      search: {
        country: null,
        operator: null,
        date: null,
      },
      loading: false,
      bankAccountInfo: {},
      currency: '',
      timezone: 'America/Santiago',
      hasManualInputs: false,
      transferPolicy: '',
      holder: '',
    };
  },
  beforeMount() {
    this.fetchDetails();
  },
  methods: {
    fetchDetails() {
      this.loading = true;
      FinanceApi
        .get_conciliation({ ...this.search, id: this.conciliationId })
        .then((data) => {
          this.setDataItem(data);
        }).catch((exception) => {
          this.loading = false;
          this.$notify.error({ title: 'Error', message: exception.body.message });
        });
    },
    searchConciliation() {
      this.loading = true;
      FinanceApi
        .get_conciliation({ ...this.search, id: null }).then((data) => {
          this.setDataItem(data);
        }).catch((exception) => {
          this.loading = false;
          this.$notify.error({ title: 'Error', message: exception.body.message });
        });
    },
    setDataItem(data) {
      const { id, timezone } = data.body;

      this.conciliableName = data.body.operator_name;
      this.hasManualInputs = data.body.has_manual_inputs;
      const manualKeys = [
        'operator_total_sold_with_surcharge', 'operator_total_sold', 'operator_refund',
      ];

      this.headers = data.body.columns.map((header) => {
        if (header.key === 'day') { return { ...header, fixed: true, type: 'date' }; }
        if (['transfer_operation_number', 'transfer_date'].includes(header.key)) {
          return { ...header, type: 'string' };
        }

        return {
          ...header,
          type: 'currency',
          country: data.body.country,
          editable: this.hasManualInputs && manualKeys.includes(header.key),
        };
      });

      const items = data.body.data.map((item) => {
        if (this.hasManualInputs) {
          const newItem = { ...item };
          this._.forEach(item, (value, key) => {
            if (manualKeys.includes(key)) {
              newItem[key] = { value };
            }
          });
          return { ...newItem, type: 'input' };
        }

        return { ...item, day: DateService.getDateWithTimezone(timezone, item.day, null) };
      });
      this.items = items;
      this.originalItems = items;

      data.body.summary.forEach((item) => {
        this.summaries[item.key] = item.value;
      });

      this.summaries.pending_transfer = Number(this.summaries.pending_transfer);

      this.search.country = data.body.country;
      this.search.operator = data.body.operator_name;
      this.search.date = DateService.getDateWithTimezone(timezone, data.body.date);

      this.bankAccountInfo = {
        transferPolicy: data.body.transfer_policy,
        holder: data.body.holder,
      };

      this.currency = data.body.currency;
      this.timezone = timezone;
      this.transferPolicy = data.body.transfer_policy;
      this.holder = data.body.holder;

      this.loading = false;
      if (this.conciliationId !== id) {
        this.$router.push({ name: 'conciliation', params: { id } }).catch(() => {});
      }
    },
    exportConciliation() {
      FinanceApi.export_conciliation(this.conciliationId).then((res) => {
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    refreshConciliation() {
      this.loading = true;
      this.$confirm('¿Estás seguro que quieres actualizar la conciliación?', 'Actualización', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        FinanceApi.update_conciliation(this.conciliationId)
          .then((data) => {
            this.setDataItem(data);
            this.$message({ type: 'success', message: 'Conciliación actualizada' });
            this.loading = false;
          }).catch(() => {
            this.$notify.error({ title: 'Error al actualizar la conciliación' });
            this.loading = false;
          });
      }).catch(() => { this.loading = false; });
    },
    saveConciliation() {
      this.loading = true;
      this.$confirm('¿Estás seguro que quieres guardar la conciliación?', 'Guardado Manual', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        FinanceApi.update_conciliation(this.conciliationId, this.items).then((data) => {
          this.setDataItem(data);
          this.$message({ type: 'success', message: 'Conciliación guardada' });
          this.loading = false;
        });
      }).catch(() => { this.loading = false; });
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    conciliationId() {
      return Number(this.$route.params.id);
    },
    selectedMonth() {
      return DateService.getDateWithTimezone(this.timezone, this.search.date, 'MMMM YYYY');
    },
    dataChanged() {
      return this.items !== this.originalItems;
    },
  },
};
</script>
