<template lang="pug">
  el-aside(width="48px;")
    el-menu(
      :collapse="isCollapse"
      mode="vertical"
      v-if="loggedIn"
      :router="true"
      class="side-menu"
    )
      el-menu-item(v-for="sideMenuItem in sideMenuItems")(
        v-if="sideMenuItem.name"
        :index="'index'"
        :route="{ path: sideMenuItem.path }"
        :title="sideMenuItem.meta.title"
        :class="activeSideMenuClass(sideMenuItem.path)"
      )
        template(slot="title") {{sideMenuItem.meta.title}}
        svgicon.sidemenu-svg(:icon="sideMenuItem.meta.icon" color="#5e92f3")
      el-menu-item.side-menu__logout(index="9")
        a(@click.prevent="logout") Salir
</template>

<script>
import store from '@/store';

import logo from '@/assets/images/recorrido-icon.png';

export default {
  name: 'SideMenu',
  data() {
    return {
      logo,
      isCollapse: true,
    };
  },
  computed: {
    loggedIn() {
      return store.state.isLogged;
    },
    sideMenuItems() {
      const userRole = store.state.user.role;
      return this.$router.options.routes.filter((route) => route.name === 'home'
        || (route.meta && route.meta.level !== 0
        && (!route.meta.roles || route.meta.roles.includes(userRole))));
    },
  },
  methods: {
    activeSideMenuClass(path) {
      let itemStyle = '';
      const activePage = this.activeIndex();
      if (activePage.includes(path)) {
        itemStyle = 'side-menu-is-active';
      }
      return itemStyle;
    },
    activeIndex() {
      return this.$route.path;
    },
    logout() {
      this.$router.push({ name: 'logout' });
    },
  },
};
</script>
