<template lang="pug">
  div
    el-row
      el-col
        h3.title
          | Transferencias Pendientes BICE - {{ selectedCountryName }}
          | - {{ batchTransferenceStatusText }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores:")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Por Día de transferencia:")
            date-input(:inputModel="search")
          el-form-item(label="Transferencias entre:")
            select-input(
              :options="filterBetweenAmount"
              :attribute="search"
              modelKey="betweenAmount"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchPendingTransfers()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton() && selectedCountry === 'CL'"
              type="success"
              icon="el-icon-download"
              @click.prevent="handleExportBice"
            ) EXPORTAR BICE
            el-button(
              v-if="canSeeButton() && selectedCountry === 'CL'"
              type="warning"
              icon="el-icon-upload2"
              @click.prevent="handleImportBice"
              :disabled="canImportBice"
            ) IMPORTAR BICE
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :summaries="summaries"
          :height="500"
          :shownSummary="true"
          :loading="loading"
          :shownEdit="false"
          @cellClickEvent="handleCellClick"
          refTable="pendingTransfersTable"
          :stripe="false"
          :shownActions="showActions"
          :shownSelection="true"
          @select="handleSelectionChange"
        )
          template(slot="additionalActions" slot-scope="scope")
            template(v-if="shownDiscountActions(scope.row)")
              a.el-button.el-button--primary.el-button--mini(
                :href="mailerDiscountPreview(scope.row)"
                @click.stop="showMailerDiscountPreview(scope.row)"
                target="_blank"
              )
                i(class="el-icon-view")
              el-button(
                type="info"
                icon="el-icon-message"
                size="mini"
                @click="sendMailerDiscount(scope.row)"
                :disabled="scope.row.disabled || scope.row.can_be_sent"
              )
    el-dialog(title="Subir archivo PDF de BICE" :visible.sync="uploadDialog.visible")
      el-form(:inline="true" :model="uploadDialog" :rules="uploadFormRules" ref="form")
        el-row
          el-col
            el-form-item(label="Selecciona un archivo PDF:" prop="files")
              el-upload(
                v-model="uploadDialog.files"
                action=""
                :file-list="uploadDialog.files"
                :auto-upload="false"
                :on-change="handleChangeFile"
              )
                el-button(slot="trigger" size="small" type="primary") Buscar
        el-row.inline-form__actions
          el-button(@click="uploadDialog.visible = false") Cancelar
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="uploadBiceFile()"
            :loading="submitLoading"
          ) Subir
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';
import pendingTransfers from '@/mixins/pendingTransfers';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'PendingTransfersBice',
  components: {
    SelectInput, DataTable, DateInput,
  },
  mixins: [
    conciliables, pendingTransfers,
  ],
  data() {
    return {
      items: [],
      summaries: [],
      search: {
        country: 'CL',
        operators: [],
        date: DateService.getDate(),
        betweenAmount: 'all',
        bank: 'BICE',
      },
      operators: [],
      batchTransference: null,
      selectedCountry: null,
      loading: false,
      uploadDialog: { visible: false, files: [] },
      submitLoading: false,
    };
  },
  beforeMount() {
    this.fetchPendingTransfers();
  },
  methods: {
    fetchPendingTransfers() {
      this.loading = true;
      if (this.search.betweenAmount !== 'all') {
        this.search.operators = [];
      }

      FinanceApi.get_pending_transfers(this.search).then((data) => {
        this.items = data.body.pending_transfers.map((pendingTransfer) => ({
          ...pendingTransfer,
          disabled: true,
        }));
        this.batchTransference = data.body.batch_transference;
        this.loading = false;
        this.summaries = data.body.summary;
        const emptyBankData = data.body.empty_bank_data;
        if (emptyBankData.length > 0) {
          const emptyBankDataList = emptyBankData.join(', ');
          this.$notify.warning({ title: '¡Cuidado!', message: `Hay operadores o grupos sin datos bancarios: ${emptyBankDataList}` });
        }
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
      this.selectedCountry = this.search.country;
    },
    mailerDiscountPreview(row) {
      return FinanceApi.url_mailer_discount_preview(row.operator, this.search.date);
    },
    showMailerDiscountPreview(row) {
      // eslint-disable-next-line no-param-reassign
      row.disabled = false;
      this.$notify({
        title: 'Correo previsualizado',
        message: 'Correo previsualizado exitosamente, listo para ser enviado',
        type: 'success',
      });
    },
    sendMailerDiscount(row) {
      FinanceApi.send_mailer_discount(row.operator, this.search.date).then(() => {
        this.fetchPendingTransfers();
        this.$notify({
          title: 'Mail Enviado',
          message: 'Mail enviado exitosamente',
          type: 'success',
        });
      });
    },
    handleImportBice() {
      if (this.batchTransferenceCompleted) {
        this.pdfCompletedMessage();
      } else if (!this.batchTransference) {
        this.$alert('No has descargado el csv para el día seleccionado', 'Cuidado', {
          showConfirmButton: false,
        });
      } else {
        this.uploadDialog.visible = true;
      }
    },
    handleChangeFile(file) {
      // eslint-disable-next-line prefer-destructuring
      this.uploadDialog.files = [file.raw];
    },
    uploadBiceFile() {
      this.startSubmitLoading();
      const { form } = this.$refs;

      form.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append('file', this.uploadDialog.files[0]);

          FinanceApi.import_bice(formData).then(() => {
            this.$notify({
              title: 'Carga exitosa.',
              message: 'El archivo se ha subido con éxito.',
              type: 'success',
            });
            this.fetchPendingTransfers();
            this.stopSubmitLoading();
          }).catch((exception) => {
            this.$notify.error({ title: 'Archivo invalido o la carga ya esta completa para el día indicado en el archivo', message: exception.body.message });
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    handleExportBice() {
      if (!this.batchTransference) { this.exportBice(); }

      if (this.batchTransferenceStatus === 'csv_downloaded') {
        this.csvDownloadedMessage();
      }

      if (this.batchTransferenceCompleted) {
        this.pdfCompletedMessage();
      }

      if (this.batchTransferenceStatus === 'pdf_processed' && this.batchTransference.failed_count > 0) {
        this.exportBice();
      }
    },
    pdfCompletedMessage() {
      this.$alert('La carga de la nómina está completa para el día seleccionado', 'No existen transferencias pendientes', {
        showConfirmButton: false,
      });
    },
    csvDownloadedMessage() {
      const message = `Ya fue exportado por ${this.batchTransference.user.name} a las
          ${this.batchTransference.updated_time}. ¿Estás seguro que quieres exportar de nuevo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'SÍ',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.exportBice();
      }).catch(() => {});
    },
    async exportBice() {
      await FinanceApi.export_bice(this.search).then((res) => {
        handleDownload(res);
      });
      this.fetchPendingTransfers();
    },
    shownDiscountActions(row) {
      return row.discounts !== 0;
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    headers() {
      return [
        { key: 'operator', label: 'Operador' },
        { key: 'national_id', label: 'Rut' },
        { key: 'holder', label: 'Titular de la cuenta' },
        { key: 'bank_name', label: 'Banco' },
        { key: 'number', label: 'Numero de Cuenta' },
        { key: 'dates_to_transfer', label: 'Fechas a transferir' },
        {
          key: 'to_transfer', label: 'A Transferir', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'pendings', label: 'Pendientes', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'discounts', label: 'Descuentos', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'calculated_to_transfer', label: 'A Transferir con descuentos y pendientes', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'transfer_amount', label: 'Transferido', type: 'currency', country: this.selectedCountry,
        },
      ];
    },
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    batchTransferenceStatus() {
      return this._.get(this.batchTransference, 'status');
    },
    batchTransferenceStatusText() {
      switch (this.batchTransferenceStatus) {
        case 'pdf_uploaded':
          return 'Subiendo BICE 🔄';
        case 'csv_downloaded':
          return 'Planilla Descargada';
        default:
          return '';
      }
    },
    batchTransferenceCompleted() {
      return this.batchTransferenceStatus === 'pdf_processed' && this.batchTransference.failed_count === 0;
    },
    canImportBice() {
      if (!this.batchTransference) { return true; }

      if (this.batchTransference && this.batchTransferenceCompleted) { return true; }

      return false;
    },
    showActions() {
      return this.items.some((item) => item.discounts !== 0);
    },
    uploadFormRules() {
      return {
        files: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
      };
    },
    filterBetweenAmount() {
      return [
        { key: 'less_than_or_equal_zero', value: 'Menor o igual a cero' },
        { key: 'one_to_seven_millions', value: '$1 a $7 millones' },
        { key: 'greater_than_seven_millions', value: 'Mayor a 7 millones' },
        { key: 'all', value: 'Todos' },
      ];
    },
  },
};
</script>
