<template lang="pug">
  div
    el-row
      el-col
        h3.title Ingresos - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores:")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Entre los meses:")
            date-input(:inputModel="search" modelKey="monthRange" type="monthrange")
          el-form-item(label="Montos:")
            el-radio-group(v-model="search.amount")
              el-radio-button(label="absolute") Absoluto $
              el-radio-button(label="relative") Relativo %
          el-form-item(label="Tipo de ingreso:")
            el-radio-group(v-model="search.incomes")
              el-radio-button(label="all") Todo
              el-radio-button(label="cs") C+S
              el-radio-button(label="c") C
              el-radio-button(label="s") S
              el-radio-button(label="s+") S*
              el-radio-button(label="recorrido_fee") Recorrido FEE
          el-form-item(label="IVA:")
            el-radio-group(v-model="search.tax")
              el-radio-button(label="with") Con
              el-radio-button(label="without") Sin
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click="fetchIncomes()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportIncomes()"
            ) EXPORTAR
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :shownActions="false"
          @cellClickEvent="handleCellClick"
          :height="500"
          :loading="loading"
          refTable="masterIncomeTable"
          :stripe="false"
        )
    el-row
      el-col
        h5 Ingresos Estimados {{ currentMonth }}
        p {{ estimatedIncomes }}
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import handleDownload from '@/utils/handleDownload';
import { currencyFormatter } from '@/utils/cellFormatter';

export default {
  name: 'Incomes',
  components: {
    SelectInput, DataTable, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      columns: [],
      items: [],
      search: {
        country: 'CL',
        operators: [],
        monthRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        amount: 'absolute',
        tax: 'with',
        incomes: 'all',
      },
      estimatedIncomes: 0,
      selectedCountry: null,
      loading: false,
    };
  },
  beforeMount() {
    this.fetchIncomes();
  },
  methods: {
    fetchIncomes() {
      this.loading = true;
      FinanceApi.get_master_incomes(this.search, false).then((data) => {
        let cellType = 'currency';
        if (this.search.amount === 'relative') { cellType = 'percentage'; }

        this.columns = data.body.columns;
        const items = data.body.data.map((item) => {
          this._.forEach(item, (value, key) => {
            if (key !== 'operator') {
              // eslint-disable-next-line no-param-reassign
              item[key] = {
                ...value, type: cellType, click: true, cellClass: 'data-table__click-row',
              };
            }
          });
          return item;
        });
        const summaries = data.body.summaries.map((summary, index) => {
          const newSummary = { rowClass: 'data-table__summary-row' };
          const type = this.getSummaryType(index, cellType);
          summary.forEach((item) => {
            if (item.key === 'operator') {
              newSummary[item.key] = item.value;
            } else {
              newSummary[item.key] = { value: item.value, type };
            }
          });
          return newSummary;
        });
        this.items = [...items, ...summaries];
        this.estimatedIncomes = currencyFormatter(data.body.estimated_incomes, this.search.country);
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
      this.selectedCountry = this.search.country;
    },
    exportIncomes() {
      FinanceApi.get_master_incomes(this.search, true).then((res) => {
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    handleCellClick({ column, row }) {
      const columnName = column.property;
      const { id, click } = row[columnName];

      if (click) {
        store.commit('selectTotal', id);
        this.$router.push({ name: 'conciliation', params: { id } });
      }
    },
    getSummaryType(index, cellType) {
      switch (index) {
        case 1:
          return cellType;
        default:
          return null;
      }
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    headers() {
      return this.columns.map((column) => {
        if (column.key === 'operator') {
          return { ...column, fixed: true, className: 'data-table__fixed-column' };
        }

        return { ...column, type: 'object', country: this.search.country };
      });
    },
    currentMonth() {
      return DateService.getDate(null, 'MMMM YYYY');
    },
  },
};
</script>
