import dayjs from 'dayjs';
import es from 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.locale(es);

const defaultFormat = 'YYYY-MM-DD';
const defaultTimezone = 'America/Santiago';

function formatDate(date, format) {
  if (format) { return date.format(format); }
  return date;
}

export default {
  getDate(value = null, format = defaultFormat) {
    let date = null;

    if (!value) {
      date = dayjs();
    } else { date = dayjs(value); }

    return formatDate(date, format);
  },
  getDateWithTimezone(timezone = defaultTimezone, value = null, format = defaultFormat) {
    let date = null;

    if (!value) {
      date = dayjs().tz(timezone);
    } else { date = dayjs(value).tz(timezone); }

    return formatDate(date, format);
  },
  getDateWithoutTimezone(value, format = 'YYYY-MM-DD') {
    if (!value) { return dayjs.utc().format(format); }
    return dayjs.utc(value).format(format);
  },
  convertToDate(field, value) {
    if (!field.includes('_at')) { return value; }
    const result = this.getDateWithoutTimezone(value, 'YYYY-MM-DD HH:mm:ss');

    return result === 'Invalid Date' ? value : result;
  },
  addDate(amount, unit, value = null, format = defaultFormat) {
    let date = null;

    if (!value) {
      date = dayjs().add(amount, unit);
    } else { date = dayjs(value).add(amount, unit); }

    return formatDate(date, format);
  },
  startOfDate(unit, date = null, format = defaultFormat) {
    return formatDate(date.startOf(unit), format);
  },
  endOfDate(unit, date = null, format = defaultFormat) {
    return formatDate(date.endOf(unit), format);
  },
};
