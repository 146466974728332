const defaults = {
  PE: {
    thousands: ',', precision: 2, decimal: '.', prefix: '$/ ', suffix: '',
  },
  CL: {
    thousands: '.', precision: 0, decimal: ',', prefix: '$ ', suffix: '',
  },
  MX: {
    thousands: ',', precision: 2, decimal: '.', prefix: '$ ', suffix: '',
  },
  USD: {
    thousands: ',', precision: 2, decimal: '.', prefix: '$ ', suffix: '',
  },
};

function toStr(value) {
  return value ? value.toString() : '';
}

function onlyNumbers(input) {
  return toStr(input).replace(/\D+/g, '') || '0';
}

function between(min, n, max) {
  return Math.max(min, Math.min(n, max));
}

function fixed(precision) {
  return between(0, precision, 20);
}

function numbersToCurrency(numbers, precision) {
  // eslint-disable-next-line no-restricted-properties
  const exp = Math.pow(10, precision);
  const float = parseFloat(numbers) / exp;
  return float.toFixed(fixed(precision));
}

function addThousandSeparator(integer, separator) {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
}

function joinIntegerAndDecimal(integer, decimal, separator) {
  return decimal ? integer + separator + decimal : integer;
}

function formatCurrency(input, opt) {
  const newOpt = {
    ...defaults[opt],
    ...opt,
  };

  if (typeof input === 'number') {
    // eslint-disable-next-line no-param-reassign
    input = input.toFixed(fixed(newOpt.precision));
  }
  const negative = input && input.indexOf('-') >= 0 ? '-' : '';

  const numbers = onlyNumbers(input);
  const currency = numbersToCurrency(numbers, newOpt.precision);
  const parts = toStr(currency).split('.');
  let integer = parts[0];
  const decimal = parts[1];
  integer = addThousandSeparator(integer, newOpt.thousands);

  return newOpt.prefix + negative + joinIntegerAndDecimal(integer, decimal, newOpt.decimal)
    + newOpt.suffix;
}

export default formatCurrency;
