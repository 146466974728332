<template lang="pug">
  el-container.login
    el-row(style="width: 400px")
      el-col
        el-form(:model="loginForm")
          h2 Iniciar Sesión
          el-form-item(label="Correo electrónico" prop="email" :rules="emailRules")
            el-input(v-model="loginForm.email")
          el-form-item(label="Contraseña" prop="password" :rules="passwordRules")
            el-input(v-model="loginForm.password" type="password")
          el-form-item
            el-button(type="primary" @click.prevent="login()") Iniciar sesión
</template>

<script>

import jwtDecode from 'jwt-decode';

// eslint-disable-next-line import/no-cycle
import router from '@/router';
import constants from '@/constants/Url.constants';
import store from '@/store';

export default {
  name: 'login',
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    login() {
      this.$http.post(`${constants.BASE}/login`, { user: this.loginForm }).then((response) => {
        const token = response.headers.map.authorization[0] || '';
        const data = jwtDecode(token);
        localStorage.setItem('token', token);
        store.commit('login', { expiration: data.exp, user: response.body.user });
        router.push('/home');
      }, (exception) => {
        this.$notify.error({
          title: 'Error',
          message: exception.body.message,
        });
        this.loginForm.password = '';
      });
    },
  },
  computed: {
    emailRules() {
      return [
        { required: true, message: 'Ingresa el correo', trigger: 'blur' },
        { type: 'email', message: 'Ingresa un correo valido', trigger: ['blur'] },
      ];
    },
    passwordRules() {
      return [
        { required: true, message: 'Ingresa la contraseña', trigger: 'blur' },
      ];
    },
  },
};
</script>
<style>
.login{
  position: relative;
  min-width: 100vh;
  height: 100vh;
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>
