<template lang="pug">
  div
    el-row
      el-col
        h3.title Reclamos
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Fecha de reclamo:")
            date-input(:inputModel="search" modelKey="complaintRange" type="daterange")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores:")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Responsabilidad:")
            select-input(
              :options="responsibilityComplaint"
              :attribute="search"
              modelKey="responsibility"
              :multiple="true"
            )
          el-form-item(label="Datos actualizados:")
            el-radio-group(v-model="search.updatedData")
              el-radio-button(label="all") Todo
              el-radio-button(label="true") Si
              el-radio-button(label="false") No
          el-form-item(label="Fecha devolución al cliente:")
            date-input(:inputModel="search" modelKey="devolutionRange" type="daterange")
          el-form-item(label="N° Reclamo:")
            el-input(v-model="search.number")
          el-form-item(label="N° Booking:")
            el-input(v-model="search.booking")
          el-form-item(label="Devuelto:")
            el-radio-group(v-model="search.devolution")
              el-radio-button(label="all") Todo
              el-radio-button(label="true") Si
              el-radio-button(label="false") No
          el-form-item(label="Agendado:")
            el-radio-group(v-model="search.scheduled")
              el-radio-button(label="all") Todo
              el-radio-button(label="true") Si
              el-radio-button(label="false") No
          el-form-item(label="Cerrado:")
            el-radio-group(v-model="search.closed")
              el-radio-button(label="all") Todo
              el-radio-button(label="true") Si
              el-radio-button(label="false") No
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click="fetchComplaints()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportComplaints()"
            ) EXPORTAR
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :summaries="summaries"
          :shownActions="false"
          :shownSummary="true"
          :height="450"
          :loading="loading"
          refTable="complaintTable"
          :stripe="false"
        )
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'Complaints',
  components: {
    SelectInput, DataTable, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      headers: [
        { key: 'complaint_number', label: 'Número de reclamo' },
        { key: 'booking', label: 'Booking' },
        { key: 'operator', label: 'Operador de bus' },
        { key: 'complaint_created_at', label: 'Fecha de reclamo' },
        { key: 'ticket_price', label: 'Monto del pasaje', type: 'currency' },
        { key: 'complaint_approved_at', label: 'Fecha aprobación reclamo' },
        { key: 'complaint_approved_amount', label: 'Monto aprobado', type: 'currency' },
        { key: 'responsibility', label: 'Responsabilidad' },
        { key: 'updated_data_date', label: 'Fecha datos actualizados' },
        { key: 'reimbursement_date', label: 'Fecha devolución cliente' },
        { key: 'reimbursement_amount', label: 'Monto devuelto al cliente', type: 'currency' },
        { key: 'confirm_date', label: 'Fecha agendado al operador' },
        { key: 'reimbursement_discount', label: 'Monto descontado al operador', type: 'currency' },
        { key: 'transportation_fee', label: 'Cuota de transporte', type: 'currency' },
        { key: 'closed_date', label: 'Fecha de cierre' },
      ],
      columns: [],
      items: [],
      summaries: {},
      search: {
        complaintRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        country: 'CL',
        operators: [],
        responsability: [],
        updatedData: 'all',
        devolutionRange: [],
        number: '',
        booking: '',
        devolution: 'all',
        scheduled: 'all',
        closed: 'all',
      },
      loading: false,
    };
  },
  beforeMount() {
    this.fetchComplaints();
  },
  methods: {
    fetchComplaints() {
      this.loading = true;
      if (this._.isEmpty(this.search.complaintRange)) {
        this.search.complaintRange = [];
      }
      if (this._.isEmpty(this.search.devolutionRange)) {
        this.search.devolutionRange = [];
      }
      FinanceApi.get_complaints_report(this.search).then((data) => {
        this.items = data.body.map((complaint) => ({
          ...complaint,
          disabled: true,
        }));
        this.summaries = data.body.summary;
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    exportComplaints() {
      FinanceApi.get_complaints_report(this.search, true).then((res) => {
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({
          title: 'Error',
          message: exception.body.message,
        });
      });
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    responsibilityComplaint() {
      return DataService.ResponsibilityComplaint();
    },
    countries() {
      return DataService.get_countries();
    },
  },
};
</script>
