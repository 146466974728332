/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'statistics': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M76 240c12.102 0 23.055-4.855 31.148-12.652l44.403 22.199c-.223 1.808-.551 3.586-.551 5.453 0 24.813 20.188 45 45 45s45-20.188 45-45c0-6.926-1.703-13.41-4.512-19.277l60.235-60.235C302.59 178.297 309.074 180 316 180c24.813 0 45-20.188 45-45 0-4.672-.918-9.09-2.246-13.328L411.09 82.43C418.23 87.2 426.79 90 436 90c24.813 0 45-20.188 45-45S460.812 0 436 0s-45 20.188-45 45c0 4.672.918 9.09 2.246 13.328L340.91 97.57C333.77 92.8 325.21 90 316 90c-24.813 0-45 20.188-45 45 0 6.926 1.703 13.41 4.512 19.277l-60.235 60.235C209.41 211.703 202.926 210 196 210c-12.102 0-23.055 4.855-31.148 12.652l-44.403-22.199c.223-1.808.551-3.586.551-5.453 0-24.813-20.187-45-45-45s-45 20.188-45 45 20.188 45 45 45zm0 0"/><path pid="1" d="M497 482h-16V165c0-8.29-6.71-15-15-15h-60c-8.29 0-15 6.71-15 15v317h-30V255c0-8.29-6.71-15-15-15h-60c-8.29 0-15 6.71-15 15v227h-30V375c0-8.29-6.71-15-15-15h-60c-8.29 0-15 6.71-15 15v107h-30V315c0-8.29-6.71-15-15-15H46c-8.29 0-15 6.71-15 15v167H15c-8.29 0-15 6.71-15 15s6.71 15 15 15h482c8.29 0 15-6.71 15-15s-6.71-15-15-15zm0 0"/>'
  }
})
