<template lang="pug">
  div
    el-row
      el-col
        h3.title Historial de descuentos - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores: ")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Fecha de descuento: ")
            date-input(:inputModel="search" modelKey="discountRange" type="daterange")
          el-form-item(label="Fecha de venta: ")
            date-input(:inputModel="search" modelKey="conciliationRange" type="daterange")
          el-form-item(label="Categoría:")
            select-input(
              :options="categories"
              :attribute="search"
              modelKey="selectedCategories"
              :multiple="true"
            )
          el-form-item(label="Descripción:")
            el-input(v-model="search.description")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchDiscounts(pagination.current)"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportHistoryDiscount(pagination.current)"
            ) EXPORTAR
    el-row
      el-col
        data-table(
          :headers="headers"
          :summaries="summaries"
          :shownSummary="true"
          :items="discounts"
          :shownEdit="false"
          :loading="loading"
        )
          template(slot="additionalActions" slot-scope="scope")
            el-button(
              type="primary"
              size="mini"
              @click.prevent="handleSelectConciliation(scope.row.conciliation_id)"
            ) Conciliación
            el-button(
              v-if="validateDiscountAction(scope.row)"
              type="warning"
              circle
              size="mini"
              icon="el-icon-edit"
              @click.prevent="handleEditDiscount(scope.row)"
            )
            el-button(
              v-if="validateDiscountAction(scope.row)"
              type="danger"
              circle
              size="mini"
              icon="el-icon-delete"
              @click.prevent="handleDestroyDiscount(scope.row)"
            )
            el-button(
              v-if="scope.row.discount_type === 'external' && !scope.row.send_email"
              type="danger"
              size="mini"
              @click.prevent="handleVoidDiscount(scope.row)"
            ) Devolver
        el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
    el-dialog(title="Editar Descuento" :visible.sync="dialogForm.visible")
      inline-form(:formAttributes="dialogForm.attributes" ref="dialogForm")
        template(slot="actions" slot-scope="scope")
          el-row.inline-form__actions
            el-button(type="primary" @click.prevent="updateDiscount()" :loading="submitLoading")
              | Editar
    el-dialog(title="Anular Descuento" :visible.sync="voidDialogForm.visible")
      el-row
        el-col
          | Operador: {{ voidDialogForm.data.operator }}
        el-col
          | Fecha de descuento: {{ voidDialogForm.data.discount_date }}
        el-col
          | Fecha de venta: {{ voidDialogForm.data.conciliation_date }}
      el-row
        el-col
          data-table(
            :headers="scheduleHeaders"
            :items="voidDialogForm.data.info_complaints"
            :shownActions="false"
            :shownSelection="true"
            :height="500"
            @select="handleSelectionChange"
            refTable="voidTable"
          )
      el-row.inline-form__actions
        el-button(
          type="primary"
          @click.prevent="voidComplaints()"
          :disabled="someComplaintSelected"
          :loading="submitLoading"
        ) Devolver
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import InlineForm from '@/components/forms/InlineForm.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

import store from '@/store';
// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';
import setPagination from '@/utils/setPagination';
import handleDownload from '@/utils/handleDownload';

export default {
  name: 'History',
  components: {
    SelectInput, InlineForm, DataTable, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      scheduleHeaders: [
        { key: 'complaint_id', label: 'Reclamo' },
        { key: 'amount', label: 'Monto' },
      ],
      discounts: [],
      summaries: {},
      pagination: {},
      selectedCountry: null,
      search: {
        country: 'CL',
        operators: [],
        discountRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        conciliationRange: [],
        categories: [],
        description: '',
      },
      dialogForm: { visible: false, attributes: {}, data: {} },
      voidDialogForm: { visible: false, data: {}, selectedDiscounts: [] },
      loading: false,
      submitLoading: false,
    };
  },
  beforeMount() {
    this.fetchDiscounts(1);
  },
  methods: {
    fetchDiscounts(page) {
      this.loading = true;
      FinanceApi.get_discounts(page, this.search).then((data) => {
        this.discounts = data.body.discounts.map((discount) => ({
          ...discount,
          category: this.categories.find((category) => category.enum === discount.category).value,
          category_id: this.categories.find((category) => category.enum === discount.category).key,
        }));
        this.summaries = { amount: Number(data.body.summary.amount) };
        setPagination(this, data, page);
        this.loading = false;
      }).catch(() => { this.loading = false; });
      this.selectedCountry = this.search.country;
    },
    exportHistoryDiscount(page) {
      FinanceApi.get_discounts(page, this.search, true).then((res) => {
        handleDownload(res);
      }).catch((exception) => {
        if (exception.status === 406) {
          this.$notify.error({ title: 'Error', message: 'Aún no se ha realizado alguna búsqueda que pueda ser exportada' });
        } else {
          this.$notify.error({ title: 'Error', message: exception.statusText });
        }
      });
    },
    handleCurrentChange(page) {
      this.fetchDiscounts(page);
    },
    handleSelectConciliation(id) {
      store.commit('selectTotal', id);
      this.$router.push({ name: 'conciliation', params: { id } });
    },
    handleDestroyDiscount(row) {
      this.$confirm('¿Estás seguro que deseas eliminar este descuento?', 'Eliminar Descuento', {
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
      }).then(() => {
        FinanceApi.destroy_discount(row.id).then(() => {
          this.$message({ type: 'success', message: 'Descuento borrado' });
          this.fetchDiscounts();
        });
      }).catch(() => {});
    },
    handleEditDiscount(data) {
      const attributes = this._.cloneDeep(this.formAttributes);
      attributes.conciliable.id = data.operator_id;
      attributes.conciliable.klass = data.operator_klass;
      const rows = flattenForm(attributes.rows);
      rows.forEach((row) => {
        let rowValue = this._.get(data, row.key);
        if (row.key === 'amount') {
          rowValue = Number(rowValue);
        } else if (row.key === 'operator_id') {
          rowValue = `${data.operator_id}-${data.operator_klass}`;
        }
        // eslint-disable-next-line no-param-reassign
        row.value = rowValue;
      });
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = { ...data };
      this.dialogForm.visible = true;
    },
    updateDiscount() {
      this.startSubmitLoading();
      const { form } = this.$refs.dialogForm.$refs;

      form.validate((valid) => {
        if (valid) {
          const { id } = this.dialogForm.data;
          const attributes = formatForm(id, flattenForm(this.dialogForm.attributes.rows));
          const conciliableData = this.dialogForm.attributes.conciliable;
          FinanceApi.updateDiscount(attributes, conciliableData, this.selectedCountry).then(() => {
            this.fetchDiscounts(this.pagination.current);
            this.dialogForm.visible = false;
            this.stopSubmitLoading();
          }).catch(() => { this.stopSubmitLoading(); });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    handleVoidDiscount(data) {
      this.voidDialogForm.data = { ...data };
      this.voidDialogForm.visible = true;
    },
    handleSelectionChange(rows) {
      this.voidDialogForm.selectedDiscounts = rows;
    },
    voidComplaints() {
      this.startSubmitLoading();
      FinanceApi.void_discount(this.voidDialogForm.data.id, this.voidDialogForm.selectedDiscounts)
        .then(() => {
          this.fetchDiscounts(this.pagination.current);
          this.stopSubmitLoading();
          this.voidDialogForm.visible = false;
        }).catch(() => { this.stopSubmitLoading(); });
    },
    validateDiscountAction(row) {
      const discountType = row.discount_type;
      const dayGap = DateService.addDate(row.gap, 'days', null, null);
      const conciliationDate = DateService.getDate(String(row.conciliation_date));
      return conciliationDate > dayGap && discountType === 'manual';
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    headers() {
      return [
        { key: 'operator', label: 'Operador' },
        { key: 'category', label: 'Categoría' },
        { key: 'description', label: 'Descripción' },
        {
          key: 'discount_date', label: 'Fecha de descuento', type: 'date', format: 'DD-MM-YYYY',
        },
        {
          key: 'conciliation_date', label: 'Fecha de venta', type: 'date', format: 'DD-MM-YYYY',
        },
        { key: 'state', label: 'Estado' },
        {
          key: 'amount', label: 'Monto', type: 'currency', country: this.selectedCountry,
        },
      ];
    },
    countries() {
      return DataService.get_countries();
    },
    categories() {
      return DataService.get_discount_categories();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    lastFriday() {
      return DateService.addDate(-2, 'days', null);
    },
    someComplaintSelected() {
      return this.voidDialogForm.selectedDiscounts.length === 0;
    },
    formAttributes() {
      const $this = this;
      return {
        conciliable: { id: null, klass: '' },
        rows: [
          [
            {
              key: 'discount_date', value: null, inputType: 'date', label: 'Fecha de descuento',
            },
            {
              key: 'operator_id',
              value: null,
              inputType: 'select',
              label: 'Operador',
              options: this.filteredConciliables,
              optionKey: 'idKey',
              changeMethod(idKey) {
                const foundConciliable = $this.getConciliable(idKey);

                const conciliableData = $this.dialogForm.attributes.conciliable;

                this._.set(conciliableData, 'id', foundConciliable.id);
                this._.set(conciliableData, 'klass', foundConciliable.klass);
              },
            },
            {
              key: 'amount', value: null, inputType: 'currency', label: 'Monto',
            },
          ],
          [
            {
              key: 'category_id', value: 0, inputType: 'select', label: 'Categoría', options: this.categories,
            },
            {
              key: 'description', value: null, inputType: 'textarea', label: 'Descripción',
            },
          ],
        ],
      };
    },
  },
};
</script>
