import Vue from 'vue';
import Resource from 'vue-resource';

import constants from '@/constants/Url.constants';
import router from '@/router';
import store from '@/store';
import eventBus from '@/events';

Vue.use(Resource);

Vue.http.interceptors.push((request, next) => {
  eventBus.$emit('request-start');
  request.headers.set('Authorization', localStorage.getItem('token'));
  request.headers.set('Accept', 'application/json');
  next((response) => {
    if (response.status === 401) {
      store.commit('logout');
      if (router.currentRoute.name !== 'login') {
        router.push({ path: 'login' });
      }
    } else {
      eventBus.$emit('request-end');
    }
  });
});

function formatSalesParams(search) {
  return {
    country: search.country,
    date_range: search.dateRange,
    operators: search.operators,
    payment_types: search.paymentTypes,
    payment_forms: search.paymentForms,
    sales_channel_mediums: search.salesChannelMediums,
    is_agency: search.isAgency,
    sales_channel_sources: search.salesChannelSources,
    report_generation_type: search.reportGenerationType,
    with_hours: search.withHours,
    with_coupons: search.withCoupons,
    with_insurance: search.withInsurance,
    with_recorrido_fee: search.withRecorridoFee,
  };
}

export default {
  get_master_sales(search, download) {
    const params = {
      country: search.country,
      operators: search.operators,
      from: search.monthRange[0],
      to: search.monthRange[1],
    };
    if (download) {
      return Vue.http.get(`${constants.API_URL}/master/sales.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/master/sales`, { params });
  },
  get_master_incomes(search, download) {
    const params = {
      country: search.country,
      operators: search.operators,
      from: search.monthRange[0],
      to: search.monthRange[1],
      amount: search.amount,
      tax: search.tax,
      incomes: search.incomes,
    };

    if (download) {
      return Vue.http.get(`${constants.API_URL}/master/incomes.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/master/incomes`, { params });
  },
  get_operators(search) {
    return Vue.http.get(`${constants.API_URL}/bus_operators`, {
      params: {
        country: search.country,
      },
    });
  },
  getConciliables() {
    return Vue.http.get(`${constants.API_URL}/conciliables`);
  },
  update_operator(busOperator, id, bankAccountId) {
    let transferPolicy = busOperator.transfer_policy_type;
    switch (transferPolicy) {
      case 'business_day':
        transferPolicy = { type: 'business_day', value: busOperator.business_day_value };
        break;
      case 'monthly_times':
        transferPolicy = { type: 'monthly_times', value: busOperator.monthly_times_value, business_days: busOperator.business_day_value };
        break;
      case 'fixed':
        transferPolicy = { type: 'fixed', value: busOperator.weekly_day_value };
        break;
      case 'weekly':
        transferPolicy = { type: 'weekly', accountable_day: busOperator.weekly_day_value };
        break;
      default: // Do nothing
    }
    const params = {
      b2c_offset: busOperator.b2c_offset,
      netsuite_vendor_id: busOperator.netsuite_vendor_id,
      netsuite_company_name: busOperator.netsuite_company_name,
      conciliation_emails: busOperator.conciliation_emails,
      discount_emails: busOperator.discount_emails,
      transfer_policy: transferPolicy,
      bank_account_attributes: { ...busOperator.bank_account, id: bankAccountId },
    };
    return Vue.http.put(`${constants.API_URL}/bus_operators/${id}`, { bus_operator: params });
  },
  get_groups(search) {
    return Vue.http.get(`${constants.API_URL}/groups/`, {
      params: {
        country: search.country,
      },
    });
  },
  get_group(id) {
    return Vue.http.get(`${constants.API_URL}/groups/${id}`);
  },
  update_group(group, id, bankAccountId) {
    let transferPolicy = group.transfer_policy_type;
    switch (transferPolicy) {
      case 'business_day':
        transferPolicy = { type: 'business_day', value: group.business_day_value };
        break;
      case 'monthly_times':
        transferPolicy = { type: 'monthly_times', value: group.monthly_times_value, business_days: group.business_day_value };
        break;
      case 'fixed':
        transferPolicy = { type: 'fixed', value: group.weekly_day_value };
        break;
      case 'weekly':
        transferPolicy = { type: 'weekly', accountable_day: group.weekly_day_value };
        break;
      default: // Do nothing
    }
    const params = {
      b2c_offset: group.b2c_offset,
      netsuite_vendor_id: group.netsuite_vendor_id,
      netsuite_company_name: group.netsuite_company_name,
      conciliation_emails: group.conciliation_emails,
      discount_emails: group.discount_emails,
      transfer_policy: transferPolicy,
      bank_account_attributes: { ...group.bank_account, id: bankAccountId },
    };
    return Vue.http.put(`${constants.API_URL}/groups/${id}`, { groups: params });
  },
  get_conciliation({
    id, date, operator, country, force = false,
  }) {
    if (id === null) {
      return Vue.http.get(`${constants.API_URL}/conciliations/${date}/${operator}?country=${country}&force=${force}`);
    }
    return Vue.http.get(`${constants.API_URL}/conciliations/${id}?force=${force}`);
  },
  export_conciliation(id, force = false) {
    return Vue.http.get(`${constants.API_URL}/conciliations/${id}.csv?force=${force}`, { responseType: 'blob' });
  },
  get_conciliations(search, download) {
    const params = {
      country: search.country,
      operators: search.operators,
      date: search.date,
    };
    if (download) {
      return Vue.http.get(`${constants.API_URL}/conciliations.csv`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/conciliations`, { params });
  },
  update_conciliation(id, manualInputs) {
    return Vue.http.put(`${constants.API_URL}/conciliations/${id}`, { manual_inputs: manualInputs });
  },
  get_resended_conciliations(page) {
    return Vue.http.get(`${constants.API_URL}/resended_conciliations?page=${page}`);
  },
  resendConciliations(params) {
    return Vue.http.post(`${constants.API_URL}/resended_conciliations/resend`, { conciliation_to_resend: params.conciliation_to_resend });
  },
  url_mailer_conciliation_preview(operator, external, from, to, recipients) {
    return `${constants.BASE}/rails/mailers/conciliation_mailer/generic_conciliation?`
            + `operator=${operator}&external=${external}&from=${from}&to=${to}&recipients=${recipients}`;
  },
  get_bank_transfers(page, search, download) {
    const params = {
      country: search.country,
      description: search.description,
      sale_period: search.salePeriod,
      holder: search.holder,
      operation_number: search.operationNumber,
      deposit_range: search.depositRange,
      operators: search.operators,
      statuses: search.statuses,

    };
    if (download) {
      return Vue.http.get(`${constants.API_URL}/bank_transfers.csv`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/bank_transfers?page=${page}`, { params });
  },
  createTransfer(attributes, conciliable, country) {
    const bankTransfer = {
      description: attributes.description,
      operation_number: attributes.operation_number,
      amount: attributes.amount,
      deposit_date: attributes.deposit_date,
      range_start: attributes.date_range[0],
      range_end: attributes.date_range[1],
      company_type: conciliable.klass,
      company_id: conciliable.id,
    };
    return Vue.http.post(`${constants.API_URL}/bank_transfers`, { bank_transfer: bankTransfer, country });
  },
  updateTransfer(id, data, country) {
    return Vue.http.put(`${constants.API_URL}/bank_transfers/${id}`, { bank_transfer: data, country });
  },
  get_discounts(page, search, download) {
    const params = {
      country: search.country,
      description: search.description,
      discount_range: search.discountRange,
      conciliation_range: search.conciliationRange,
      operators: search.operators,
      selected_categories: search.selectedCategories,
    };
    if (download) {
      return Vue.http.get(`${constants.API_URL}/discounts.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/discounts?page=${page}`, { params });
  },
  get_kexa_discounts(search, type) {
    return Vue.http.get(`${constants.API_URL}/kexa_complaints`, {
      params: {
        country: search.country,
        operators: search.operators,
        approved_date_range: search.approvedDateRange,
        type,
      },
    });
  },
  createDiscount(attributes, conciliable) {
    const discount = {
      discount_date: attributes.discount_date,
      amount: attributes.amount,
      description: attributes.description,
      category: attributes.category,
      discountable_type: conciliable.klass,
      discountable_id: conciliable.id,
    };
    return Vue.http.post(`${constants.API_URL}/discounts`, { discount, country: attributes.country });
  },
  updateDiscount(attributes, conciliable, country) {
    const discount = {
      discount_date: attributes.discount_date,
      amount: attributes.amount,
      description: attributes.description,
      discountable_type: conciliable.klass,
      discountable_id: conciliable.id,
      category: attributes.category_id,
    };
    return Vue.http.put(`${constants.API_URL}/discounts/${attributes.id}`, { discount, country });
  },
  destroy_discount(id) {
    return Vue.http.delete(`${constants.API_URL}/discounts/${id}`);
  },
  confirm_discounts(discountDate, discounts, type) {
    return Vue.http.post(`${constants.API_URL}/discounts/confirm`, { discount_date: discountDate, complaints: discounts, type });
  },
  void_discount(id, complaints) {
    return Vue.http.post(`${constants.API_URL}/discounts/void/${id}`, { complaints });
  },
  url_mailer_discount_preview(operator, date) {
    return `${constants.BASE}/rails/mailers/discount_mailer/discount_details?operator=${operator}&date=${date}`;
  },
  send_mailer_discount(operator, date) {
    return Vue.http.post(`${constants.API_URL}/discounts/send_mailer`, { operator, date });
  },
  get_pending_transfers(search) {
    return Vue.http.get(`${constants.API_URL}/pending_transfers`, {
      params: {
        country: search.country,
        operators: search.operators,
        date: search.date,
        between_amount: search.betweenAmount,
        bank: search.bank,
      },
    });
  },
  get_sales(search, download) {
    const salesParams = formatSalesParams(search);

    if (download) return Vue.http.get(`${constants.API_URL}/sales.csv`, { params: salesParams, responseType: 'blob' });

    return Vue.http.get(`${constants.API_URL}/sales`, { params: salesParams });
  },
  export_tickets_report(search) {
    const salesParams = formatSalesParams(search);

    return Vue.http.get(`${constants.API_URL}/sales/export_tickets_report`, { params: salesParams });
  },
  automatic_cancellations(search) {
    return Vue.http.get(`${constants.API_URL}/analytics/automatic_cancellations`, {
      params: {
        country: search.country,
        from: search.date_range[0],
        to: search.date_range[1],
      },
    });
  },
  monthly_deferred_sales(search, download) {
    const params = {
      date: search.date,
      operators: search.operators,
      country: search.country,
    };

    if (download) {
      return Vue.http.get(`${constants.API_URL}/analytics/monthly_deferred_sales.csv`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/analytics/monthly_deferred_sales`, { params });
  },
  daily_deferred_sales(search, download) {
    const params = {
      date: search.date,
      operators: search.operators,
      country: search.country,
    };

    if (download) {
      return Vue.http.get(`${constants.API_URL}/analytics/daily_deferred_sales.csv`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/analytics/daily_deferred_sales`, { params });
  },
  get_tbk_transactions(page, search, download) {
    const params = {
      date: search.date,
      historic: search.historic,
      by_token: search.token,
      statuses: search.statuses,
      missing_in: search.missingIn,
    };
    if (download) {
      return Vue.http.get(`${constants.API_URL}/tbk_transactions.csv`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/tbk_transactions?page=${page}`, { params });
  },
  update_tbk_transaction(params) {
    return Vue.http.put(`${constants.API_URL}/tbk_transactions/${params.id}`, {
      tbk_transaction: {
        token: params.token,
        status: params.status,
        comment: params.comment,
      },
    });
  },
  upload_tbk_transactions(formData) {
    const options = { emulateJson: true };
    return Vue.http.post(`${constants.API_URL}/tbk_transactions`, formData, options);
  },
  send_tbk_transactions(attributes, tbkTransactions) {
    return Vue.http.post(`${constants.API_URL}/tbk_transactions/send_mail_tbk`, {
      subject: attributes.subject,
      body: attributes.body,
      transactions: tbkTransactions,
    });
  },
  export_bice(search) {
    const params = {
      date: search.date,
      between_amount: search.betweenAmount,
      bank: search.bank,
    };

    return Vue.http.get(`${constants.API_URL}/pending_transfers/export_bice.csv`, { params, responseType: 'blob' });
  },
  export_scotiabank(search) {
    const params = {
      date: search.date,
      between_amount: search.betweenAmount,
      bank: search.bank,
    };

    return Vue.http.get(`${constants.API_URL}/pending_transfers/export_scotiabank.text`, { params, responseType: 'blob' });
  },
  import_bice(formData) {
    return Vue.http.post(`${constants.API_URL}/pending_transfers/import_bice`, formData);
  },
  import_scotiabank(formData) {
    return Vue.http.post(`${constants.API_URL}/pending_transfers/import_scotiabank`, formData);
  },
  historic_filename_import(params) {
    return Vue.http.get(`${constants.API_URL}/pending_transfers/historic_filename_import`, { params });
  },
  get_devolution_coupons(search, page) {
    return Vue.http.get(`${constants.API_URL}/coupons?page=${page}`, { params: { date_range: search.dateRange, country: search.country } });
  },
  sendDevolutionCouponsMailer(search) {
    return Vue.http.get(`${constants.API_URL}/coupons/send_coupons_mail`, {
      params: {
        from: search.dateRange[0],
        to: search.dateRange[1],
        country: search.country,
      },
    });
  },
  getAgencies() {
    return Vue.http.get(`${constants.API_URL}/agencies`);
  },
  get_holidays(page, search) {
    return Vue.http.get(`${constants.API_URL}/holidays?page=${page}`, {
      params: {
        country: search.country,
        year: search.year,
      },
    });
  },
  createHoliday(attributes) {
    const dateHoliday = new Date(attributes.date);
    const day = dateHoliday.getDay();
    const holiday = {
      date: attributes.date,
      title: attributes.title,
      extra: attributes.extra,
      day,
      country: attributes.country,
    };

    return Vue.http.post(`${constants.API_URL}/holidays/`, { holiday });
  },
  deleteHoliday(id) {
    return Vue.http.delete(`${constants.API_URL}/holidays/${id}`);
  },
  updateHoliday(id, attributes) {
    const dateHoliday = new Date(attributes.date);
    const day = dateHoliday.getDay();
    const holiday = {
      date: attributes.date,
      title: attributes.title,
      extra: attributes.extra,
      day,
      country: attributes.country,
    };
    return Vue.http.put(`${constants.API_URL}/holidays/${id}`, { holiday });
  },
  insurance_report(attributes) {
    return Vue.http.get(`${constants.API_URL}/analytics/insurance_conciliation`, {
      params: {
        country: attributes.country,
        from: attributes.date_range[0],
        to: attributes.date_range[1],
      },
    });
  },
  get_complaints_report(search, download) {
    const params = {
      complaint_from: search.complaintRange[0],
      complaint_to: search.complaintRange[1],
      country: search.country,
      operators: search.operators,
      responsibility: search.responsibility,
      updated_data: search.updatedData,
      devolution_from: search.devolutionRange[0],
      devolution_to: search.devolutionRange[1],
      complaint_number: search.number,
      booking: search.booking,
      devolution: search.devolution,
      discounted: search.scheduled,
      closed: search.closed,
    };
    if (download) {
      return Vue.http.get(`${constants.API_URL}/kexa_complaints/complaints_report.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/kexa_complaints/complaints_report`, { params });
  },
  get_general_settings() {
    return Vue.http.get(`${constants.API_URL}/general_settings`);
  },
  updateTbkMails(id, attributes) {
    return Vue.http.put(`${constants.API_URL}/general_settings/${id}`, {
      field: 'tbk_mails',
      data: attributes,
    });
  },
  get_recorrido_fee(scrollId, search, download) {
    const params = {
      country: search.country,
      from: search.dateRange[0],
      to: search.dateRange[1],
      generated_from: search.generationDate[0],
      generated_to: search.generationDate[1],
      generated: search.generated,
      scroll_id: scrollId,
      column_filters: search.filters,
    };

    if (download) {
      params.exportable = true;

      return Vue.http.get(`${constants.API_URL}/analytics/recorrido_fee.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/analytics/recorrido_fee`, { params });
  },
  recorrido_fee_regenerate_emit_rch(search) {
    const params = { from: search.dateRange[0], to: search.dateRange[1], country: search.country };
    return Vue.http.get(`${constants.API_URL}/analytics/recorrido_fee_emit_rch`, { params });
  },
  recorrido_fee_regenerate_declare_rch(search) {
    const params = search;
    return Vue.http.get(`${constants.API_URL}/analytics/recorrido_fee_declare_rch`, { params });
  },
  regenerate_dte(booking) {
    return Vue.http.post(`${constants.API_URL}/analytics/regenerate_single_dte`, { booking });
  },
  regenerate_group_dte(search) {
    const params = { from: search.dateRange[0], to: search.dateRange[1], country: search.country };

    return Vue.http.post(`${constants.API_URL}/analytics/regenerate_group_dte`, { regenerate: params });
  },
  get_users(page, params) {
    return Vue.http.get(`${constants.API_URL}/users?page=${page}`, { params });
  },
  create_user(params) {
    return Vue.http.post(`${constants.BASE}/signup`, { user: params });
  },
  update_user(id, params) {
    return Vue.http.put(`${constants.API_URL}/users/${id}`, { user: params });
  },
  delete_user(id) {
    return Vue.http.delete(`${constants.API_URL}/users/${id}`);
  },
  change_status(id) {
    return Vue.http.get(`${constants.API_URL}/users/change_status/${id}`);
  },
  save_password(passwords, rpt) {
    return Vue.http.put(`${constants.BASE}/save_password/${rpt}`, { user: passwords });
  },
  reset_password(id) {
    return Vue.http.get(`${constants.BASE}/reset_password/${id}`);
  },
  get_bank_cancellations(scrollId, search, download, exportType) {
    const params = {
      country: search.country,
      from: search.dateRange[0],
      to: search.dateRange[1],
      updated_bank_data: search.updatedBankData,
      refund_methods: search.refundMethods,
      states: search.cancelationStates,
      scroll_id: scrollId,
      column_filters: search.filters,
    };
    if (download && exportType === 'text') {
      params.states = ['pending'];
      params.exportable = true;

      return Vue.http.get(`${constants.API_URL}/f_cancellations/bank.text`, { params, responseType: 'blob' });
    }

    if (download && exportType === 'xlsx') {
      params.exportable = true;

      return Vue.http.get(`${constants.API_URL}/f_cancellations/bank.xlsx`, { params, responseType: 'blob' });
    }

    return Vue.http.get(`${constants.API_URL}/f_cancellations/bank`, { params });
  },
  get_paypal_cancellations(scrollId, search, download) {
    const params = {
      country: search.country,
      from: search.dateRange[0],
      to: search.dateRange[1],
      refund_methods: search.refundMethods,
      states: search.cancelationStates,
      scroll_id: scrollId,
      column_filters: search.filters,
    };
    switch (download) {
      case 'payroll':
        params.exportable = true;
        params.states = ['pending'];
        params.refund_methods = ['via_payroll_paypal'];
        return Vue.http.get(`${constants.API_URL}/f_cancellations/paypal.text`, { params, responseType: 'blob' });
      case 'export':
        params.exportable = true;
        return Vue.http.get(`${constants.API_URL}/f_cancellations/paypal.xlsx`, { params, responseType: 'blob' });
      default:
        return Vue.http.get(`${constants.API_URL}/f_cancellations/paypal`, { params });
    }
  },
  get_cancellations(scrollId, search, download) {
    const params = {
      country: search.country,
      from: search.dateRange[0],
      to: search.dateRange[1],
      refund_methods: search.refundMethods,
      states: search.cancelationStates,
      scroll_id: scrollId,
      column_filters: search.filters,
    };
    if (download) {
      params.exportable = true;

      return Vue.http.get(`${constants.API_URL}/f_cancellations.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/f_cancellations`, { params });
  },
  import_paypal(formData) {
    const options = { emulateJson: true };

    return Vue.http.post(`${constants.API_URL}/f_cancellations/import_paypal`, formData, options);
  },
  import_bank(formData) {
    const options = { emulateJson: true };

    return Vue.http.post(`${constants.API_URL}/f_cancellations/import_bank`, formData, options);
  },
  refund_paypal_cancellations(refund) {
    const params = {
      country: refund.country,
      from: refund.from,
      to: refund.to,
      refund_methods: ['via_paypal'],
      states: ['pending'],
      exportable: true,
    };

    return Vue.http.get(`${constants.API_URL}/f_cancellations/refund_paypal`, { params });
  },
  refund_webpay_cancellations(search, refundMethod) {
    const params = {
      country: search.country,
      from: search.dateRange[0],
      to: search.dateRange[1],
      updated_bank_data: search.updatedBankData,
      refund_methods: [refundMethod],
      states: ['pending'],
      exportable: true,
    };

    return Vue.http.get(`${constants.API_URL}/f_cancellations/refund_webpay`, { params });
  },
  refund_webpay_complaints(search, refundMethod) {
    const params = {
      country: search.country,
      complaint_from: search.dateRangeComplaint[0],
      complaint_to: search.dateRangeComplaint[1],
      devolution_from: search.dateRangeDevolution[0],
      devolution_to: search.dateRangeDevolution[1],
      refund_methods: [refundMethod],
      column_filters: search.filters,
      start_row: null,
      end_row: null,
    };

    return Vue.http.get(`${constants.API_URL}/f_complaints/refund_webpay`, { params });
  },
  historic_imports(validType, importType, fileName = '') {
    const params = {
      valid_type: validType,
      import_type: importType,
      filename: fileName,
    };

    return Vue.http.get(`${constants.API_URL}/f_cancellations/historic_imports`, { params });
  },
  manual_change_state(data) {
    const params = {
      cancellation_id: data.id,
      state: data.state,
    };

    if (data.state === 'refunded') {
      params.transaction_number = data.transactionNumber;
    } else if (data.state === 'excluded') {
      params.exclude_reason = data.excludeReason;
    }

    return Vue.http.put(`${constants.API_URL}/f_cancellations/manual_change_state`, { manual_change: params });
  },
  get_activity_history(page, search) {
    return Vue.http.get(`${constants.API_URL}/activities?page=${page}`, {
      params: {
        from: search.dateRange[0],
        to: search.dateRange[1],
      },
    });
  },
  get_netsuite(page, search, download) {
    const params = {
      from: search.dateRange[0],
      to: search.dateRange[1],
      format_date: search.formatDate,
      country: search.country,
      format_type: search.formatType,
      force: search.force,
      column_filters: search.filters,
    };

    if (download) {
      return Vue.http.get(`${constants.API_URL}/net_suite_reports.csv`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/net_suite_reports?page=${page}`, { params });
  },
  update_netsuite_report(search) {
    const from = search.force ? search.date : search.dateRange[0];
    const to = search.force ? search.date : search.dateRange[1];

    const params = {
      from,
      to,
      country: search.country,
      force: search.force,
    };

    return Vue.http.put(`${constants.API_URL}/net_suite_reports/update_report`, { netsuite: params });
  },
  import_netsuite(formData) {
    return Vue.http.post(`${constants.API_URL}/net_suite_reports/import_file`, formData);
  },
  get_complaints(search, download, startRow, endRow) {
    const refundMethods = search.refundMethod.length === 0 ? '' : [search.refundMethod];
    const params = {
      country: search.country,
      complaint_from: search.dateRangeComplaint[0],
      complaint_to: search.dateRangeComplaint[1],
      devolution_from: search.dateRangeDevolution[0],
      devolution_to: search.dateRangeDevolution[1],
      approved_from: search.dateRangeApproved[0],
      approved_to: search.dateRangeApproved[1],
      refund_methods: refundMethods,
      aasm_state: search.aasmState,
      column_filters: search.filters,
      start_row: startRow,
      end_row: endRow,
    };
    if (download) {
      params.exportable = true;

      return Vue.http.get(`${constants.API_URL}/f_complaints.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/f_complaints`, { params });
  },
  get_paypal_complaints(search, download, startRow, endRow) {
    const refundMethods = search.refundMethod.length === 0 ? ['payroll_paypal', 'devolution_paypal'] : [search.refundMethod];
    const params = {
      country: search.country,
      complaint_from: search.dateRangeComplaint[0],
      complaint_to: search.dateRangeComplaint[1],
      devolution_from: search.dateRangeDevolution[0],
      devolution_to: search.dateRangeDevolution[1],
      approved_from: search.dateRangeApproved[0],
      approved_to: search.dateRangeApproved[1],
      refund_methods: refundMethods,
      aasm_state: search.aasmState,
      column_filters: search.filters,
      start_row: startRow,
      end_row: endRow,
    };

    switch (download) {
      case 'payroll':
        params.aasm_state = 'approved_reimbursement';
        params.refund_methods = ['payroll_paypal'];
        return Vue.http.get(`${constants.API_URL}/f_complaints/paypal.text`, { params, responseType: 'blob' });
      case 'export':
        params.exportable = true;
        return Vue.http.get(`${constants.API_URL}/f_complaints/paypal.xlsx`, { params, responseType: 'blob' });
      default:
        return Vue.http.get(`${constants.API_URL}/f_complaints/paypal`, { params });
    }
  },
  refund_paypal_complaints(search) {
    const params = {
      country: search.country,
      complaint_from: search.dateRangeComplaint[0],
      complaint_to: search.dateRangeComplaint[1],
      devolution_from: search.dateRangeDevolution[0],
      devolution_to: search.dateRangeDevolution[1],
      refund_methods: ['devolution_paypal'],
      column_filters: search.filters,
      start_row: null,
      end_row: null,
    };
    return Vue.http.get(`${constants.API_URL}/f_complaints/paypal_refund`, { params });
  },
  get_bank_complaints(search, download, startRow, endRow) {
    const refundMethods = search.refundMethod.length === 0 ? ['payroll_bank', 'devolution_oneclick', 'devolution_tbk'] : [search.refundMethod];
    const params = {
      country: search.country,
      complaint_from: search.dateRangeComplaint[0],
      complaint_to: search.dateRangeComplaint[1],
      devolution_from: search.dateRangeDevolution[0],
      devolution_to: search.dateRangeDevolution[1],
      approved_from: search.dateRangeApproved[0],
      approved_to: search.dateRangeApproved[1],
      refund_methods: refundMethods,
      aasm_state: search.aasmState,
      column_filters: search.filters,
      start_row: startRow,
      end_row: endRow,
    };
    switch (download) {
      case 'payroll':
        params.aasm_state = 'approved_reimbursement';
        return Vue.http.get(`${constants.API_URL}/f_complaints/bank.text`, { params, responseType: 'blob' });
      case 'export':
        params.exportable = true;
        return Vue.http.get(`${constants.API_URL}/f_complaints/bank.xlsx`, { params, responseType: 'blob' });
      default:
        return Vue.http.get(`${constants.API_URL}/f_complaints/bank`, { params });
    }
  },
  get_payu_complaints(search, download, startRow, endRow) {
    const refundMethods = search.refundMethod.length === 0 ? ['payroll_payu', 'devolution_payu'] : [search.refundMethod];
    const params = {
      country: search.country,
      complaint_from: search.dateRangeComplaint[0],
      complaint_to: search.dateRangeComplaint[1],
      devolution_from: search.dateRangeDevolution[0],
      devolution_to: search.dateRangeDevolution[1],
      approved_from: search.dateRangeApproved[0],
      approved_to: search.dateRangeApproved[1],
      refund_methods: refundMethods,
      aasm_state: search.aasmState,
      column_filters: search.filters,
      start_row: startRow,
      end_row: endRow,
    };
    if (download) {
      params.exportable = true;

      return Vue.http.get(`${constants.API_URL}/f_complaints/payu.xlsx`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/f_complaints/payu`, { params });
  },
  import_paypal_complaints(formData) {
    return Vue.http.post(`${constants.API_URL}/f_complaints/import_paypal`, formData);
  },
  import_scotiabank_complaints(formData) {
    return Vue.http.post(`${constants.API_URL}/f_complaints/import_scotiabank`, formData);
  },
  historic_filename_import_complaints(name, type) {
    const params = { filename: name, type };
    return Vue.http.get(`${constants.API_URL}/f_complaints/historic_filename_import`, { params });
  },
  get_summary_agencies(page, search, download) {
    const params = {
      from: search.dateRange[0],
      to: search.dateRange[1],
      format_date: search.formatDate,
      country: search.country,
      force: search.force,
      column_filters: search.filters,
    };

    if (download) {
      return Vue.http.get(`${constants.API_URL}/financial_audits/summary_agencies.csv`, { params, responseType: 'blob' });
    }
    return Vue.http.get(`${constants.API_URL}/financial_audits/summary_agencies?page=${page}`, { params });
  },
  updateAgency(id, attributes) {
    const params = {
      send_report_email: attributes.send_report_email,
      send_report_frequency: attributes.send_report_frequency,
      send_report_emails: attributes.send_report_emails,
    };
    return Vue.http.put(`${constants.API_URL}/agencies/${id}`, { agency: params });
  },
};
