<template lang="pug">
  el-col(v-if="inputType === 'string'")
    el-form-item(:label="inputLabel" :prop="inputProp")
      el-input(v-model="inputModel.value" :disabled="inputModel.disabled")
  el-col(v-else-if="inputType === 'date' || inputType === 'daterange' || inputType === 'month'")
    el-form-item(:label="inputLabel" :prop="inputProp" v-show="!inputModel.visible")
      date-input(
        :inputModel="inputModel"
        modelKey="value"
        :type="inputType"
        :disabled="inputModel.disabled"
      )
  el-col(v-else-if="inputType === 'number'")
    el-form-item(:label="inputLabel" :prop="inputProp" v-show="!inputModel.visible")
      el-input-number(v-model="inputModel.value" :precision="inputModel.precision"
                      :step="inputModel.step" :min="inputModel.minimum || 0"
                      :max="inputModel.maximum || undefined")
  el-col(v-else-if="inputType === 'select' && inputTooltip === true")
    el-tooltip(:content="toolTipLabel" placement="top-start")
      el-form-item(:label="inputLabel" :prop="inputProp" v-show="!inputModel.visible")
        select-input(
          :simple="inputModel.simple"
          :options="inputModel.options"
          :attribute="inputModel"
          :modelKey="inputModel.modelKey"
          :optionKey="inputModel.optionKey"
          :disabled="inputModel.disabled"
          :changeMethod="inputModel.changeMethod"
          :multiple="inputModel.multiple"
          :allowCreate="inputModel.allowCreate"
        )
  el-col(v-else-if="inputType === 'select' && inputTooltip !== true")
    el-form-item(:label="inputLabel" :prop="inputProp" v-show="!inputModel.visible")
      select-input(
        :simple="inputModel.simple"
        :options="inputModel.options"
        :attribute="inputModel"
        :modelKey="inputModel.modelKey"
        :optionKey="inputModel.optionKey"
        :disabled="inputModel.disabled"
        :changeMethod="inputModel.changeMethod"
        :multiple="inputModel.multiple"
        :allowCreate="inputModel.allowCreate"
      )
  el-col(v-else-if="inputType === 'textarea'")
    el-form-item(:label="inputLabel" :prop="inputProp")
      el-input(v-model="inputModel.value" type="textarea" autosize)
  el-col(v-else-if="inputType === 'currency'")
    el-form-item(:label="inputLabel" :prop="inputProp")
      currency-input(:inputModel="inputModel")
  el-col(v-else-if="inputType === 'checkbox'")
    el-form-item
      el-checkbox(v-model="inputModel.value") {{ inputLabel }}
  el-col(v-else-if="inputType === 'radio'")
    el-form-item(:label="inputLabel" :prop="inputProp")
      el-radio-group(v-model="inputModel.value" size="small" @change="inputModel.changeMethod")
        el-radio-button(v-for="option in inputModel.options" :label="option.id" :key="option")
          | {{ option.label }}
  el-col(v-else-if="inputType === 'description'")
    el-descriptions(
      :title="inputModel.title"
      v-show="!inputModel.visible")
      el-descriptions-item(:label="inputModel.label") {{ inputModel.value }}
  el-col(v-else-if="inputType === 'switch'")
    el-form-item
      el-switch(
        v-model="inputModel.value"
        :active-text="inputModel.label"
        @change="inputModel.change"
      )
      el-divider
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import CurrencyInput from '@/components/forms/inputs/CurrencyInput.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

export default {
  name: 'InputSelector',
  props: ['inputModel', 'inputProp'],
  components: {
    SelectInput, CurrencyInput, DateInput,
  },
  computed: {
    inputType() {
      return this.inputModel.inputType;
    },
    inputLabel() {
      return this.inputModel.label;
    },
    inputTooltip() {
      return this.inputModel.inputTooltip;
    },
    toolTipLabel() {
      return this.inputModel.toolTipLabel;
    },
  },
};
</script>
