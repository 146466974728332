<template lang="pug">
  div
    el-row
      el-col
        h3.title F. Cancelaciones - Paypal
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Fecha de cancelación:")
            date-input(:inputModel="search" modelKey="dateRange" type="daterange")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Método devolución:")
            select-input(
              :options="refundMethods"
              :attribute="search"
              modelKey="refundMethods"
              :multiple="true"
            )
          el-form-item(label="Estado:")
            select-input(
              :options="cancelationStates()"
              :attribute="search"
              modelKey="cancelationStates"
              :multiple="true"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchCancellations()"
            ) FILTRAR
          el-form-item.filters__buttons-wrapper
            el-dropdown(@command="exportTable" style="margin: 0 12px 0 12px")
              el-button(
                type="success"
                Dropdown
              ) EXPORTAR TABLA
                i(class="el-icon-arrow-down el-icon--right")
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(command="all") Exportar todo
          el-form-item.filters__buttons-wrapper
            el-button(
                type="success"
                icon="el-icon-download"
                @click.prevent="confirmExport()"
              ) EXPORTAR NOMINA
            el-button(
              type="warning"
              icon="el-icon-download"
              @click.prevent="handleUploadDialog()"
            ) IMPORTAR NÓMINA
          el-form-item.filters__buttons-wrapper
            el-button(
              type="warning"
              icon="el-icon-s-finance"
              @click.prevent="handleRefundPaypal()"
            ) REEMBOLSAR
    el-row
      el-col
        stats-table(
          :data="stats"
          :rowSize="20"
          :colSize="8"
        )
    el-row(:key="renderTable")
      el-col
        report-table(
          v-loading="loading"
          :headers="headers"
          :height="600"
          :paginated="true"
          :pageSize="20"
          :rowModel="'infinite'"
          :blockSize="20"
          :blocksInCache="20"
          :datasourceRequests="1"
          @updateTable="updateTable"
        )
    el-dialog(
      title="Subir archivo Paypal"
      :visible.sync="uploadDialog.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-form(:inline="true" :model="uploadDialog" :rules="uploadFormRules" ref="form")
        el-row
          el-col
            el-form-item(label="Selecciona un archivo:" prop="file")
              el-upload(
                v-model="uploadDialog.file"
                action=""
                accept=".txt"
                :limit="1"
                :file-list="uploadDialog.file"
                :auto-upload="false"
                :on-change="handleChangeFile"
              )
                el-button(slot="trigger" size="small" type="primary") Buscar
        el-row
          el-col
            el-form-item(label="Fecha de pago:" prop="date")
              date-input(:inputModel="uploadDialog" modelKey="date" type="date")
        el-row.inline-form__actions
          el-button(@click="closeUploadDialog()") Cancelar
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="uploadPaypalFile()"
            :loading="loading"
          ) Subir
    el-dialog(
      :title="this.summaryDialog.title"
      :visible.sync="summaryDialog.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="60%")
      el-row(:gutter="12" style="margin: 25px 0")
        el-col(:span="8")
          el-card
            el-result(icon="info")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(label="Total Cancelaciones") {{ this.summaryDialog.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ this.summaryDialog.total }}
        el-col(:span="8")
          el-card
            el-result(icon="success")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(
                    label="Cancelaciones Pagadas") {{ this.summaryDialog.refund.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ this.summaryDialog.refund.total }}
        el-col(:span="8")
          el-card
            el-result(icon="error")
              template(slot="extra")
                el-descriptions
                  el-descriptions-item(
                    label="Cancelaciones Rechazadas") {{ this.summaryDialog.reject.count }}
                el-descriptions
                  el-descriptions-item(label="Monto Total") {{ this.summaryDialog.reject.total }}
      el-row.inline-form__actions
        el-button(
          type="primary"
          @click="closeDialog()"
        ) Aceptar
    el-dialog(
      title="¡Cuidado!"
      :visible.sync="warningDateImport.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-row(:gutter="12")
        el-col
          el-result(icon="warning" :title="this.warningDateImport.title"
          :subTitle="this.warningDateImport.message")
      el-row.inline-form__actions
        el-button(@click="warningDateImport.visible = false") No
        el-button(
          type="warning"
          @click="warningDateImport.visible = false"
          @click.prevent="handleConfirmUpload()"
        ) Si
    el-dialog(
      title="¡Cuidado!"
      :visible.sync="warningFilenameImport.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-row(:gutter="12")
        el-col
          el-result(icon="warning" :title="this.warningFilenameImport.title"
          :subTitle="this.warningFilenameImport.message")
      el-row.inline-form__actions
        el-button(
          @click="warningFilenameImport.visible = false"
          @click.prevent="uploadDialog.file = []"
        ) No
        el-button(
          type="warning"
          @click="warningFilenameImport.visible = false"
          @click.prevent="confirmUpload = true"
        ) Si
    el-dialog(
      title="Se ha realizado un proceso de reembolso"
      :visible.sync="dialogForm.visible"
      v-loading="loading"
      width="60%")
      el-row
        el-col
          h4(v-show="modal1") Esta seguro de que quiere pagar:
          el-row(v-show="modal2")
            el-col(:span="3") Rango de fechas:
            el-col(:span="3" :offset="0") Desde
            el-col(:span="3") Hasta
          el-row(v-show="modal2")
            el-col(:span="3" :offset="3") <span v-text="toRefund.from"></span>
            el-col(:span="3") <span v-text="toRefund.to"></span>

          el-row(v-show="modal1")
            el-col(:span="8" :offset="8") Desde
            el-col(:span="8") Hasta
          el-row(v-show="modal1")
            el-col(:span="8") Rango de fechas:
            el-col(:span="8") <span v-text="toRefund.from"></span>
            el-col(:span="8") <span v-text="toRefund.to"></span>
          el-row(v-show="modal1")
            el-col(:span="8") Cantidad de cancelaciones:
            el-col(:span="8" v-show="modal1") <span v-text="toRefund.count"></span>
          el-row(v-show="modal1")
            el-col(:span="8") Monto total:
            el-col(:span="8") <span v-text="toRefund.total"></span>
          el-row(:gutter="12" v-show="modal2" style="margin: 25px 0")
            el-col(:span="8")
              el-card
                el-result(icon="info")
                  template(slot="extra")
                    el-descriptions
                      el-descriptions-item(
                        label="Total Cancelaciones") {{ this.toRefund.summary_count }}
                    el-descriptions
                      el-descriptions-item(
                        label="Monto Total") {{ this.toRefund.summary_total }}
            el-col(:span="8")
              el-card
                el-result(icon="success")
                  template(slot="extra")
                    el-descriptions
                      el-descriptions-item(
                        label="Cancelaciones Pagadas") {{ this.toRefund.refund.count }}
                    el-descriptions
                      el-descriptions-item(label="Monto Total") {{ this.toRefund.refund.total }}
            el-col(:span="8")
              el-card
                el-result(icon="error")
                  template(slot="extra")
                    el-descriptions
                      el-descriptions-item(
                        label="Cancelaciones Rechazadas") {{ this.toRefund.reject.count }}
                    el-descriptions
                      el-descriptions-item(label="Monto Total") {{ this.toRefund.reject.total }}
      el-row.inline-form__actions
        el-button(
          type="primary"
          @click.prevent="refundPaypal()"
          v-show="modal1"
        ) Aceptar
        el-button(
          type="danger"
          @click="closeDialog()"
          v-show="modal1"
        ) Cancelar
        el-button(
          type="primary"
          @click="closeDialog()"
          v-show="modal2"
        ) Aceptar
    el-dialog(
      :title="changeStateDialog.title"
      :visible.sync="changeStateDialog.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-form(:inline="true" :model="changeStateDialog")
        el-row(:gutter="24")
          el-col(:span="12")
            el-form-item(label="Estado actual:") {{ this.changeStateDialog.currentState }}
          el-col(:span="12")
            el-form-item(label="Estado nuevo:")
              select-input(
                :changeMethod="this.handleChange"
                :options="changeStateDialog.states"
                :attribute="changeStateDialog"
                modelKey="state"
              )
        el-row(v-show="changeStateDialog.excludedInput")
          el-form-item(label="Motivo de exclusión")
            el-input(v-model="changeStateDialog.excludeReason" type="textarea" :rows="4"
            :cols="35")
        el-row(v-show="changeStateDialog.refundedInput")
          el-form-item(label="Número de transacción")
            el-input(v-model="changeStateDialog.transactionNumber" type="text")
      el-row.inline-form__actions
        el-button(
          @click="closeChangeStateDialog"
        ) Cancelar
        el-button(
          type="warning"
          @click="confirmChangeState"
        ) Aceptar
    el-dialog(
      :visible.sync="confirmChangeStateDialog.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="40%"
      :show-close="false")
      el-row(:gutter="12")
        el-col
          el-result(icon="warning" :title="this.confirmChangeStateDialog.title"
          style="padding: 0px")
      el-steps(:space="200" simple style="margin: 50px 0; padding: 20px 10px")
        el-step(status="finish" :title="this.confirmChangeStateDialog.firstStepTitle")
        el-step(status="success" :title="this.confirmChangeStateDialog.lastStepTitle")
      el-row.inline-form__actions
        el-button(
          @click="discardChangeState"
        ) Descartar
        el-button(
          type="warning"
          @click="changeState"
        ) Confirmar
    el-dialog(
      :title="historicDetail.title"
      :visible.sync="historicDetail.visible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="60%")
      historic-details(
        :historicalRecords="this.historicDetail.historicalRecords"
      )
</template>

<script>
import ReportTable from '@/components/tables/ReportTable.vue';
import StatsTable from '@/components/tables/StatsTable.vue';
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';
import HistoricDetails from '@/components/reimburse_users/f_cancellations/historic_details/HistoricDetails.vue';
import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

// eslint-disable-next-line import/no-cycle
import DataService from '@/services/dataService';

// eslint-disable-next-line import/no-cycle
import handleDownload from '@/utils/handleDownload';
import DateService from '@/services/dateService';

export default {
  name: 'PaypalView',
  components: {
    ReportTable, StatsTable, SelectInput, DateInput, HistoricDetails,
  },
  data() {
    return {
      headers: [
        {
          headerName: 'Estado',
          field: 'state',
          cellRenderer: 'agGroupCellRenderer',
          onCellClicked: this.handleChangeState,
        },
        { headerName: 'Método devolución', field: 'method' },
        {
          headerName: 'ID Cancelación',
          field: 'id_k',
          cellRenderer: 'agGroupCellRenderer',
          onCellClicked: this.handleHistoricDetails,
          filterType: 'text',
        },
        { headerName: 'Fecha de cancelación', field: 'canceled_at' },
        { headerName: 'Operador de bus', field: 'bus_operator', filterType: 'text' },
        { headerName: 'Token compra', field: 'token', filterType: 'text' },
        { headerName: 'Cambio forma de pago (Cupón)', field: 'coupon', filterType: 'text' },
        { headerName: 'N° boleto', field: 'number', filterType: 'text' },
        { headerName: 'Nombre', field: 'fullname', filterType: 'text' },
        { headerName: 'ID (Rut/Pasaporte)', field: 'passenger_identification', filterType: 'text' },
        { headerName: 'Correo / Cuenta PayPal', field: 'paypal_account_k', filterType: 'text' },
        { headerName: 'ID transacción Paypal', field: 'paypal_transaction_id', filterType: 'text' },
        { headerName: 'ID reembolso PayPal', field: 'paypal_refund_id', filterType: 'text' },
        { headerName: 'Reembolsado en (Fecha)', field: 'refunded_at', filterType: 'range' },
        { headerName: 'Precio boleto ($M.USD)', field: 'final_price_isc', filterType: 'range' },
        { headerName: 'Reembolso boleto ($M.USD)', field: 'refund', filterType: 'range' },
        { headerName: 'Precio boleto ($M.Funcional)', field: 'final_price_ioc', filterType: 'range' },
        { headerName: 'Reembolso boleto ($M.Funcional)', field: 'devolution_ioc', filterType: 'range' },
        { headerName: 'Descuento OPS ($M.Funcional)', field: 'operator_discount', filterType: 'range' },
        { headerName: 'Descuento CT ($M.Funcional)', field: 'offset_discount', filterType: 'range' },
        { headerName: 'Descuento a Recorrido ($M.Funcional)', field: 'recorrido_discount', filterType: 'range' },
      ],
      stats: [{ title: 'Cancelaciones PayPal', total: 0 },
        { title: 'USD', total: currencyFormatter(0, 'USD') }],
      search: {
        country: 'CL',
        refundMethods: [],
        cancelationStates: [],
        dateRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        filters: {},
      },
      searchCancellations: false,
      currentFileName: '',
      confirmUpload: false,
      uploadDialog: {
        visible: false,
        file: [],
        date: DateService.getDate(),
      },
      summaryDialog: {
        visible: false,
        title: '',
        total: 0,
        count: 0,
        refund: {
          count: 0,
          total: 0,
        },
        reject: {
          count: 0,
          total: 0,
        },
      },
      warningDateImport: {
        visible: false,
        message: '',
        title: '¿Estás seguro que quieres importar de nuevo?',
      },
      warningFilenameImport: {
        visible: false,
        message: '',
        title: '¿Estás seguro que quieres importar este archivo?',
      },
      dialogForm: { visible: false, attributes: {} },
      toRefund: {
        country: 'CL',
        from: DateService.getDate(),
        to: DateService.getDate(),
        count: 0,
        total: 0,
        summary_count: 0,
        summary_total: 0,
        refund: {
          count: 0,
          total: 0,
        },
        reject: {
          count: 0,
          total: 0,
        },
      },
      modal1: true,
      modal2: false,
      loading: false,
      changeStateDialog: {
        visible: false,
        id: '',
        title: '',
        currentState: '',
        excludeReason: '',
        transactionNumber: '',
        state: '',
        states: [{ key: 'refunded', value: 'Devuelto' }, { key: 'excluded', value: 'Excluido' }],
        refundedInput: false,
        excludedInput: false,
      },
      confirmChangeStateDialog: {
        visible: false,
        title: '',
        firstStepTitle: '',
        lastStepTitle: '',
      },
      historicDetail: {
        visible: false,
        title: '',
        historicalRecords: [],
      },
      renderTable: 0,
      setSummary: true,
      scrollId: null,
      paramsApi: null,
      data: [],
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setCancellations();
            const lastRow = this.data.data_size;
            rowsParams.successCallback(this.data.tickets, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };
        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  methods: {
    async setCancellations() {
      this.searchCancellations = false;
      const response = await FinanceApi.get_paypal_cancellations(this.scrollId, this.search);
      this.scrollId = response.body.data.scroll_id;
      if (this.setSummary) { this.summary(response); }
      if (response.body.data.tickets.length > 0) { this.searchCancellations = true; }

      const [from, to] = this.search.dateRange;
      this.toRefund.country = this.search.country;
      this.toRefund.from = from;
      this.toRefund.to = to;

      if (Object.keys(response.body.data.stats).length > 0) {
        this.toRefund.count = response.body.data.stats.pendings.count;
        this.toRefund.total = currencyFormatter(response.body.data.stats.pendings.total, 'USD');
      }

      return response.body.data;
    },
    async fetchCancellations() {
      this.renderTable += 1;
      this.setSummary = true;
      this.scrollId = null;
    },
    summary(response) {
      this.stats = this.formatStats(response.body.data.stats);
      this.setSummary = false;
    },
    cleanSearchFilter() {
      this.scrollId = null;
      this.setSummary = true;
    },
    formatStats(stats) {
      return [
        { title: 'Cancelaciones PayPal', total: stats.count },
        { title: 'USD', total: currencyFormatter(stats.total_price, 'USD') },
      ];
    },
    confirmExport() {
      this.loading = true;
      if (this.searchCancellations) {
        this.$confirm('Al exportar nómina vas a cambiar el estado de Pendiente a Procesando de todas las cancelaciones filtradas', '¡Cuidado!', {
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cerrar',
          type: 'warning',
        }).then(() => {
          this.exportCancellations();
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay resultados para exportar' });
        this.loading = false;
      }
    },
    exportCancellations() {
      this.loading = true;
      FinanceApi.get_paypal_cancellations(null, this.search, 'payroll').then((res) => {
        this.fetchCancellationsDelay();
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    cancelationStates() {
      return DataService.CancelationStates('paypal');
    },
    handleUploadDialog() {
      this.loading = true;
      FinanceApi.historic_imports('date', 'paypal').then((data) => {
        if (data.body.invalid) {
          this.warningDateImport.message = `Hoy ${data.body.date} ya fue importado por ${data.body.user} a las ${data.body.hour} Hrs. el archivo ${data.body.filename}`;
          this.warningDateImport.visible = true;
        } else {
          this.uploadDialog.visible = true;
        }
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });

        this.loading = false;
      });
    },
    closeUploadDialog() {
      this.uploadDialog.visible = false;
      this.confirmUpload = false;
      this.uploadDialog.file = [];
    },
    handleChangeFile(file) {
      this.currentFileName = file.name;
      this.uploadDialog.file = [];
      this.uploadDialog.file.push(file.raw);
    },
    uploadFormRules() {
      return {
        date: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
        file: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
      };
    },
    uploadPaypalFile() {
      this.loading = true;

      if (this.uploadDialog.file.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar un archivo' });
        return;
      }
      if (this.uploadDialog.date === null) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar una fecha' });
        return;
      }

      FinanceApi.historic_imports('filename', 'paypal', this.uploadDialog.file[0].name).then((validData) => {
        if (validData.body.invalid && !this.confirmUpload) {
          this.warningFilenameImport.message = `El archivo ${validData.body.filename} ya fue importado por ${validData.body.user} el ${validData.body.date} a las ${validData.body.hour} Hrs.`;
          this.warningFilenameImport.visible = true;
          this.loading = false;
        } else {
          const formData = new FormData();

          formData.append('date', this.uploadDialog.date);
          formData.append('file', this.uploadDialog.file[0]);

          FinanceApi.import_paypal(formData).then((data) => {
            this.loading = true;
            this.fetchCancellationsDelay();
            this.summaryDialog.title = this.titleSummaryDialog(data.body.status, data.body.message);
            this.summaryDialog.count = data.body.total;
            this.summaryDialog.total = currencyFormatter(data.body.refunded.total + data.body.rejected.total, 'USD');
            this.summaryDialog.refund.count = data.body.refunded.quantity;
            this.summaryDialog.refund.total = currencyFormatter(data.body.refunded.total, 'USD');
            this.summaryDialog.reject.count = data.body.rejected.quantity;
            this.summaryDialog.reject.total = currencyFormatter(data.body.rejected.total, 'USD');

            this.uploadDialog.visible = false;
            this.summaryDialog.visible = true;
            this.cleanFormFields();

            this.confirmUpload = false;
          }).catch((exception) => {
            this.$notify.error({ title: 'Error', message: exception.body.message });

            this.loading = false;
          });
        }
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });

        this.loading = false;
      });
    },
    handleConfirmUpload() {
      this.uploadDialog.visible = true;
    },
    titleSummaryDialog(status, message = '') {
      if (status === 'error') {
        let formatMessage = `No se logró importar el archivo ${this.currentFileName}`;
        if (message !== '') {
          formatMessage += ` (${message})`;
        }
        return formatMessage;
      }

      return `Se ha importado correctamente el archivo ${this.currentFileName}`;
    },
    cleanFormFields() {
      this.uploadDialog.file = [];
      this.uploadDialog.date = DateService.getDate();
    },
    handleRefundPaypal() {
      if (this.toRefund.count !== 0) {
        this.modal1 = true;
        this.modal2 = false;
        this.dialogForm.visible = true;
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay cancelaciones con método de devolución PayPal y pendientes para reembolsar' });
      }
    },
    refundPaypal() {
      this.loading = true;
      FinanceApi.refund_paypal_cancellations(this.toRefund).then((data) => {
        this.fetchCancellationsDelay();
        this.toRefund.summary_count = data.body.refunded_quantity + data.body.rejected_quantity;
        this.toRefund.summary_total = currencyFormatter(data.body.refunded_total + data.body.rejected_total, 'USD');
        this.toRefund.refund.count = data.body.refunded_quantity;
        this.toRefund.refund.total = currencyFormatter(data.body.refunded_total, 'USD');
        this.toRefund.reject.count = data.body.rejected_quantity;
        this.toRefund.reject.total = currencyFormatter(data.body.rejected_total, 'USD');
        this.modal1 = false;
        this.modal2 = true;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    fetchCancellationsDelay() {
      setTimeout(() => {
        this.fetchCancellations();
        this.confirmChangeStateDialog.visible = false;
        this.loading = false;
      }, 15000);
    },
    closeDialog() {
      this.dialogForm.visible = false;
      this.summaryDialog.visible = false;
    },
    exportTable() {
      if (this.searchCancellations) {
        FinanceApi.get_paypal_cancellations(null, this.search, 'export').then(() => {
          this.$message({ type: 'success', message: 'Reporte enviado' });
        }).catch((exception) => {
          this.$notify.error({ title: 'Error', message: exception.body.message });
        });
      } else {
        this.$notify.error({ title: 'Error', message: 'No hay resultados para exportar' });
      }
    },
    handleChangeState(params) {
      const { data } = params;
      if (data.state === 'Pendiente') {
        this.changeStateDialog.visible = true;
        this.changeStateDialog.id = data.id;
        this.changeStateDialog.title = `Cambio de estado manual de Cancelación ${data.id}`;
        this.changeStateDialog.currentState = data.state;
      }
    },
    handleChange(state) {
      if (state === 'refunded') {
        this.changeStateDialog.refundedInput = true;
        this.changeStateDialog.excludedInput = false;
        this.changeStateDialog.transactionNumber = '';
      } else {
        this.changeStateDialog.excludedInput = true;
        this.changeStateDialog.refundedInput = false;
        this.changeStateDialog.excludeReason = '';
      }
    },
    closeChangeStateDialog() {
      this.changeStateDialog.visible = false;
      this.changeStateDialog.refundedInput = false;
      this.changeStateDialog.excludedInput = false;
      this.changeStateDialog.state = '';
      this.changeStateDialog.excludeReason = '';
      this.changeStateDialog.transactionNumber = '';
    },
    confirmChangeState() {
      if (this.changeStateDialog.state.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar un estado' });
        return;
      }
      if (this.changeStateDialog.refundedInput
          && this.changeStateDialog.transactionNumber.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe ingresar un numero de transacción' });
        return;
      }
      if (this.changeStateDialog.excludedInput
          && this.changeStateDialog.excludeReason.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe ingresar un motivo de exclusión' });
        return;
      }
      this.changeStateDialog.visible = false;
      this.confirmChangeStateDialog.visible = true;
      this.confirmChangeStateDialog.title = `¿Estás seguro que deseas cambiar el estado de la cancelación ${this.changeStateDialog.id}?`;
      this.confirmChangeStateDialog.firstStepTitle = `Estado actual: ${this.changeStateDialog.currentState}`;
      const newState = this.changeStateDialog.state === 'refunded' ? 'Devuelto' : 'Excluido';
      this.confirmChangeStateDialog.lastStepTitle = `Estado nuevo: ${newState}`;
    },
    discardChangeState() {
      this.confirmChangeStateDialog.visible = false;
      this.changeStateDialog.visible = true;
    },
    changeState() {
      this.loading = true;
      FinanceApi.manual_change_state(this.changeStateDialog).then((data) => {
        this.fetchCancellationsDelay();
        this.closeChangeStateDialog();
        if (data.body) {
          this.$notify({ message: 'Cambios guardados exitosamente', type: 'success' });
        } else {
          this.$notify({ message: 'No se logró actualizar la cancelación', type: 'error' });
        }
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    handleHistoricDetails(params) {
      this.historicDetail.historicalRecords = [];
      const { data } = params;
      this.historicDetail.visible = true;
      this.historicDetail.title = `Cancelación ${data.id}`;
      const currentDate = new Date();
      data.historical_records.sort((a, b) => {
        const dateItemOne = new Date(a.state_at);
        const dateItemTwo = new Date(b.state_at);
        return Math.abs(dateItemTwo - currentDate) - Math.abs(dateItemOne - currentDate);
      });
      this.historicDetail.historicalRecords = data.historical_records.reverse();
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;
      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length
        && Object.keys(this.search.filters).length === 0) {
          this.search.filters = {};
          this.cleanSearchFilter();
        }
        this.search.filters = {};
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (filterValue.type === 'from' || filterValue.type === 'to') {
            if (this.search.filters[key] === undefined) {
              this.search.filters[key] = [];
            }
            const date = DateService.convertToDate(key, filterValue.filter);
            if (filterValue.type === 'from' && this.search.filters[key][0] !== date) {
              this.search.filters[key][0] = date;
              this.cleanSearchFilter();
            }
            if (filterValue.type === 'to' && this.search.filters[key][1] !== date) {
              if (filterValue.filter === '-1') {
                this.search.filters[key].pop();
                this.cleanSearchFilter();
              } else {
                this.search.filters[key][1] = date;
                if (this.search.filters[key][0] === undefined) { this.search.filters[key][0] = '0'; }
                this.cleanSearchFilter();
              }
            }
            this.cleanSearchFilter();
          } else if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
            this.cleanSearchFilter();
          } else if (this.search.filters.length !== filterModel.length) {
            this.cleanSearchFilter();
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.search.filters = {};
        this.cleanSearchFilter();
      }
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    refundMethods() {
      return DataService.TicketCancellationRefundMethods('paypal');
    },
  },
};
</script>
