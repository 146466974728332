<template lang="pug">
  div
    el-row
      el-col
        h3.title Códigos de reembolso
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Periodo de generación:")
            date-input(:inputModel="search" modelKey="dateRange" type="daterange")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchDevolutionCoupons()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success",
              icon="el-icon-message",
              @click.prevent="sendCouponsMailer()"
            ) ENVIAR CORREO
    el-row
      el-col
        h4 Resumen
    el-row
      el-col
        data-table(
          :headers="summaryHeaders"
          :items="summary"
          :loading="loading"
          :shownActions="false"
        )
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="coupons"
          :shownActions="false"
          :loading="loading"
        )
        el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

import { currencyFormatter } from '@/utils/cellFormatter';

import setPagination from '@/utils/setPagination';

export default {
  name: 'DevolutionCoupons',
  components: {
    SelectInput, DataTable, DateInput,
  },
  data() {
    return {
      coupons: [],
      summary: [],
      pagination: {},
      search: {
        country: 'CL',
        dateRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
      },
      loading: false,
    };
  },
  beforeMount() {
    this.fetchDevolutionCoupons(1);
  },
  methods: {
    fetchDevolutionCoupons(page) {
      this.loading = true;
      FinanceApi.get_devolution_coupons(this.search, page).then((data) => {
        this.coupons = data.body.coupons;
        this.summary = data.body.summary;
        setPagination(this, data, page);
        this.loading = false;
      }).catch((exception) => {
        this.coupons = [];
        this.summary = {};

        this.$notify.error({ title: 'Error', message: exception.body.message });

        this.loading = false;
      });
    },
    sendCouponsMailer() {
      FinanceApi.sendDevolutionCouponsMailer(this.search).then(() => {
        this.$message({ type: 'success', message: 'Reporte enviado' });
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    format(value) {
      return currencyFormatter(value);
    },
    handleCurrentChange(page) {
      this.fetchDevolutionCoupons(page);
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    headers() {
      return [
        { key: 'token', label: 'Código de reembolso' },
        { key: 'created_at', label: 'Fecha generación' },
        { key: 'amount', label: 'Valor código de reembolso', type: 'currency' },
        { key: 'booking_token', label: 'Compra asociada generación código' },
        { key: 'ticket_number', label: 'Pasaje asociado generación código' },
        { key: 'aasm_state', label: 'Estado código' },
        { key: 'expires_at', label: 'Fecha vencimiento código' },
        { key: 'redeemed_at', label: 'Fecha canje código' },
        { key: 'redemption_booking', label: 'Compra asociada al canje' },
        { key: 'redemption_booking_amount', label: 'Monto total compra asociada canje', type: 'currency' },
        { key: 'payback_coupon_token', label: 'Código nuevo asociado comprar con canje' },
        { key: 'payback_coupon_amount', label: 'Valor nuevo código asociado comprar con canje', type: 'currency' },
      ];
    },
    summaryHeaders() {
      return [
        { key: 'title', label: '' },
        { key: 'quantity', label: 'Cantidad de cupones' },
        {
          key: 'total', label: 'Total', type: 'currency', country: 'CL',
        },
      ];
    },
    countries() {
      return DataService.get_countries();
    },
  },
};
</script>
