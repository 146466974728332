<template lang="pug">
  el-select(
    v-model="attribute[modelKey]"
    :multiple="multiple"
    :placeholder="placeholder"
    :disabled="disabled"
    @change="handleChange($event)"
    filterable
    :allow-create="allowCreate"
  )
    el-option(
      v-for="option in options"
      :key="simple ? option : option.key"
      :label="simple? option : option.value"
      :value="simple? option: option[optionKey]"
    )
</template>

<script>
export default {
  name: 'SelectInput',
  props: {
    options: { required: true },
    attribute: { required: true },
    simple: { required: false, default: false },
    modelKey: { required: true, default: 'value' },
    optionKey: { required: false, default: 'key' },
    multiple: { required: false },
    allowCreate: { required: false, default: false },
    disabled: { required: false },
    changeMethod: { required: false },
    placeholder: { required: false, default: '' },
  },
  methods: {
    handleChange($event) {
      if (this.changeMethod) {
        this.changeMethod($event);
      }
    },
  },
};
</script>
