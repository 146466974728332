<template lang="pug">
el-container
  el-header
    el-menu(
      style="height: 48px;"
      mode="horizontal"
      :router="true"
      :collapse-transition="false"
      v-if="tabMenuItems"
    )
      el-menu-item(
        style="height: 48px; border-bottom-color: transparent;"
        v-for="tabMenuItem in tabMenuItems"
        v-if="tabMenuItem.name"
        :index="'index'"
        :route="{ path: tabMenuItem.path }"
        :class="activeTabMenuClass(tabMenuItem.path)"
      ) {{tabMenuItem.meta.title}}
  router-view(style="padding-left: 20px;")
</template>

<script>
export default {
  name: 'TabMenu',
  computed: {
    tabMenuItems() {
      const parent = this.$route.matched[this.$route.matched.length - 2];
      let name = '';
      if (parent) {
        name = parent;
      } else {
        name = this.$route;
      }
      const { routes } = this.$router.options;
      let tabs = [];
      routes.forEach((route) => {
        if (name.parent) {
          if (route.name === name.parent.name) {
            tabs = route.children.find((item) => item.name === name.name).children;
          }
        }
      });
      return tabs;
    },
  },
  methods: {
    activeTabMenuClass(path) {
      let itemStyle = '';
      const activePage = this.activeIndex();
      if (activePage.includes(path)) {
        itemStyle = 'tab-menu-is-active';
      }
      return itemStyle;
    },
    activeIndex() {
      return this.$route.path;
    },
  },
};
</script>
