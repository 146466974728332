<template lang="pug">
  div
    span(v-if="!loading && params.data && params.data.editable")
      button(@click="handleClick") Emitir
    span(v-if="loading && params.data.editable") Emitiendo...
</template>

<script>
import FinanceApi from '@/services/apiService';

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleClick() {
      this.$confirm(`¿Deseas emitir el DTE del Booking ${this.params.data.token}?`, 'Emitir Boleta', {
        confirmButtonText: 'SÍ',
        cancelButtonText: 'NO',
        type: 'warning',
      }).then(() => {
        const grid = this.params.context.componentParent;
        grid.changeTextRegenerating(this.params.data.token, this.params.node.id);
        this.loading = true;
        FinanceApi.regenerate_dte(this.params.data.token)
          .then((data) => {
            if (data.body.status) {
              this.$notify({
                title: 'Emitiendo Boleta',
                message: `La boleta del Booking ${this.params.data.token} está siendo emitida. Este proceso tardará algunos segundos, recargue la página para ver los cambios`,
                type: 'success',
              });
              this.params.data.editable = false;
            } else {
              this.$notify.error({ title: 'Error al emitir boleta' });
            }
          }).catch(() => {
            this.$notify.error({ title: 'Error al emitir boleta' });
          }).finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },
  },
};
</script>
