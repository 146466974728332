<template lang="pug">
  ag-grid-vue(
    :style="style()"
    class="ag-theme-balham"
    :columnDefs="columnDefinitions()"
    :defaultColDef="defaultColDef()"
    :rowData="rowData()"
    :localeText="localeTextEs()"
    :pagination="paginated"
    :paginationPageSize="pageSize"
    :rowModelType="rowModel"
    :rowSelection="rowSelection"
    :cacheBlockSize="blockSize"
    :maxConcurrentDatasourceRequests="datasourceRequests"
    :maxBlocksInCache="blocksInCache"
    :suppressColumnVirtualisation="columnVirtualisation"
    :context="context"
    @grid-ready="onGridReady"
    @cell-clicked="onCellClicked"
  )
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridVue } from 'ag-grid-vue';
import AG_GRID_LOCALE_ES from '@/locales/ag-grid.es';
import ReportTableSelectorFilter from '@/components/ui/ReportTableSelectorFilter.vue';
import ReportTableRangeFilter from '@/components/ui/ReportTableRangeFilter.vue';
import RegenerateDTERenderer from '@/components/tables/RegenerateDTERenderer.vue';

export default {
  name: 'ReportTable',
  props: {
    headers: { required: true },
    data: { required: false },
    sortable: { required: false, default: false },
    filterable: { required: false, default: true },
    paginated: { required: false, default: true },
    pageSize: { required: false },
    rowModel: { required: false },
    height: { required: true },
    blockSize: { required: false },
    datasourceRequests: { required: false },
    blocksInCache: { required: false },
    columnVirtualisation: { required: false, default: true },
  },
  components: {
    AgGridVue, ReportTableSelectorFilter, ReportTableRangeFilter, RegenerateDTERenderer,
  },
  data() {
    return {
      gridApi: null,
      rowSelection: 'multiple',
    };
  },
  beforeMount() {
    this.context = {
      componentParent: this,
    };
  },
  methods: {
    columnDefinitions() {
      return this.headers.map((header) => {
        const labelName = this.headerName(header);
        let resultHash = {
          headerName: labelName,
          headerTooltip: labelName,
          field: this.fieldName(header),
          minWidth: this.columnWidth(header),
          sortable: this.isSortable(header),
          cellRenderer: this.cellFormatter(header),
          onCellClicked: header.onCellClicked,
        };
        switch (header.filterType) {
          case 'selector':
            resultHash = {
              ...resultHash,
              filter: 'agTextColumnFilter',
              suppressMenu: true,
              filterParams: {
                filterOptions: ['equals'],
                suppressAndOrCondition: true,
                debounceMs: 0,
              },
              floatingFilterComponent: 'ReportTableSelectorFilter',
              floatingFilterComponentParams: {
                options: header.selectorOptions,
                suppressFilterButton: true,
              },
            };
            break;
          case 'range':
            resultHash = {
              ...resultHash,
              floatingFilterComponent: 'ReportTableRangeFilter',
              filterParams: {
                filterOptions: ['from', 'to'],
                suppressAndOrCondition: true,
                debounceMs: 0,
              },
              floatingFilterComponentParams: { suppressFilterButton: true },
              filter: 'agTextColumnFilter',
              suppressMenu: true,
            };
            break;
          case 'client_daterange':
            resultHash = {
              ...resultHash,
              filterParams: this.dateRangeFilterParams(),
              floatingFilterComponentParams: { suppressFilterButton: true },
              filter: 'agDateColumnFilter',
              suppressMenu: true,
            };
            break;
          case 'text':
            resultHash = {
              ...resultHash,
              filter: this.isFilterable(header) ? 'agTextColumnFilter' : false,
              filterParams: {
                filterOptions: ['startsWith'],
                suppressAndOrCondition: true,
              },
              floatingFilterComponentParams: { suppressFilterButton: true },
              suppressMenu: true,
            };
            break;
          default:
        }
        return resultHash;
      });
    },
    defaultColDef() {
      return {
        flex: 1,
        resizable: true,
        floatingFilter: true,
        menuModule: ['filterMenuTab'],
      };
    },
    localeTextEs() {
      return AG_GRID_LOCALE_ES;
    },
    rowData() {
      return this.data;
    },
    style() {
      return `height: ${this.height}px;`;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.$emit('updateTable', params);
    },
    headerName(header) {
      return header.headerName ? header.headerName : header;
    },
    columnWidth(header) {
      if (header.width !== undefined) {
        return header.width;
      }

      return 100;
    },
    fieldName(header) {
      return header.field ? header.field : header;
    },
    isSortable(header) {
      if (header.sortable !== undefined) { return header.sortable; }

      return this.sortable;
    },
    isFilterable(header) {
      if (header.filterable !== undefined) { return header.filterable; }

      return this.filterable;
    },
    cellFormatter(header) {
      switch (header.field) {
        case 'editable': return 'RegenerateDTERenderer';
        default: return header.field;
      }
    },
    changeTextRegenerating(token, node) {
      this.gridApi.forEachNode((rowNode) => {
        if (rowNode.data.token === token && rowNode.id !== node) { rowNode.setDataValue('editable', false); }
      });
    },
    dateRangeFilterParams() {
      return {
        suppressAndOrCondition: true,
        inRangeInclusive: true,
        debounceMs: 0,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) { return 0; }
          const dateParts = dateAsString.split('/');
          const year = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const day = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          let response = 0;
          if (cellDate < filterLocalDateAtMidnight) {
            response = -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            response = 1;
          }
          return response;
        },
      };
    },
    onCellClicked(params) {
      let formattedValue = '';

      if (params.column.getColDef().valueFormatter) {
        formattedValue = params.column.getColDef().valueFormatter(params).toString();
      } else { formattedValue = params.value.toString(); }

      if (formattedValue !== 'undefined' && formattedValue !== 'false' && formattedValue !== 'true') {
        this.$copyText(formattedValue).then((event) => {
          if (event) {
            this.$notify({ type: 'success', title: 'Texto copiado', message: formattedValue });
          }
        });
      }
    },
  },
};
</script>
