/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'recorrido-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0.798 72.201 72.202',
    data: '<defs><path pid="0" id="svgicon_recorrido-icon_a" d="M0 72.94h72.201V.798H0z"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_recorrido-icon_b" _fill="#fff"><use xlink:href="#svgicon_recorrido-icon_a"/></mask><path pid="1" d="M36.054 23.152c-8.283 0-12.616 6.78-12.616 13.746 0 7.061 4.238 13.653 12.616 13.653S48.67 43.959 48.67 36.898c0-6.966-4.897-13.746-12.616-13.746" _fill="#1A1918" mask="url(#svgicon_recorrido-icon_b)"/><path pid="2" d="M36.054 61.094c-15.064 0-24.103-10.825-24.103-24.196 0-13.275 9.229-24.195 24.01-24.195 14.779 0 24.289 10.92 24.289 24.195 0 13.371-9.133 24.196-24.196 24.196zM68.492.798H3.709A3.713 3.713 0 000 4.506v64.785A3.713 3.713 0 003.709 73h64.783a3.713 3.713 0 003.709-3.709V4.506A3.713 3.713 0 0068.492.798z" _fill="#1A1918" mask="url(#svgicon_recorrido-icon_b)"/><path pid="3" d="M48.67 36.898c0-6.966-4.897-13.746-12.616-13.746-8.283 0-12.616 6.78-12.616 13.746 0 7.061 4.238 13.653 12.616 13.653S48.67 43.959 48.67 36.898" _fill="#1A1918"/></g>'
  }
})
