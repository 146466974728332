<!-- eslint-disable prefer-destructuring -->
<template lang="pug">
  div
    el-row
      el-col
        h3.title Usuarios ({{ totalRegisters }})
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Rol / Estado:")
            el-cascader(:options="filterData()"
                        :props="{ multiple: true }"
                        placeholder="Filtrar por rol o estado"
                        collapse-tags
                        clearable
                        v-model="search.selectedValues"
                        @change="fetchUsers"
                        style="width: 100%;"
                        size="mini")
          el-form-item(label="Nombre usuario")
            el-input(v-model="search.name" placeholder="Buscar")
              template(#append)
                el-button(icon="el-icon-search" @click="fetchUsers()")
          el-form-item.filters__buttons-wrapper
            el-button(
                v-if="canSeeButton()"
                type="primary"
                @click.prevent="handleCreateUser()"
            ) Agregar usuario
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="users"
          :loading="loading"
          :height="500"
          :shownEdit="false"
          :sortable="true"
          @sort-change="sort"
        )
          template(slot="additionalActions" slot-scope="scope")
            el-tooltip(
              v-if="canSeeActions(scope.row)"
              content="Editar usuario"
              placement="top"
              effect="light"
              :show-after="800"
            )
              el-button(
                type="warning"
                circle
                size="mini"
                icon="el-icon-edit"
                @click.prevent="handleEditUser(scope.row)"
              )
            el-tooltip(
              v-if="canSeeActions(scope.row)"
              content="Eliminar usuario"
              placement="top"
              effect="light"
              :show-after="800"
            )
              el-popconfirm(
                title="¿Estás seguro que deseas borrar este usuario?"
                confirm-button-text="Aceptar"
                cancel-button-text="Cancelar"
                @confirm="deleteUser(scope.row)"
                width="237"
              )
                template(#reference)
                  el-button(
                    type="danger"
                    circle
                    style="padding: 9px"
                    icon="el-icon-delete"
                  )
            template(v-if="canSeeActions(scope.row)")
              template(v-if="setStatus(scope.row)")
                el-tooltip(
                  content="Desactivar cuenta"
                  placement="top"
                  effect="light"
                  :show-after="800"
                )
                  el-popconfirm(title="¿Estás seguro que deseas desactivar esta cuenta?"
                                confirm-button-text="Aceptar"
                                cancel-button-text="Cancelar"
                                @confirm="changeStatus(scope.row)"
                                width="220")
                    template(#reference)
                        el-button(
                          type="success"
                          circle
                          style="padding: 9px"
                          icon="el-icon-view"
                        )
              template(v-else)
                el-tooltip(
                  content="Activar cuenta"
                  placement="top"
                  effect="light"
                  :show-after="800"
                )
                  el-popconfirm(title="¿Estás seguro que deseas activar esta cuenta?"
                                confirm-button-text="Aceptar"
                                cancel-button-text="Cancelar"
                                @confirm="changeStatus(scope.row)"
                                width="220")
                    template(#reference)
                      el-button(
                        type="info"
                        circle
                        style="padding: 9px"
                        icon="el-icon-view"
                      )
            el-tooltip(
              v-if="canSeeActions(scope.row)"
              content="Reestablecer contraseña"
              placement="top"
              effect="light"
              :show-after="800"
            )
              el-button(
                circle
                size="mini"
                icon="el-icon-message"
                @click.prevent="resetPassword(scope.row)"
              )
        el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
    user-dialog(
      :initialAttributes="initialDialogAttributes"
      @setUsers="setUsers"
      @resetDialog="resetDialog"
    )
</template>

<script>
import InlineForm from '@/components/forms/InlineForm.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DateService from '@/services/dateService';
import setPagination from '@/utils/setPagination';
import store from '@/store';
import permissions from '@/constants/permissions';
import UserDialog from '../messages/dialogs/user/UserDialog.vue';

export default {
  name: 'UserManagament',
  components: {
    InlineForm, DataTable, DateInput, UserDialog,
  },
  data() {
    return {
      headers: [
        { key: 'email', label: 'Correo Electrónico', sortable: true },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'last_name', label: 'Apellido', sortable: true },
        { key: 'role', label: 'Rol', sortable: true },
        {
          key: 'last_activity', label: 'Última actividad', type: 'formatted', formatted_value: (value) => this.formatDate(value), sortable: true,
        },
        {
          key: 'deactivated', label: 'Estado', type: 'formatted', formatted_value: (value) => this.formatStatus(value), sortable: true,
        },
        {
          key: 'password_age', label: 'Edad de contraseña', type: 'formatted', formatted_value: (value) => this.formatPasswordAge(value), sortable: true,
        },
        {
          key: 'activation_key_age', label: 'Edad de clave de activación', type: 'formatted', formatted_value: (value) => this.formatActivationKey(value), sortable: true,
        },
      ],
      users: [],
      totalRegisters: 0,
      search: {
        selectedValues: [],
        name: '',
      },
      pagination: {},
      orderBy: {},
      initialDialogAttributes: { visible: false, data: {}, action: '' },
      loading: false,
      submitLoading: false,
    };
  },
  beforeMount() {
    this.fetchUsers();
  },
  methods: {
    handleCurrentChange(page) {
      this.fetchUsers(page);
    },
    fetchUsers(page = 1) {
      this.loading = true;

      const params = {
        roles: [], states: [], name: this.search.name, order: this.orderBy,
      };

      if (this.search.selectedValues.length > 0) {
        this.search.selectedValues.forEach((pair) => {
          const [key, value] = pair;
          if (key === 'role') {
            params.roles.push(value);
          } else if (key === 'state') {
            params.states.push(value);
          }
        });
      }

      FinanceApi.get_users(page, params).then((data) => {
        this.users = data.body;
        const [totalRegisters] = data.headers.map.total;
        this.totalRegisters = totalRegisters;
        setPagination(this, data, page);
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    filterData() {
      return [
        {
          value: 'role',
          label: 'Rol',
          children: [
            { label: 'Administrador', value: 'admin' },
            { label: 'Superadministrador', value: 'superadmin' },
            { label: 'Agente finanzas', value: 'finance_agent' },
            { label: 'Observador', value: 'observer' },
            { label: 'Chief', value: 'chief' },
          ],
        },
        {
          value: 'state',
          label: 'Estado',
          children: [
            { label: 'Activo', value: 'false' },
            { label: 'Inactivo', value: 'true' },
          ],
        },
      ];
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
    formatStatus(status) {
      if (status) { return 'Inactivo'; }

      return 'Activo';
    },
    formatDate(date) {
      if (date === null) { return 'Nunca'; }

      return DateService.getDateWithTimezone('America/Santiago', date, 'DD/MM/YYYY, HH:mm:ss');
    },
    formatPasswordAge(age) {
      if (age === 1) { return `${age} día`; }

      return `${age} días`;
    },
    formatActivationKey(value) {
      if (value === 1) { return `${value} día`; }

      return `${value} días`;
    },
    handleCreateUser() {
      this.initialDialogAttributes = {
        data: { ...this.formAttributes() },
        action: 'create',
        visible: true,
        title: 'Crear nuevo usuario',
      };
    },
    handleEditUser(user) {
      const editAttributes = { ...this.formAttributes() };
      Object.keys(user).forEach((key) => {
        if (editAttributes[key] !== undefined) {
          if (key !== 'last_activity') {
            editAttributes[key].value = user[key].value || user[key];
          } else {
            editAttributes[key].value = user[key] || null;
          }
        }
      });
      this.initialDialogAttributes = {
        data: editAttributes,
        action: 'update',
        visible: true,
        title: 'Editar usuario',
      };
    },
    deleteUser(user) {
      FinanceApi.delete_user(user.id).then((response) => {
        this.$notify({
          title: 'Eliminado',
          message: response.body.message,
          type: 'success',
        });
        this.fetchUsers();
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    changeStatus(user) {
      FinanceApi.change_status(user.id).then((response) => {
        this.$notify({
          title: 'Actualizado',
          message: response.body.message,
          type: 'success',
        });
        this.fetchUsers();
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    resetPassword(user) {
      FinanceApi.reset_password(user.id).then((response) => {
        this.$notify({
          title: 'Restablecer contraseña',
          message: response.body.message,
          type: 'success',
        });
        this.fetchUsers();
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    resetDialog() {
      this.initialDialogAttributes = {
        data: { ...this.formAttributes() },
        visible: false,
        action: '',
      };
    },
    async sort(column) {
      if (column.order == null) {
        this.orderBy = {};
      } else {
        const prop = column.prop.includes('role') ? 'roles' : column.prop.split('.')[0];

        this.orderBy = { prop, order: column.order };
      }
      await this.fetchUsers();
    },
    setUsers() {
      this.fetchUsers();
    },
    formAttributes() {
      return {
        id: {
          value: '',
        },
        last_activity: {
          value: 'null',
        },
        name: {
          key: 'name',
          label: 'Nombre',
          value: '',
          input_type: 'string',
          model: 'userForm.first_name',
        },
        last_name: {
          key: 'last_name',
          label: 'Apellido',
          value: '',
          input_type: 'string',
          model: 'userForm.last_name',
        },
        email: {
          key: 'email',
          label: 'Correo Electrónico',
          value: '',
          input_type: 'string',
          model: 'userForm.email',
        },
        role: {
          key: 'roles.name',
          label: 'Rol',
          value: '',
          input_type: 'select',
          model: 'userForm.roles',
        },
      };
    },
    canSeeActions(attributes) {
      if (store.state.user.role === 'superadmin') { return true; }

      if (attributes?.role === 'superadmin') {
        return false;
      }
      return true;
    },
    setStatus(attributes) {
      return !attributes.deactivated;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-tooltip {
  padding: 10px !important;
}
</style>
