<template lang="pug">
el-dialog(:title="defaultTitle()"
          :visible.sync="initialAttributes.visible"
          width="40%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false")
  el-form(:model="userForm"
          ref="userFormRef"
          :rules="validationUserForm"
          label-position="top"
          size="large")
    el-form-item(label="Nombre" :label-width="formLabelWidth" prop="name")
      el-input(v-model="userForm.name" autocomplete="off")
    el-form-item(label="Apellido" :label-width="formLabelWidth" prop="last_name")
      el-input(v-model="userForm.last_name" autocomplete="off")
    el-form-item(label="Correo Electrónico" :label-width="formLabelWidth" prop="email")
      el-input(v-model="userForm.email" autocomplete="off" :disabled="disableEmailInput()")
    el-form-item(label="Rol" :label-width="formLabelWidth" prop="roles.name")
      el-select(v-model="userForm.roles.name" placeholder="Rol")
        el-option(v-for="role in roles"
                  :key="role.name"
                  :label="role.display_name"
                  :value="role.name")
  span(slot="footer")
      el-button(size="small" @click="closeDialog()") Cancelar
      el-button(size="small" type="primary" @click="submitForm()") Guardar
</template>
<script>
import DataService from '@/services/dataService';
import financeApi from '@/services/apiService';
import store from '@/store';

export default {
  name: 'UserDialog',
  props: ['initialAttributes'],
  data() {
    return {
      formLabelWidth: '140px',
      roles: [],
      dialogData: {},
      userForm: {
        name: '',
        last_name: '',
        email: '',
        roles: { name: '' },
      },
    };
  },
  mounted() {
    const availableRoles = DataService.GetRoles();
    if (store.state.user.role === 'superadmin') {
      this.roles = availableRoles;
    } else {
      this.roles = availableRoles.filter((role) => role.name !== 'superadmin');
    }
  },
  methods: {
    defaultTitle() {
      return this.initialAttributes?.title;
    },
    closeDialog() {
      this.$emit('resetDialog');
      this.setDialogData();
      const { userFormRef } = this.$refs;
      if (userFormRef) {
        userFormRef.resetFields();
      }
    },
    setDialogData() {
      Object.keys(this.userForm).forEach((field) => {
        if (field !== 'roles') {
          this.userForm[field] = this.dialogData[field].value || '';
        } else {
          this.userForm[field].name = this.dialogData.role?.value || '';
        }
      });
    },
    async submitForm() {
      const { userFormRef } = this.$refs;
      userFormRef.validate((valid) => {
        if (valid) {
          switch (this.initialAttributes.action) {
            case 'create':
              this.userRegister();
              break;
            case 'update':
              this.updateUser();
              break;
            default:
          }
        }
      });
    },
    userRegister() {
      financeApi.create_user(this.userForm).then((response) => {
        this.$notify({
          title: 'Creado',
          message: response.body.message,
          type: 'success',
        });
        this.closeDialog();
        this.$emit('setUsers');
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    updateUser() {
      financeApi.update_user(this.initialAttributes.data.id.value, this.userForm)
        .then((response) => {
          this.$notify({
            title: 'Editado',
            message: response.body.message,
            type: 'success',
          });
          this.closeDialog();
          this.$emit('setUsers');
        }).catch((exception) => {
          this.$notify.error({ title: 'Error', message: exception.body.message });
        });
    },
    validateCharacters(field, value, callback) {
      const regex = '^[A-Za-z\u00E0-\u00FC ]+$';
      if (value.match(regex)) {
        callback();
      } else {
        callback(new Error('Sólo puede contener letras'));
      }
    },
    disableEmailInput() {
      if (this.initialAttributes.action === 'update' && this.dialogData?.last_activity?.value !== null) {
        return true;
      }
      return false;
    },
  },
  computed: {
    validationUserForm() {
      return {
        name: [
          { required: true, message: 'Por favor ingrese un nombre', trigger: 'blur' },
          { validator: this.validateCharacters, trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Por favor ingrese un apellido', trigger: 'blur' },
          { validator: this.validateCharacters, trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Por favor ingrese un email', trigger: 'blur' },
          { type: 'email', message: 'Por favor ingresa un email correcto', trigger: 'blur' },
        ],
        'roles.name': [
          {
            required: true,
            message: 'Por favor seleccione un rol para el usuario',
            trigger: 'change',
          },
        ],
      };
    },
  },
  watch: {
    initialAttributes() {
      this.dialogData = this.initialAttributes?.data;
      this.setDialogData();
    },
  },
};
</script>
<style>
.el-button--text {
  margin-right: 15px;
}
.el-select, .el-input {
  width: 100%;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
