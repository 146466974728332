<template lang="pug">
  div
    input.ag-text-field-input(
      v-model="inputFilterFrom"
      name="from"
      type="text"
      placeholder="Mayor que"
    )
    br
    input.ag-text-field-input(
      v-model="inputFilterTo"
      name="to"
      type="text"
      placeholder="Menor que"
    )
</template>

<script>
export default {
  name: 'ReportTableRangeFilter',
  data() {
    return {
      inputFilterFrom: null,
      inputFilterTo: null,
      selectedFilter: null,
    };
  },
  watch: {
    inputFilterFrom(newValue) {
      this.filterChanged(newValue, 'from');
    },
    inputFilterTo(newValue) {
      this.filterChanged(newValue, 'to');
    },
  },
  methods: {
    filterChanged(newValue, name) {
      let timeout = 0;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        let value = newValue;
        if (newValue === '') {
          value = name === 'from' ? '0' : '-1';
        }

        this.params.parentFilterInstance((instance) => {
          if (name === 'from') {
            this.selectedFilter = value;
            instance.onFloatingFilterChanged(name, value);
          } else {
            this.selectedFilter = value;
            instance.onFloatingFilterChanged(name, value);
          }
        });
      }, 3000);
    },
    onParentModelChanged(parentModel) {
      this.selectedFilter = parentModel?.filter;
    },
  },
};
</script>
