<template lang="pug">
  div
    h1 Sistema de finanzas
    img(src="@/assets/images/home.jpeg" class="img-home")
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {};
  },
};
</script>
