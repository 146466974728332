/* eslint-disable */
require('./recorrido-icon')
require('./cashier')
require('./accounting')
require('./payment-method')
require('./taxes-icon')
require('./group')
require('./commissions-icon')
require('./analysis')
require('./invoice')
require('./statistics')
require('./calendar-with-a-clock-rotate')
require('./customer-support-svgrepo-com')
require('./operations-icon')
require('./settings-icon')
require('./discount-sticker-with-percentage')
require('./cancel')
require('./activity-history')
require('./netsuite')
require('./reports-icon')
