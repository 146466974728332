<template lang="pug">
  div
    el-row
      el-col
        h3.title Configuración de agencias
    el-row
      el-col
        h4 Agencias B2C
        data-table(
          :headers="b2cHeaders"
          :items="b2cAgencies"
          @update="updateAgencyDialog"
          :loading="loading"
          :height="450"
          :shownActions="canSeeButton()"
        )
      el-dialog(
        title="Ver/Editar Configuración de Agencia"
        :visible.sync="dialogForm.visible"
        width="70%")
          inline-form(:formAttributes="dialogForm.attributes" ref="dialogForm")
          el-row.inline-form__actions
            el-button(@click="closeDialog()") Cancelar
            el-button(
              type="primary"
              @click.prevent="confirmUpdate()"
              :loading="submitLoading"
            ) Actualizar
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import permissions from '@/constants/permissions';
import store from '@/store';
import flattenForm from '@/utils/flattenForm';
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import InlineForm from '@/components/forms/InlineForm.vue';
import DataService from '@/services/dataService';
import formatForm from '@/utils/formatForm';

export default {
  name: 'Agencies',
  components: {
    SelectInput,
    InlineForm,
    DataTable,
  },
  data() {
    return {
      b2cHeaders: [
        { key: 'name', label: 'Nombre' },
        { key: 'internal_name', label: 'Nombres internos' },
      ],
      b2cAgencies: [],
      loading: false,
      submitLoading: false,
      dialogForm: { visible: false, attributes: {}, data: {} },
    };
  },
  beforeMount() {
    this.fetchAgencies();
  },
  methods: {
    fetchAgencies() {
      this.loading = true;
      FinanceApi.getAgencies().then((data) => {
        this.b2cAgencies = data.body;
        this.loading = false;
      }).catch(() => { this.loading = false; });
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
    updateAgencyDialog(data) {
      const attributes = this._.cloneDeep(this.formAttributes);
      const rows = flattenForm(attributes.rows);
      // eslint-disable-next-line
      rows.forEach((row) => row.value = this._.get(data, row.key));
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = { ...data };
      if (this.dialogForm.data.send_report_email) {
        this.dialogForm.attributes.rows[1][0].rows[1].visible = false;
        this.dialogForm.attributes.rows[1][0].rows[2].visible = false;
      } else {
        this.dialogForm.attributes.rows[1][0].rows[1].visible = true;
        this.dialogForm.attributes.rows[1][0].rows[2].visible = true;
      }
      this.dialogForm.visible = true;
    },
    closeDialog() {
      this.dialogForm.visible = false;
    },
    confirmUpdate() {
      this.startSubmitLoading();
      const { form } = this.$refs.dialogForm.$refs;
      form.validate((valid) => {
        if (valid) {
          this.$confirm('¿Estás seguro de que deseas modificar la información de la Agencia?', '¡Cuidado!', {
            confirmButtonText: 'SÍ',
            cancelButtonText: 'NO',
            type: 'warning',
          }).then(() => {
            this.updateAgency();
          }).catch(() => {
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    updateAgency() {
      const { id } = this.dialogForm.data;
      const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
      FinanceApi.updateAgency(id, attributes).then(() => {
        this.closeDialog();
        this.fetchAgencies();
        this.$notify({
          title: 'Correcto',
          message: 'Agencia modificada correctamente',
          type: 'success',
        });
        this.stopSubmitLoading();
      }).catch(() => {
        this.$notify({
          title: 'Error',
          message: 'Agencia no modificada',
          type: 'error',
        });
        this.stopSubmitLoading();
      });
    },
  },
  computed: {
    frequency() {
      return DataService.FormatDate('sender');
    },
    formAttributes() {
      const $this = this;
      return {
        rows: [
          [
            {
              key: 'name', value: null, inputType: 'description', label: 'Nombre',
            },
          ],
          [
            {
              title: 'Correos de ventas/cancelados',
              grouped: true,
              rows: [
                {
                  key: 'send_report_email',
                  value: null,
                  inputType: 'switch',
                  label: 'Enviar por mail',
                  change(value) {
                    const senderFrequency = $this.dialogForm.attributes.rows[1][0].rows[1];
                    const senderEmails = $this.dialogForm.attributes.rows[1][0].rows[2];
                    if (value) {
                      senderFrequency.visible = false;
                      senderEmails.visible = false;
                    } else {
                      senderFrequency.visible = true;
                      senderEmails.visible = true;
                    }
                  },
                },
                {
                  key: 'send_report_frequency', value: null, inputType: 'select', label: 'Frecuencia del correo', options: this.frequency, visible: true,
                },
                {
                  key: 'send_report_emails',
                  value: '',
                  inputType: 'select',
                  multiple: true,
                  label: 'Correos',
                  allowCreate: true,
                  optionKey: 'id',
                  visible: true,
                },
              ],
            },
          ],
        ],
      };
    },
  },
};
</script>
