<template lang="pug">
el-container.login
  el-card.password__card
    template(#header v-if="!showResponse")
      p Crea tu contraseña en Finanzas
    template(v-if="!showResponse")
      el-form(
        :model="passwordForm"
        ref="passwordFormRef"
        label-position="top"
        size="large"
        :rules="validationsPasswordForm"
      )
        el-form-item(label="Contraseña" prop="password" )
          el-input(v-model="passwordForm.password" autocomplete="off" type="password")
        el-form-item(label="Confirma contraseña" prop="password_confirmation")
          el-input(
            v-model="passwordForm.password_confirmation"
            autocomplete="off"
            type="password"
          )
        div.center
          el-form-item
            el-button.password__form-submit(type="primary" @click="submitForm()")
              .password__form-submit-text Guardar
    template(v-else)
      p.center {{ savePasswordResponse }}
      div.center
        el-link(href="/#/login" target="_self" type="primary") Inicia sesión
</template>

<script>
// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

export default {
  name: 'Password',
  data() {
    return {
      showResponse: false,
      savePasswordResponse: '',
      passwordForm: {
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    async submitForm() {
      const { passwordFormRef } = this.$refs;
      passwordFormRef.validate((valid) => {
        if (valid) {
          FinanceApi.save_password(this.passwordForm, this.$route.params.rpt)
            .then((response) => {
              this.savePasswordResponse = response.body.message;
              this.showResponse = true;
            }).catch((exception) => {
              this.$notify.error({ title: 'Error', message: exception.body.message });
            });
        }
      });
    },
    validatesamePasswords(rule, value, callback) {
      if (value !== '' && value !== this.passwordForm.password) {
        return callback(new Error('Las contraseñas no coinciden'));
      }
      return callback();
    },
  },
  computed: {
    validationsPasswordForm() {
      return {
        password: [
          { required: true, message: 'Por favor ingrese una contraseña', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe tener al menos 8 caracteres', trigger: 'blur' },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
            message: 'La contraseña debe contener al menos una mayúscula, una minúscula, un número y un carácter especial',
            trigger: 'blur',
          },
        ],
        password_confirmation: [
          { required: true, message: 'Por favor confirme su contraseña', trigger: 'blur' },
          { validator: this.validatesamePasswords, trigger: 'blur' },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container.login {
  background-color: #2a5289 !important;
}

.password__card {
  width: 600px;

  &-title, .center {
    display: flex;
    justify-content: center;
  }

  .el-link {
    margin: 10px;
  }
}

.el-card__body {
  height: 100%;
}
</style>
