<template lang="pug">
  div
    el-row
      el-col
        h3.title Configuraciones Generales
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Correos Transacciones TBK:" style="width: 60%;")
            select-input(
              :options="tbkMails"
              :attribute="settings"
              modelKey="tbkMail"
              :multiple="true"
              :allowCreate="true"
            )
          el-form-item.filters__buttons-wrapper
            el-button(type="default" @click.prevent="cancelUpdate()") Cancelar
            el-button(
              v-if="canSeeButton()"
              type="primary" @click.prevent="updateTbkMails()") Actualizar
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import store from '@/store';
import permissions from '@/constants/permissions';

export default {
  name: 'GeneralSettings',
  components: {
    SelectInput,
  },
  data() {
    return {
      settings: {
        tbkMail: [],
      },
    };
  },
  beforeMount() {
    this.fetchGeneralSettings();
  },
  methods: {
    fetchGeneralSettings() {
      FinanceApi.get_general_settings().then((data) => {
        this.settings.id = data.body[0].id;
        this.settings.tbkMail = data.body[0].tbk_mails;
      });
    },
    updateTbkMails() {
      this.startSubmitLoading();
      const { id, tbkMail } = this.settings;
      FinanceApi.updateTbkMails(id, tbkMail).then(() => {
        this.$notify({
          title: 'Correcto',
          message: 'Configuración modificada correctamente',
          type: 'success',
        });
        this.stopSubmitLoading();
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.stopSubmitLoading();
      });
    },
    cancelUpdate() {
      this.fetchGeneralSettings();
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    tbkMails() {
      return '';
    },
  },
};
</script>
