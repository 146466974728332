<template lang="pug">
  div
    el-timeline
      ul(class="infinite-list" style="overflow: auto")
        el-timeline-item(v-for="item in formatHistoricalRecords()" :key="item.title"
          v-infinite-scroll="4" :timestamp="item.date" placement="top")
          el-card(style="padding: 10px;")
            el-descriptions(v-if="item.showPending || item.showCreated"
              border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") {{ item.changeState }}
              el-descriptions-item(:span="2" label="Tipo" v-if="item.showCreated")
                el-tag(size="small") Cancelación creada
              el-descriptions-item(:span="2" label="Estado")
                el-tag(size="small") {{ item.state }}
              el-descriptions-item(:span="4" label="Responsable") {{ item.responsible }}
              el-descriptions-item(:span="4" label="Vía") {{ formatRefundMethod(item) }}
              el-descriptions-item(v-if="item.paypal" :span="4"
                label="Cuenta Paypal") {{ item.paypalData.paypal_account }}
              el-descriptions-item(v-if="item.payrollPaypal" :span="4"
                label="Cuenta Paypal") {{ item.payrollPaypalData.paypal_account }}
              el-descriptions-item(v-if="item.bankTransfer" :span="4"
                label="Datos de transferencia bancaria")
              el-descriptions-item(v-if="item.bankTransfer" :span="2"
                label="Nombre") {{ item.fullname }}
              el-descriptions-item(v-if="item.bankTransfer" :span="2"
                label="Banco") {{ item.bank }}
              el-descriptions-item(v-if="item.bankTransfer" :span="2"
                label="Rut") {{ item.accountRut }}
              el-descriptions-item(v-if="item.bankTransfer" :span="2"
                label="Tipo de cuenta") {{ item.account }}
              el-descriptions-item(v-if="item.bankTransfer" :span="2"
                label="Número de cuenta") {{ item.accountNumber }}
              el-descriptions-item(v-if="item.coupon" :span="4"
                label="Datos del cupón asociado a la cancelación")
              el-descriptions-item(v-if="item.coupon" :span="2"
                label="Token") {{ item.token }}
              el-descriptions-item(v-if="item.coupon" :span="2"
                label="Estado") {{ item.couponState }}
              el-descriptions-item(v-if="item.coupon" :span="2"
                label="Plataforma") {{ item.platform }}
              el-descriptions-item(v-if="item.coupon" :span="2"
                label="Fecha de creación") {{ item.createdDate }}
              el-descriptions-item(v-if="item.coupon" :span="2"
                label="Fecha de expiración") {{ item.expiresDate }}

            el-descriptions(v-if="item.showRefunded" border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") Si
              el-descriptions-item(:span="2" label="Estado")
                el-tag(size="small") {{ item.state }}
              el-descriptions-item(:span="4" label="Responsable") {{ item.responsible }}
              el-descriptions-item(:span="4" label="Vía") {{ formatRefundMethod(item) }}
              el-descriptions-item(v-if="item.manually" :span="4"
                label="Número de operación") {{ item.operationNumber }}
              el-descriptions-item(v-if="!item.manually && !item.agency" :span="4"
                label="Número de devolución") {{ item.refundNumber }}

            el-descriptions(v-if="item.showProcessing" border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") Si
              el-descriptions-item(:span="2" label="Estado")
                el-tag(size="small") {{ item.state }}
              el-descriptions-item(:span="4" label="Responsable") {{ item.responsible }}
              el-descriptions-item(:span="4"
                label="Cancelaciones de exportación de nómina") {{ item.ticketCancellationIds }}
              el-descriptions-item(:span="4" label="Fechas de exportación de nómina")
              el-descriptions-item(:span="4" label="Desde") {{ item.payrollStartDate }}
              el-descriptions-item(:span="4" label="Hasta") {{ item.payrollEndDate }}

            el-descriptions(v-if="item.showExcluded" border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") Si
              el-descriptions-item(:span="2" label="Estado")
                el-tag(size="small") {{ item.state }}
              el-descriptions-item(:span="4" label="Responsable") {{ item.responsible }}
              el-descriptions-item(:span="4" label="Motivo de exclusión") {{ item.reason }}

            el-descriptions(v-if="item.showExpired" border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") Si
              el-descriptions-item(:span="2" label="Estado")
                el-tag(size="small") Expirado

            el-descriptions(v-if="item.showRejected" border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") Si
              el-descriptions-item(:span="2" label="Estado")
                el-tag(size="small") Rechazado

            el-descriptions(v-if="item.showComplaintRefunded || item.showCouponPayment"
            border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") Si
              el-descriptions-item(:span="2" label="Estado")
                el-tag(size="small") {{ item.state }}
              el-descriptions-item(:span="4" label="Responsable") {{ item.responsible }}
              el-descriptions-item(:span="4" label="ID de reclamo") {{ item.complaintId }}

            el-descriptions(v-if="item.showUpdateBankData"
              border size="mini")
              el-descriptions-item(:span="2" label="Cambio de estado") {{ item.changeState }}
              el-descriptions-item(v-if="item.showUpdateBankData" :span="2" label="Tipo")
                el-tag(size="small") Datos bancarios actualizados
              el-descriptions-item(v-if="item.bankTransferData" :span="4"
                label="Datos de transferencia bancaria")
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Número de cuenta") {{ item.accountNumber }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Tipo de cuenta") {{ item.accountType }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Banco") {{ item.bank }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="CCI") {{ item.cci }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Número de documento") {{ item.documentNumber }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Tipo de documento") {{ item.documentType }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Email") {{ item.email }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Nombre") {{ item.name }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Rut") {{ item.rut }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Fecha de creación") {{ item.createdDate }}
              el-descriptions-item(v-if="item.bankTransferData" :span="2"
                label="Fecha de actualización") {{ item.updatedDate }}
</template>

<script>
import DateService from '@/services/dateService';

export default {
  name: 'HistoricDetails',
  props: {
    historicalRecords: { required: false },
  },
  data() {
    return {
      historicDetail: {
        created: false,
        pending: false,
        processing: false,
        refunded: false,
        rejected: false,
        couponPaymentFormChanged: false,
        complaintRefunded: false,
        excluded: false,
        expired: false,
        updateBankData: false,
      },
    };
  },
  methods: {
    formatHistoricalRecords() {
      const records = [];

      if (this.historicalRecords !== null || this.historicalRecords.length > 0) {
        this.historicalRecords.forEach((record) => {
          const date = `Fecha: ${DateService.getDate(record.state_at, 'DD/MM/YYYY')}`;
          const recordItem = { state: record.status, responsible: record.responsible, date };
          switch (record.status) {
            case 'created':
            case 'pending':
              recordItem.showCreated = record.status === 'created';
              recordItem.showPending = record.status === 'pending';
              recordItem.changeState = record.status === 'pending' ? 'Si' : 'No';
              recordItem.state = 'Pendiente';
              recordItem.via = record.via;

              if (record.bank_transfer_data !== undefined) {
                if (record.status === 'created') {
                  recordItem.bankTransfer = true;
                  recordItem.fullname = record.bank_transfer_data.fullname;
                  recordItem.bank = record.bank_transfer_data.bank_name;
                  recordItem.account = record.bank_transfer_data.bank_account;
                  recordItem.accountRut = record.bank_transfer_data.bank_account_rut;
                  recordItem.accountNumber = record.bank_transfer_data.bank_account_number;
                } else {
                  recordItem.bankTransfer = true;
                  recordItem.fullname = record.bank_transfer_data.fullname_updated;
                  recordItem.bank = record.bank_transfer_data.bank_name_updated;
                  recordItem.account = record.bank_transfer_data.bank_account_updated;
                  recordItem.accountRut = record.bank_transfer_data.bank_account_rut_updated;
                  recordItem.accountNumber = record.bank_transfer_data.bank_account_number_updated;
                }
              } else { recordItem.bankTransfer = false; }

              if (record.paypal_data !== undefined) {
                recordItem.paypal = true;
                recordItem.paypalData = record.paypal_data;
              } else { recordItem.paypal = false; }

              if (record.payroll_paypal_data !== undefined) {
                recordItem.payrollPaypal = true;
                recordItem.payrollPaypalData = record.payroll_paypal_data;
              } else { recordItem.payrollPaypal = false; }

              if (record.coupon_data !== undefined) {
                recordItem.coupon = true;
                recordItem.token = record.coupon_data.token;
                recordItem.createdDate = DateService.getDate(record.coupon_data.created_at, 'DD/MM/YYYY');
                recordItem.expiresDate = DateService.getDate(record.coupon_data.expires_at, 'DD/MM/YYYY');
                recordItem.platform = record.coupon_data.platform;
                recordItem.couponState = record.coupon_data.aasm_state;
              } else { recordItem.coupon = false; }
              break;
            case 'processing':
              recordItem.showProcessing = true;
              recordItem.changeState = 'Si';
              recordItem.state = 'Procesando';
              recordItem.payrollStartDate = DateService.getDate(record.payroll_export_dates.start_at, 'DD/MM/YYYY');
              recordItem.payrollEndDate = DateService.getDate(record.payroll_export_dates.end_at, 'DD/MM/YYYY');
              recordItem.ticketCancellationIds = record.ticket_cancellation_ids.join(' - ');
              break;
            case 'refunded':
              recordItem.showRefunded = true;
              recordItem.changeState = 'Si';
              recordItem.state = 'Devuelto';
              recordItem.agency = false;

              if (record.operation_number !== undefined) {
                recordItem.manually = true;
                recordItem.operationNumber = record.operation_number;
              } else {
                recordItem.manually = false;
                recordItem.refundNumber = record.refund_number;
              }

              if (record.operation_number === undefined && record.refund_number === undefined) {
                recordItem.agency = true;
              }

              recordItem.via = record.via;
              break;
            case 'coupon_payment_form_changed':
            case 'complaint_refunded':
              recordItem.showComplaintRefunded = true;
              recordItem.changeState = 'Si';
              recordItem.state = record.status === 'complaint_refunded' ? 'Reclamo devuelto' : 'Cupón cambio forma de pago';
              recordItem.complaintId = record.complaint_id;
              break;
            case 'excluded':
              recordItem.showExcluded = true;
              recordItem.changeState = 'Si';
              recordItem.state = 'Excluido';
              recordItem.reason = record.reason;
              break;
            case 'expired':
              recordItem.showExpired = true;
              break;
            case 'rejected':
              recordItem.showRejected = true;
              break;
            case 'updated_bank_data':
              recordItem.showUpdateBankData = record.status === 'updated_bank_data';

              if (record.status === 'updated_bank_data') {
                recordItem.bankTransferData = true;
                recordItem.complaintId = record.complaint_id;
              }

              recordItem.accountNumber = record.bank_transfer_data.account_number;
              recordItem.accountType = record.bank_transfer_data.account_type;
              recordItem.bank = record.bank_transfer_data.bank;
              recordItem.cci = record.bank_transfer_data.cci;
              recordItem.documentNumber = record.bank_transfer_data.document_number;
              recordItem.documentType = record.bank_transfer_data.document_type;
              recordItem.email = record.bank_transfer_data.email;
              recordItem.name = record.bank_transfer_data.name;
              recordItem.rut = record.bank_transfer_data.rut;
              recordItem.createdDate = DateService.getDate(record.bank_transfer_data.created_at, 'DD/MM/YYYY');
              recordItem.updatedDate = DateService.getDate(record.bank_transfer_data.updated_at, 'DD/MM/YYYY');
              break;
            default:
              break;
          }
          records.push(recordItem);
        });
      }

      return records;
    },
    formatRefundMethod(item) {
      let label = '';
      switch (item.via) {
        case 'via_coupon': label = 'Cupón Reembolso'; break;
        case 'via_bank_transfer': label = 'Nómina Banco'; break;
        case 'via_paypal': label = 'Paypal'; break;
        case 'via_payroll_paypal': label = 'Nómina paypal'; break;
        case 'via_payroll_bank': label = 'Nómina banco'; break;
        case 'via_agency':
        case 'via_external_agency': label = 'Agencia'; break;
        case 'via_manual_change': label = 'Cambio manual'; break;
        case 'via_paypal_api': label = 'API Paypal'; break;
        case 'via_tbk_devolution': label = 'Reembolso Transbank'; break;
        case 'via_oneclick': label = 'Reembolso Oneclick'; break;
        default: label = item.via; break;
      }
      return label;
    },
  },
};
</script>

<style>
  .infinite-list {
    height: 400px;
    padding: 0;
    margin: 0;
    list-style: none;
  }
</style>
