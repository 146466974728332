<template lang="pug">
div
  el-row
    el-col
      h3.title Historial de actividades
  el-row
    el-col
      el-form(:inline="true" label-position="top" size="mini")
        el-form-item(label="Rango de fecha:")
          date-input(:inputModel="search" modelKey="dateRange" type="daterange")
        el-form-item.filters__buttons-wrapper
          el-button(
            type="primary"
            icon="el-icon-search"
            @click.prevent="fetchActivityHistory()"
          ) FILTRAR
  el-row
    el-col
      data-table(
        :headers="headers"
        :items="items"
        :loading="loading"
        :shownActions="false"
        :height="700"
      )
      el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
</template>

<script>
import DateInput from '@/components/forms/inputs/DateInput.vue';
import DataTable from '@/components/tables/DataTable.vue';

import DateService from '@/services/dateService';
import setPagination from '@/utils/setPagination';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

export default {
  name: 'ActivityHistory',
  components: { DateInput, DataTable },
  data() {
    return {
      headers: [
        { key: 'responsible', label: 'Responsable' },
        { key: 'date', label: 'Fecha de actividad' },
        { key: 'detail', label: 'Detalle' },
      ],
      search: {
        dateRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
      },
      items: [],
      pagination: {},
      loading: false,
    };
  },
  beforeMount() {
    this.fetchActivityHistory();
  },
  methods: {
    fetchActivityHistory(page = 1) {
      this.loading = true;
      FinanceApi.get_activity_history(page, this.search).then((data) => {
        this.items = data.body.data;
        setPagination(this, data, page);
        this.loading = false;
      }).catch((exception) => {
        this.loading = false;
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    handleCurrentChange(page) {
      this.fetchActivityHistory(page);
    },
  },
};
</script>
