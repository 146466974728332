<template lang="pug">
  div
    el-row
      el-col
        h3.title Ventas - {{ selectedCountryName }}
    el-row
      el-col
        sales-filters(
          :search="search"
          :countries="countries"
          @fetchSales="fetchSales"
          @exportReport="exportReport"
          @sendTicketReport="sendTicketReport"
        )
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :summaries="summary"
          :shownActions="false"
          :loading="loading"
          :shownSummary="true"
          :copySummary="true"
          refTable="salesTable"
        )
    el-row(v-if="withCoupons")
      h4 Resumen
      el-col
        el-row(v-for="item in summaryWithCoupons" :key="item.title")
          dt
            | {{ item.title }}:
          dd
            | {{ item.total }}
</template>

<script>
import SalesFilters from '@/components/control/analytics/sales/SalesFilters.vue';
import DataTable from '@/components/tables/DataTable.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'Sales',
  components: {
    SalesFilters, DataTable,
  },
  data() {
    return {
      items: [],
      summary: [],
      search: {
        country: 'CL',
        operators: [],
        dateRange: [
          DateService.addDate(-2, 'months'),
          DateService.getDate(),
        ],
        paymentTypes: [],
        paymentForms: [],
        salesChannelMediums: [],
        salesChannelSources: [],
        reportGenerationType: 'sales',
        withHours: false,
        withCoupons: false,
        withInsurance: false,
        withRecorridoFee: false,
        isAgency: false,
      },
      selectedCountry: null,
      loading: false,
      showInsurance: false,
      showRecorridoFee: false,
    };
  },
  beforeMount() {
    this.fetchSales();
  },
  methods: {
    fetchSales() {
      this.loading = true;
      FinanceApi.get_sales(this.search, false).then((data) => {
        this.items = data.body.sales;
        this.summary = data.body.summary;
        this.loading = false;
        this.showInsurance = this.search.withInsurance;
        this.showRecorridoFee = this.search.withRecorridoFee;
      }).catch(() => { this.loading = false; });
      this.selectedCountry = this.search.country;
    },
    exportReport() {
      FinanceApi.get_sales(this.search, true).then((res) => {
        handleDownload(res);
      });
    },
    sendTicketReport() {
      FinanceApi.export_tickets_report(this.search).then(() => {
        this.$notify({
          title: 'Enviando Correo',
          message: 'Los datos se estan procesando, te llegará un email con el detalle.',
          type: 'success',
        });
      });
    },
  },
  computed: {
    headers() {
      const defaultHeaders = [
        { key: 'operator', label: 'Operador' },
        {
          key: 'price_with_offsets', label: 'Total vendido incl. CT + Recorrido fee', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'sales_channel_offset_ioc', label: 'CT Canal de venta', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'seasonal_offset_ioc', label: 'CT Estacional', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'operator_offset_ioc', label: 'CT Operador', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'operator_price_ioc', label: 'Total prec. Operador', type: 'currency', country: this.selectedCountry,
        },
      ];
      if (this.showInsurance) {
        defaultHeaders.splice(2, 0, {
          key: 'insurance_ioc', label: 'Seguro AXA', type: 'currency', country: this.selectedCountry,
        });
      }
      if (this.showRecorridoFee) {
        defaultHeaders.splice(2, 0, {
          key: 'recorrido_fee_offset_ioc', label: 'Recorrido Fee', type: 'currency', country: this.selectedCountry,
        });
      }
      return defaultHeaders;
    },
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    withCoupons() {
      return this.search.withCoupons;
    },
    summaryWithCoupons() {
      return [
        { title: 'Total vendido incluido sobrecarga', total: this.summary.price_with_offsets },
        { title: 'Total de Cupones', total: this.summary.total_redeemed_coupons },
        { title: 'Total con descuento de cupones', total: this.summary.price_with_offsets - this.summary.total_redeemed_coupons },
      ];
    },
  },
};
</script>
